import {
    checkDmecDate,
    updateInfosRef,
    countNbRefEquip,
    displayCriteres,
    updateListRefEquip,
    disabledIfNoRefOrig,
    disabledIfRefOrig } from './pubtech-vehicule-affectation-origine';
import {getPromotionBand, showPromoBands} from './promotion-bands';
import {logPackageLoadTime} from './packages/autossimo-packages-ad';
import {
    clickChoicePiece,
    autoSelectOnlyIamReference,
    autoSelectFirstIamReference,
    manageFolderDiscount, updateSelectedRefs
} from './followUpFolder/folder-functions';
import {formatAvailability} from "./manage-ga";
import {loadReplacementReferences} from "./replacement-references";
import $ from "jquery";
import {getFicheProduitAjax} from "./pubtech-main-v2";
import { ProductAttributes } from './analytics/amplitude/dto/product/ProductAttributes';
import { ItemAttributes } from './analytics/ga/dto/item/ItemAttributes';
import {adjustQuantity, handleQuantityUpdate} from "./quantityManager";
import EventHandler from './analytics/amplitude/EventHandler';
import GaEventHandler from './analytics/ga/GaEventHandler';
import {PATH} from "./analytics/constants";

// - Initialise variables
var idPackagesAdContent = '#packages-ad-content';
var classServiceUnavailable = '.alert-service-unavailable';
const folderGlassMakers = '.folder-glass-makers';
const hasAngAccessClass = '.has-ang-access';
var noSiretCora = 'no-siret-cora';
const BLUE_TEXT = '.blue-text';
const DISPO_PRIORITY = 'data-dispo-priority';
const PAHT = 'data-ht-pa-price';
const DESIGNATION = 'data-design';
const inputQuantity = '.input-quantity';
const originData = '.origin-datas';
const isInGlazingFamily = $('.is-in-glazing-family').length > 0 && $(hasAngAccessClass).length > 0;
const isCoraTrackingFolder = $('p[class="total-price"]').length > 0;
const sortGlazingReferences = '.sort-glazing-references'
const REF_CONTAINER = '.ref-oe-container';
const RESULTS_CONTAINER = 'div.results-container';
const FOLDER_TIMEOUT_ALERT = '.folder-timeout-alert';
const LOADER_FILTER_RESULT = '.loader-filter-result-piece';
const DATA_QUANTITY = 'data-quantity';
const DATA_DISPOECPF= '.DispoECPF';
const ERP_NOK_DIST = 'erp-nok-distrib';
const DATA_STATUS = 'data-status';
const CORA_NO_SIRET = 'cora-no-siret';
const CORA_NOK_BDD = 'erp-nok-cora-bdd';
const DISPO_ERROR = '.dispoError';
const DATA_EQUIP = 'data-equip';
const DATA_REFEQUIP = 'data-refequip';
const HAS_DATE_PICKER = 'input.hasDatepicker';
const DISPO_PRICE_UNAVAILABLE = '.dispo-price-service-unavailable';
const DATA_PRICE = 'data-price';
const BTN_EDIT_CHOICE = '.btn-edit.choice-piece';
const IS_ELIGIBLE_TECCOM_PROVIDER = $('.isEligibleTecComProvider').length > 0
const IS_PORTAL_CUSTOMER = '.is-portal-customer';
const END_OF_LIFE = 'END_OF_LIFE';
const OUT_OF_STORE = '.out-of-store';
const OUT_OF_STORE_STATUS = 'OUT_OF_STORE';
const CONTENT_STORE = '.content-store';

/**
 * browse the results obtained and modify the availability and price blocks.
 *
 * @param updatedRef
 * @param resultsUpdated
 */
function updateResult(updatedRef, resultsUpdated, angRef = false)
{
    jQuery.each(updatedRef, function (i, value) {
        var myRef = value.CODEQU + value.REFEQ;
        if (jQuery.inArray(myRef, resultsUpdated) < 0) {
            var refEquToUpdate = jQuery('.containerDispo[data-equiprefeq="' + value.CODEQU + value.REFEQ + '"]');
            updateBlocDispo(refEquToUpdate, value);
            updateBlocPrice(refEquToUpdate, value);
            if (angRef) {
                refEquToUpdate.find(DISPO_PRICE_UNAVAILABLE).hide();
            }
            //For Price level selected default value
            const isPriceLevel = refEquToUpdate.find('.price-level-bloc');
            if (isPriceLevel.length > 0 && !isPriceLevel.is(':hidden')) {
                const options = refEquToUpdate.find('select.selectPriceLevel');
                const input = refEquToUpdate.find('div.cart-action input');
                input.val(options.val());
                input.attr(DATA_QUANTITY, options.val());
                input.attr('value', options.val());
            }

            toggleBlocDispoPriceToResults(refEquToUpdate);
            refEquToUpdate.find('.update-in-progress').hide();
            refEquToUpdate.find('.dispo-price-bloc').show();
            $(classServiceUnavailable).not('.autodata').hide();
            resultsUpdated.push(myRef);
        }
    });
}

function toggleLoaderIamReferences(referencesIam) {
    let refs = []
    referencesIam.each(function(index, referenceIam){
        refs.push($(referenceIam).closest(REF_CONTAINER).attr('data-codrefco'));
    })
    // remove duplicate reference from refs using "Spread syntax of ES6"
    refs = [...new Set(refs)]
    refs.forEach(function(referencesOe, index){
        var refOeSelector = `.ref-oe-container[data-codrefco="${referencesOe}"]`;
        $(refOeSelector).find('.references-produit-list').toggleClass('hide', false);
        $(refOeSelector).find('.references-produit-list-loader').toggleClass('hide', true);
        $(refOeSelector).find('.folder-nb-iam-text').toggleClass('hide', false);
    })
}

function eliminateManufacturersRedundancy(){
    $(".filtreEquipementiers").each(function (index) {
        var map = {};
        var equimenetsList = $(this).find('.selectpicker');
        equimenetsList.children("option").each(function () {
            if (map[this.value]) {
                $(this).remove();
            }
            map[this.value] = true;
        });
        equimenetsList.selectpicker('refresh');
    });
}

export function getOEMByGetPrice(references, performLog, parentClass, refreshCount, checkDmec = true, replacementToSearched = false)
{
    const referenceOE = references;
    if (references.hasClass('ref-oe-container')) {
        references = referenceOE.find(originData).find('.containerDispo');
        parentClass = referenceOE;
    }
    const nbRefIams = references.length;
    var referencesDatas = [];
    var packageLengthContent = $(idPackagesAdContent).length;

    references.each(function () {
        const ref = $(this);
        const formatedRef = formatRefeqParamsForErpRequest(ref);
        referencesDatas.push(formatedRef);
    });

    $(originData).each(function () {
        changePrioCriteriaOnRefeq($(this));
    });
    const loadPriceParameters = {
        performLog : performLog,
        referenceOE : referenceOE,
        parentClass : parentClass,
        nbRefIams : nbRefIams,
        packageLengthContent : packageLengthContent,
        refreshCount : refreshCount,
    };

    const referencesDataTyres = referencesDatas.filter(function (ref) {
        return ref.CODARBO === '120101';
    });

    referencesDatas = referencesDatas.filter(function (ref) {
        return ref.CODARBO !== '120101';
    });
    let ajaxCall = [];
    if (referencesDatas.length > 0) {
        const ajaxCall1 = callAjaxToLoadPrice(
            referencesDatas,
            references,
            loadPriceParameters,
            checkDmec,
            replacementToSearched
        ).complete(() => {
            EventHandler.displayIamForOem(references, 'pieces')
        });
        ajaxCall.push(ajaxCall1);
        ;
    }

    // [Homepages] call prices for tyres and update DOM
    if (referencesDataTyres.length > 0) {
        const ajaxCall2 = callAjaxToLoadPriceTyres(referencesDataTyres);
        // update amplitude div prices for tyres
        ajaxCall.push(ajaxCall2);
    }

    if (PATH.indexOf('client-portal/display-references') >= 0) {
        $.when(...ajaxCall)
            .done(function (results) {
                EventHandler.viewCampaignDetail($('.amplitude'), 'client-portal')
            })
            .fail(function (error) {
                console.log('fail : ' + error);
            });
    }
}

export function callAjaxToLoadPriceTyres(referencesData) {
    return jQuery.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(referencesData),
            'isMainLoad': true
        },
        url: Routing.generate('autossimo_ajax_call_erp_get_price_tyre'),
        async: true,
        success: function (response) {
            jQuery.each(response.data.results, function (key, value) {
                let refEquToUpdate = jQuery('.containerDispo[data-equiprefeq="' + value.CODEQU + value.REFEQ + '"]');
                ProductAttributes.fromLoadPrice(refEquToUpdate,value);
                ItemAttributes.fromLoadPrice(refEquToUpdate,value);
            });
            renderTyresViews(response);
        },
        error: function (error) {
            if (error.status === 0) {
                window.location.reload()
            } else {
                updateRefeqsOnError('Erreur - SERVICE INDISPONIBLE');
            }
        }
    });
}

function updatePricesAndAvailabilities(response)
{
    const tyreProductsLines = $('.product-content-tyre');
    $.each(tyreProductsLines, function (index) {
        const refeq = $(this).attr('data-refeq');
        const equip = $(this).attr('data-equip');
        let indexRef = equip+refeq;
        if (response[indexRef] !== undefined) {
            // drop prices/availabilities
            if (response[indexRef].prices.dropPrices !== undefined) {
                const dropAddCartBtn = $(this).find('.btn-cart[data-flag-dropshipping="O"]');
                $('.tyre-price-drop-bloc').eq(index).html(response[indexRef].prices.dropPrices);
                $('.tire-detail-availability-drop').eq(index).html(response[indexRef].availabilities.dropAvailabilities);
                $(this).find('.portal-customer-bloc-drop').removeClass('hidden');
                dropAddCartBtn.attr('data-siret-dropshipping', response[indexRef].siret);
                dropAddCartBtn.attr('data-availability', response[indexRef].availabilityDrop);
                const platform = response[indexRef].providerName === 'DIPROCONNECT' ? 'DIPC' : ''
                dropAddCartBtn.attr('data-flag-platform', platform);
            }

            // distributor prices/availabilities
            if (response[indexRef].prices.distributorPrices !== undefined) {
                $('.tyre-price-bloc').eq(index).html(response[indexRef].prices.distributorPrices);
                $('.tire-detail-availability').eq(index).html(response[indexRef].availabilities.distributorAvailabilities);
                $(this).find('.btn-cart[data-flag-dropshipping="N"]').attr('data-availability', response[indexRef].availability);
            } else {
                $(this).find('.portal-customer-bloc-distrib').addClass('hidden');
                $(this).find('.portal-customer-bloc-drop .tire-price-border').removeClass('tire-price-border');
            }

        }
    });
}
function renderTyresViews(response)
{
    jQuery.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(response.data.results)
        },
        url: Routing.generate('client_portal_render_tyres_views'),
        async: true,
        success: function (result) {
            updatePricesAndAvailabilities(result);
            let modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');
            jQuery.each(response.data.results, function (key, value) {
                let refEquToUpdate = jQuery('.containerDispo[data-equiprefeq="' + value.CODEQU + value.REFEQ + '"]');
                displayProductStatus(value, refEquToUpdate, true)
                getPromotionBand(value, refEquToUpdate, modePrice);
            });
            showPromoBands(response.data.results);
        },
        error: function (error) {console.log('error render tyres views')}
    });
}

export function callAjaxToLoadPrice(
    referencesDatas,
    references,
    loadPriceParameters,
    checkDmec = true,
    replacementToSearched = false
)  {
    let performLog =  undefined;
    let referenceOE = undefined;
    let parentClass =  undefined;
    let nbRefIams =  undefined;
    let packageLengthContent =  undefined;
    let refreshCount =  undefined;

    if (undefined !== loadPriceParameters) {
        performLog = loadPriceParameters.performLog;
        referenceOE = loadPriceParameters.referenceOE;
        parentClass = loadPriceParameters.parentClass;
        nbRefIams = loadPriceParameters.nbRefIams;
        packageLengthContent = loadPriceParameters.packageLengthContent;
        refreshCount = loadPriceParameters.refreshCount;
    }
    return jQuery.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(referencesDatas),
            'isMainLoad': true
        },
        url: Routing.generate('autossimo_ajax_call_erp_get_price'),
        async: true,
        beforeSend: function(){
            if ($(folderGlassMakers).length > 0) {
                $(FOLDER_TIMEOUT_ALERT).addClass('hidden');
            }
        },
        complete: function(){
            if ($('.packages-ad').length > 0) {
                $('.origin-equivalence').each(function(){
                    const priceHT = $(this).attr(DATA_PRICE);
                    if (priceHT.includes('--')) {
                        $(this).find('input.choice-piece').remove();
                        $(this).find(BTN_EDIT_CHOICE).addClass('choice-piece-disabled');
                        $(this).find(BTN_EDIT_CHOICE).addClass('disabled');
                        $(this).find('.btn-edit.choice-piece label').text('CHOIX INDISPONIBLE');
                        $(this).find(BTN_EDIT_CHOICE).removeClass('choice-piece');
                    }
                });
            }
            if ($(hasAngAccessClass).length > 0) {
                var products = referenceOE.find('.produits');
                if (referenceOE.data('status') !== 'ORDERED') {
                    jQuery.each(products, function () {
                        var refOE = $(this).attr('reforigine').replace(
                            new RegExp(" |\\*|\\.|\\'|\\/", 'g'), ''
                        );
                        var sortedListRefIAM = sortFolderResultsByRefOE(refOE);

                        // update list IAM references
                        if ($(sortGlazingReferences).length > 0) {
                            $(`${sortGlazingReferences} .produits`).html(sortedListRefIAM)
                        } else {
                            $(`#table-content-${refOE}, #table-content`).html(sortedListRefIAM);
                        }

                        // update IAM references count
                        referenceOE.find(BLUE_TEXT).html(sortedListRefIAM.length);
                        nbRefIams = sortedListRefIAM.length;
                    });
                    if ($(folderGlassMakers).length > 0) {
                        autoSelectOnlyIamReference(referenceOE);
                    }
                }

                if (typeof $(referenceOE).data('status') === 'undefined') {
                    // Get ref OE
                    const refOE = references.attr('data-refcon');
                    const sortedListRefIAM = sortFolderResultsByRefOE(refOE);

                    // Update list IAM references
                    $(`#table-content-${refOE}, #table-content`).html(sortedListRefIAM);
                }
            }

            // Display the warning messages
            if (($(hasAngAccessClass).length > 0) || IS_ELIGIBLE_TECCOM_PROVIDER) {
                const warnings = parseJSON($('#warnings-codes').val());
                const errorErp = parseJSON($('#warnings-erp-codes').val());
                displayWarningMessages(warnings, errorErp);
            }
            if ($(folderGlassMakers).length > 0) {
                toggleLoaderIamReferences(references);
                $(referenceOE).find(LOADER_FILTER_RESULT).toggle();
                $(referenceOE).find('.folder-nb-iam-refs:not(.caseOneB)').removeClass('hide')
                if (nbRefIams > 1) {
                    $(referenceOE).find('.toggle-display-ref-oem').removeClass('hide');
                }
            }
            if ($('.choix-equipementiers').length > 0) {
                // update counter refs iam
                $('.choix-equipementiers').each(function(index, val){
                    $(val).find('.refequip22:first-child, strong:eq(0)').html(
                        $(val).closest('.results-container, .reference-produits-container')
                            .find(`${originData}:not(.hide)`).length
                    )
                });

                $('.nb-ref-equi-total').html(
                    $(`.results-container:not(.hide) ${originData}:not(.hide), .reference-origine-results:not(.hide) ${originData}:not(.hide)`).length
                );
            } else {
                $('.nb-refs-iam').html($(originData).length)
            }
            if ($('#productSheetToDisplay').length > 0) {
                const manufacturerCode = $('#productSheetToDisplay').val()
                $(`#folder-part-id-${manufacturerCode} .productsheet-link`).click();
            }
            if ($(folderGlassMakers).length > 0 && referenceOE.data('status') !== 'ORDERED'
                && referenceOE.data('status') !== 'CANCELED') {
                updateSelectedRefs();
            }

            GaEventHandler.viewItemList($('.origin-datas:visible'), 'pieces');

        },
        success: function (data) {
            const selectedRefsSelector = '#selected-refs';
            const dataSelected = 'data-selected';
            const selected = parseJSON($(selectedRefsSelector).attr(dataSelected));
            const inCatalog = selected !== null ? Object.keys(selected.inCatalog) : [];
            var updatedRef = data.data.results;
            var errorCode = data.data.error;
            const warnings = data.data.warnings !== undefined ? data.data.warnings : [];
            const accessNotFound = data.data.accessNotFound !== undefined ? data.data.accessNotFound : [];
            var resultsUpdated = [];
            updateProductStatus(updatedRef);
            // Set the warning messages depending on the status code returned from the WS ANG and ERP Bundle
            if ($(hasAngAccessClass).length > 0 || IS_ELIGIBLE_TECCOM_PROVIDER) {
                $("#warnings-codes").val(JSON.stringify(warnings));
                $("#access-not-found").val(JSON.stringify(accessNotFound));
                $("#warnings-erp-codes").val(JSON.stringify(errorCode));
            }

            if (errorCode !== null) {
                var erpNOK = errorCode[ERP_NOK_DIST];
                $('.erp_nok_distributor').attr('value', erpNOK);
            }

            checkErrorCode(errorCode, updatedRef, resultsUpdated, packageLengthContent, parentClass, checkDmec);

            if (performLog === true) {
                logPackageLoadTime();
            }
            if ($(folderGlassMakers).length > 0) {
                updateQuantityBlock(references);
                if ($(hasAngAccessClass).length === 0 &&
                    $.inArray(String(referenceOE.data('codrefco')), inCatalog) === -1) {
                    autoSelectFirstIamReference(referenceOE);
                }
            }
            if ($(hasAngAccessClass).length > 0) {
                eliminateManufacturersRedundancy();
                if (data.data.success === false) {
                    $(FOLDER_TIMEOUT_ALERT).removeClass('hidden');
                }
                processAngReplacedReferences(data.data.results);
            }
            // discount for folders
            if ($('.folder-glass-makers').length > 0 && errorCode === null) {
                manageFolderDiscount(updatedRef);
            }
            if (refreshCount === true ) {
                $('.no-padding-right .loader-filter-result-piece').toggle();
            }
            const equivalentReferences = $('.equivalences-ref');
            if (equivalentReferences.length > 0) {
                sortData();
            }

            let referencesSearchedAndLoaded  = false;
            if (replacementToSearched) {
                referencesSearchedAndLoaded = loadReplacementReferences();
            }

            $('.spinner-package-ad').addClass('sucessLoad');
            $('.spinner-package-ad').addClass('hidden');
            $('.result-show').show();
            showPromoBands(updatedRef);

        },
        error: function (error) {
            if (error.status === 0 && $(hasAngAccessClass).length > 0) {
                window.location.reload()
            } else {
                var errorMessage = 'Erreur - SERVICE INDISPONIBLE';
                updateRefeqsOnError(errorMessage);
            }
        }
    });
}
function escapeSelector(selector) {
    return selector.replace(/([!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~])/g, '\\$1');
}

function updateProductStatus(updatedRef) {
    let countREFIAM = 0;
    $.each(updatedRef, function(index, value) {
        if (value !== undefined ) {
            const blockOrigin = $(`.product-content.panier-input[data-equiprefeq="${value.CODEQU+value.REFEQ}"]`);
            const sourcePage = $('#source-search').data('source');
            if (value.PRODUCT_STATUS === END_OF_LIFE) {
                if (sourcePage === 'equipementier') {
                    blockOrigin.find('.v3-pricing-part').html(
                        '<div class="text-description-orange bold">Désolé, cette référence a été arrêtée et n’est plus disponible à l’achat !</div>'
                    );
                    blockOrigin.find('.pricing-block').remove();
                } else if ($.inArray(sourcePage, ['origine', 'constructor']) !== -1) {
                    blockOrigin.parents('tr.origin-equivalence.origin-datas').remove();
                }
            } else if (value.PRODUCT_STATUS === OUT_OF_STORE_STATUS) {
                adjustQuantity(blockOrigin, value, folderGlassMakers);
            } else {
                countREFIAM++;
            }
            const origineConstructorDiv = $(`.result-ref-origine[data-refeq="refEquip-${value.REFCON}"]`);
            const escapedRefCon = escapeSelector(value.REFCON);
            const countReference = $(`strong.count-reference-origine-${escapedRefCon}`);

            // Update reference count
            if (origineConstructorDiv.find(countReference).length) {
                origineConstructorDiv.find(countReference).html(`"${countREFIAM}"`);
            }
        }
    });
}

function processAngReplacedReferences(references)
{
    references.forEach(reference => {
        const selector = `tr.origin-datas[data-refequip="${reference.REFEQ}"]`;
        if (reference.hasOwnProperty('REPLACED') && reference.REPLACED === true) {
            $(selector).remove();
        }
        if (reference.hasOwnProperty('REPLACE') && reference.REPLACE !== null) {
            $(selector).find('.replacements-ref span').html(`Remplace : ${reference.REPLACE}`);
        }
        if (reference.hasOwnProperty('REPLACED_BY') && reference.REPLACED_BY !== null) {
            const blockOriginData = `div.origin-datas[data-refequip="${reference.REFEQ}"]`;
            const productSheetUrl =
                Routing.generate('autossimo_fiche_produit_equipementier_light', {codrefeq: reference.REPLACED_BY_CODREFEQ });
            const html = `<a class="product-link replaced-by"
                            data-product-url="${productSheetUrl}"
                            data-refeq="${reference.REPLACED_BY}"
                            data-equip="${reference.CODEQU}"
                            >
                                ${reference.REPLACED_BY}
                            </a>`;
            $(blockOriginData).find('.replacements-ref span').html(`Est remplacée par : ${html}`);
            const availability = $(blockOriginData).find('.dispoError.orange-availability-msg:visible');
            if (availability.length > 0) {
               const tooltips =  availability.find('.tooltips .tooltips-text');
               tooltips.addClass('top-20');
               tooltips.html(`Cette référence n’est plus disponible et a été remplacée par la ${html}`);
            }
            $(`${blockOriginData} .product-link`).unbind().click(function () {
                getFicheProduitAjax($(this).data('product-url'));
            });
        }
    })
}

function sortData()
{
    const list            = $('#table-content');
    const itemsToSort     = $(originData);
    const availabilityToDisplay = ['D', 'C', 'P', 'F', 'E', 'I', 'A', 'N'];

    itemsToSort.sort(function(firstElement, secondElement) {
        const availabilityFirstElement = $(firstElement).data('dispo');
        const availabilitySecondElement = $(secondElement).data('dispo');
        const deadlineFirstElement = $(firstElement).data('deadline');
        const deadlineSecondElement = $(secondElement).data('deadline');
        const priceFirstElement = $(firstElement).data('price');
        const priceSecondElement = $(secondElement).data('price');

        // 'D' comes before any other availability
        if (availabilityFirstElement === 'D' && availabilitySecondElement !== 'D') return -1;
        if (availabilityFirstElement !== 'D' && availabilitySecondElement === 'D') return 1;

        // Sort other availabilities alphabetically
        if (availabilityFirstElement !== availabilitySecondElement)
                return availabilityToDisplay.indexOf(availabilityFirstElement)
                    - availabilityToDisplay.indexOf(availabilitySecondElement);

        // If availability is 'A', compare prices
        if (availabilityFirstElement === 'A') {
            if (priceFirstElement === '--,--') return 1; // Put items with '--,--' price last
            if (priceSecondElement === '--,--') return -1;
            return parseFloat(priceFirstElement) - parseFloat(priceSecondElement);
        }

        // If availability is the same, compare deadlines
        const deadlineFirstType = deadlineFirstElement ? deadlineFirstElement.substring(0, 1).toUpperCase() : '';
        const deadlineSecondType = deadlineSecondElement ? deadlineSecondElement.substring(0, 1).toUpperCase() : '';

        if (deadlineFirstType !== deadlineSecondType) {
            return (deadlineFirstType === 'H') ? -1 : 1;
        }

        // If deadlines are of the same type
        const deadlineFirstValue = deadlineFirstElement ? parseInt(deadlineFirstElement.substring(2)) : 0;
        const deadlineSecondValue = deadlineSecondElement ? parseInt(deadlineSecondElement.substring(2)) : 0;
        return deadlineFirstValue - deadlineSecondValue;
    });

    list.append(itemsToSort);
}

export function applyGetPriceForResult(result, hideLoader, performLog, checkDmec = true, replacementToSearched = false)
{
    if(!result.hasClass( "stopGetPrice" )) {
        if (result.find(LOADER_FILTER_RESULT).css('display') !== 'block' ) {
            result.find(LOADER_FILTER_RESULT).toggle();
        }
        getOEMByGetPrice(result.find('.origin-datas .containerDispo'), performLog, result, false, checkDmec, replacementToSearched);
        hideLoader.show().next('strong').show();
        hideLoader.nextAll('strong').last().hide();
        result.addClass("stopGetPrice");
    }
}

export function updateBlocAvailabilityPrice(performLog, referenceOe, replacementToSearched = false) {
    var loadGetPrice = $('.loadGetPrice');
    var hideLoader = $('.nb-ref-equi-total.loader-filter-result-piece');

    $('form.filter-form').on('click', function() {
        if (!$('#hideFilters').data('clicked')) {
            $('.result.sub_result.style-subResult:hidden').each(function () {
                applyGetPriceForResult($(this).parent().parent('.results-container:not(.hide)'), hideLoader, performLog);
            });
        }

        $('#hideFilters').data('clicked', false);
    });

    if (loadGetPrice.length > 1) {
        hideLoader.show().next('strong').show();
        hideLoader.nextAll('strong').last().hide();

        loadGetPrice.on('click', function () {
            var parentResult = $(this).parents('.results-container');
            applyGetPriceForResult(parentResult, hideLoader, performLog, false, replacementToSearched);
        });
    } else {
        let referencesToGetAvailability;
        if(referenceOe !== undefined) {
            referencesToGetAvailability = referenceOe
        } else if ($(hasAngAccessClass).length > 0 && $('.global-management-folder').length > 0) {
            referencesToGetAvailability = $('.ref-oe-container:not([data-status="ORDERED"]) .origin-datas .containerDispo')
        } else {
            referencesToGetAvailability = $('.origin-datas .containerDispo')
        }
        if (referencesToGetAvailability.length > 0) {
            getOEMByGetPrice(referencesToGetAvailability, performLog, undefined, undefined, true, replacementToSearched);
        }
    }
}

$(document).ready(function () {
    var packageLengthContent = $(idPackagesAdContent).length;
    if(packageLengthContent === 0 && $('.global-management-folder').length === 0) {
        updateBlocAvailabilityPrice(false, undefined, true);
    }
});

function sortFolderResultsByRefOE(refOE) {
    var network = $('.folder-menu').data('network');
    let listRefIAM = $(`#table-content-${refOE}, #table-content`).find('tr');
    if($(sortGlazingReferences).length > 0) {
        listRefIAM = $(sortGlazingReferences).find(originData);
    }

    if (network === 'MPB_SUCCURSALES_HORS_SUD_OUEST') {
        listRefIAM =  sortReferencesByManufacturer(listRefIAM, 'DIMA');
    }

    if (network === 'MPB_SUCCURSALES_SUD_OUEST') {
        listRefIAM =  sortReferencesByManufacturer(listRefIAM, 'PGS');
    }

    if (network === 'MPB_FRANCHISES' || 'MPB_SUCCURSALES' === network) {
        var aListRefIAM=[]
        // convert to array
        $.each(listRefIAM, function() {
            aListRefIAM.push($(this));
        });

        listRefIAM =  sortElements(aListRefIAM);
    }

    return listRefIAM;
}

function sortReferencesByManufacturer(listRefIAM, Manufacturer) {
    var filteredListRefIAM = [];
    var fullListRefIAM = [];
    var totalCount = 0;
    var unavailableCount = 0;

    jQuery.each(listRefIAM, function () {
        if ($(this).data('equip') === Manufacturer) {
            if ($(this).data('dispo').slice(0, 1) === 'N' || $(this).data('dispo') === '--') {
                ++unavailableCount
            }
            filteredListRefIAM.push($(this));
            ++totalCount;
        }
        fullListRefIAM.push($(this));
    });

    // All available
    if (totalCount !== 0 && unavailableCount === 0) {
        return sortElements(filteredListRefIAM);
        // All unavailable
    } else if (totalCount !== 0 && unavailableCount === totalCount) {
        return  sortElements(fullListRefIAM);
    } else {
        return  sortElementsByManufacturerFirst(fullListRefIAM);
    }
}

function sortElements(listElements) {
    listElements.sort(function (a,b) {
        var aPAHT = getPriceExcludingTax(a);
        var bPAHT = getPriceExcludingTax(b);
        if (!$.isNumeric(aPAHT)) {
            aPAHT = 0;
        }
        if (!$.isNumeric(bPAHT)) {
            bPAHT = 0;
        }
        return a.attr(DISPO_PRIORITY) - b.attr(DISPO_PRIORITY) ||
            (aPAHT === 0) - (bPAHT === 0) || aPAHT - bPAHT ||
            getDesignation(a).localeCompare(getDesignation(b));
    });

    return listElements
}

function sortElementsByManufacturerFirst(listElements) {
    listElements.sort(function (a,b) {
        var aPAHT = getPriceExcludingTax(a);
        var bPAHT = getPriceExcludingTax(b);
        if (!$.isNumeric(aPAHT)) {
            aPAHT = 0;
        }
        if (!$.isNumeric(bPAHT)) {
            bPAHT = 0;
        }
        return a.attr('data-manufacturer-priority') - b.attr('data-manufacturer-priority') ||
            a.attr(DISPO_PRIORITY) - b.attr(DISPO_PRIORITY) ||
            (aPAHT === 0) - (bPAHT === 0) || aPAHT - bPAHT ||
            getDesignation(a).localeCompare(getDesignation(b));
    });

    return listElements
}
function getPriceExcludingTax(element) {
    return element.attr(PAHT) !== undefined ? parseFloat(element.attr(PAHT).replace(',', '.')) : '--.--'
}
function getDesignation(element) {
    return element.attr(DESIGNATION) !== undefined ?  element.attr(DESIGNATION) :
        ( element.attr('data-designation') !== undefined ? element.attr('data-designation')  : '' )
}
function checkErrorCode(errorCode, updatedRef, resultsUpdated, packageLengthContent, parentClass, checkDmec) {
    let aRefAng = []; //NOSONAR
    if (hasNoError(errorCode, updatedRef)) {
        updateResult(updatedRef, resultsUpdated);
        if (!isInGlazingFamily || isCoraTrackingFolder) {
            applySortAndPrioRules(parentClass);
        }

        if (packageLengthContent === 0 && checkDmec) {
            checkDmecDate();
        }
        removeNonPriorisedEquipInGlobalFilter();
        removeNonPriorisedEquipInRefoFilters();
        updateInfosRef();
        countNbRefEquip();
        displayCriteres();
        updateListRefEquip();

        // AD-5925 : comment the updateBlocEcotaxes while waiting the changes in environmental taxes
        //updateBlocEcotaxes(updatedRef);
        hideNonAngGlassMakerLoader(packageLengthContent, parentClass);
    } else if (errorCode[ERP_NOK_DIST]) {
        const errorMessage = `Erreur ERP ${errorCode[ERP_NOK_DIST]}, SERVICE INDISPONIBLE`;
        updateRefeqsOnError(errorMessage, parentClass);
        aRefAng = extractAngReferences(updatedRef);
    } else {
        jQuery.each(updatedRef, function (index, val) {
            const  element = $(`${originData}[data-codref="${val.CODREFEQ}"]`);
            updateBlocDispoPriceToDefault(element);
            toggleBlocDispoPriceToResults(element);
            jQuery(classServiceUnavailable).hide();
        });

        hideNonAngGlassMakerLoader(packageLengthContent, parentClass);
    }
    updateAngReferences(aRefAng);
}

function hasNoError(errorCode, updatedRef) {
    const emptyDataErrorCode = 12;
    return (errorCode == null && updatedRef !== []) ||
        errorCode.code === 0 ||
        errorCode === emptyDataErrorCode ||
        (errorCode[CORA_NO_SIRET] === noSiretCora && updatedRef !== []) ||
        (errorCode[CORA_NOK_BDD] === 'cora-bdd'  && updatedRef !== []);
}

function hideNonAngGlassMakerLoader(packageLengthContent, parentClass) {
    if (packageLengthContent === 0 && $(folderGlassMakers).length === 0) {
        if (parentClass !== undefined) {
            parentClass.find(LOADER_FILTER_RESULT).toggle();
        } else {
            $(LOADER_FILTER_RESULT).toggle();
        }
    }
}

function extractAngReferences(updatedRef) {
    return updatedRef.filter(ref => ref.ANG);
}

function updateAngReferences(aRefAng) {
    if (aRefAng.length > 0) {
        updateResult(aRefAng, [], true);
    }
}

function updateBlocEcotaxes(items) {
    items.forEach(function (item) {
        if (item.CODREFEQ && item.ECOTAXES.total && JSON.parse(item.ECOTAXES.total) > 0) {
            var ecotaxes = item.ECOTAXES;
            var id = 'popin-' + item.CODREFEQ;
            var ecotaxesTotal = String(ecotaxes.total).replace('.', ',');
            var paht = item.PAHT.replace('.', ',');

            let htmlConsigne = '';
            if (ecotaxes.items.CONSIGNE.length > 0) {
                ecotaxes.items.CONSIGNE.forEach(function (itemCONS) {
                    htmlConsigne += '<li>' + itemCONS.libelle + ': <span class="strong">' + itemCONS.valeur.replace('.', ',') + ' € HT</span></li>';
                });
            } else {
                htmlConsigne += '<li>Non disponible</li>';
            }

            var htmlEco = '';
            if (ecotaxes.items.ECO_PART.length > 0) {
                ecotaxes.items.ECO_PART.forEach(function (itemECO) {
                    htmlEco += '<li>' + itemECO.libelle + ': <span class="strong">' + itemECO.valeur.replace('.', ',') + ' € HT</span></li>';
                });
            } else {
                htmlEco += '<li>Non disponible</li>';
            }

            var parent = $('#ecotaxes_parent_' + item.CODREFEQ);

            parent.find('.ecotaxe_container--link').attr('data-idPopin', id);
            parent.find('.ecotaxe_container--popin').attr('id', id);
            parent.find('.ecotaxe_container--link-value').text(ecotaxesTotal);
            parent.find('.ecotaxe_container--popin-price--value').text(paht);
            parent.find('.ecotaxe_container--popin--consigne').html(htmlConsigne);
            parent.find('.ecotaxe_container--popin--eco').html(htmlEco);
            parent.find('.ecotaxe_container--link').show();
        }
    });
}
function updateRefeqsOnError(errorMsg, parentElement) {
    var alertServiceUnavailable = jQuery(classServiceUnavailable);
    var packageLengthContent = $(idPackagesAdContent).length;
    alertServiceUnavailable.find('.alert-erp-details').text(errorMsg);
    alertServiceUnavailable.show();
    $(originData).each(function(){
        var refeq = this;
        var prio = refeq.getAttribute('data-pr');
        if(prio == '1' || prio == '2' || prio == '3'){
            removeRefeqNonPrioOnErrorErp();
            return false;
        }
    });
    checkDmecDate();
    removeNonPriorisedEquipInGlobalFilter();
    removeNonPriorisedEquipInRefoFilters();
    updateInfosRef();
    countNbRefEquip();
    displayCriteres();
    updateListRefEquip();
    disabledIfNoRefOrig();
    disabledIfRefOrig();
    let $loaderDispoPrice = $('.containerDispo[data-refeq] .loader-dispo-price');
    let $dispoPriceUnavailable = $('.containerDispo[data-refeq] .dispo-price-service-unavailable');
    let $filterResults = $(LOADER_FILTER_RESULT);
    if (parentElement !== undefined) {
        $loaderDispoPrice = parentElement.find('.containerDispo[data-refeq] .loader-dispo-price');
        $dispoPriceUnavailable = parentElement.find('.containerDispo[data-refeq] .dispo-price-service-unavailable');
        $filterResults = parentElement.find(LOADER_FILTER_RESULT);
    }
    $loaderDispoPrice.toggle();
    $dispoPriceUnavailable.toggle();
    if(packageLengthContent === 0 && $(folderGlassMakers).length === 0) {
        $filterResults.toggle();
    }

    // Add warning alert message for detail folder page when service is unavailable
    if ($(folderGlassMakers).length > 0 || $(hasAngAccessClass).length > 0) {
        $(".glassmaker-alert.alert-message-service-unavailable").removeClass('hidden');
        alertServiceUnavailable.hide();
    }
}

export function formatRefeqParamsForErpRequest(reference, isFolderPage = false, layerPage = false){
    let qte;
    const codequ = reference.attr(DATA_EQUIP);
    const refeq = reference.attr('data-refeq');
    const codfam = reference.prev('div[id^="quote-data"]').attr('data-fam');
    const codsfam = reference.attr('data-sous-code-famille');
    let codrep = reference.attr('data-code-repere');
    const codrefco = reference.attr('data-code-refco');
    const codrefeq = reference.attr('data-code-refeq');
    const refcon = reference.attr('data-refcon');
    const codarbo = reference.attr('data-codarbo');
    const dimTyre = reference.attr('data-dimTyre');
    const libequ = reference.attr('data-lib-equip') !== undefined ? reference.attr('data-lib-equip') : null;
    const diametre = reference.attr('data-diametre') !== undefined ? reference.attr('data-diametre') : null;
    const designation = getDesignation(reference);
    var typeRecherche = reference.attr('data-type-recherche');
    if (reference.closest('.ref-oe-container.bloc-reference').length > 0) {
        qte = reference.closest('.ref-oe-container.bloc-reference').attr(DATA_QUANTITY);
    } else if (isFolderPage || layerPage) {
        qte = reference.attr('data-quantity');
    } else {
        qte = reference.find('input[data-quantity]').attr(DATA_QUANTITY);
    }
    qte = qte !== undefined ? qte : 1;
    if(codrep === '') {
        codrep = reference.find('input[data-code-repere]').attr('data-code-repere');
    }
    const destocking = reference.attr('data-destocking') !== undefined ? reference.attr('data-destocking') : 0;
    return {
        "CODEQU" : codequ,
        "CODFOU" : codequ !== undefined ? codequ : '',
        "REFEQ" : refeq,
        "REF" : refeq !== undefined ? refeq : '',
        "QUANTITE": qte,
        "CODFAM": codfam,
        "CODSFAM": codsfam,
        "REPERE": codrep,
        "CODREFCO": codrefco,
        "CODREFEQ": codrefeq,
        "REFCON" : refcon,
        "typeRecherche" : typeRecherche !== undefined ? typeRecherche : '',
        "CODARBO" : codarbo !== undefined ? codarbo : '',
        "DIMPNEU" : dimTyre !== undefined ? dimTyre : '',
        'LIBEQU': libequ,
        'DESTOCKING': destocking,
        'DESGINATION': designation,
        'DIAMETRE' : diametre
    };
}

function changePrioCriteriaOnRefeq (refeq) {
    var prio = refeq.data('pr');
    switch (prio){
        case 'P1':
            prio = 1;
            break;
        case 'P2':
            prio = 2;
            break;
        case 'P3':
            prio = 3;
            break;
        case 'ZZ':
            prio = 4;
            break;
    }
    refeq.attr('data-pr', prio);
    return prio;
}

export function updateBlocDispo(refeq, value) {
    var erpNoSiret = $('.erp_no_siret').val();
    var manufacturerCode = value.CODEQU;

    if( (manufacturerCode === 'CORA') && erpNoSiret  === noSiretCora )
    {
        value.DISPO = 'A';
    }
    refeq.attr('data-dispo',  value.DISPO);
    refeq.attr('data-global-status',  value.GLOBAL_STATUS);
    refeq.attr('data-stock',  value.STOCK);
    refeq.attr('data-product-status',  value.PRODUCT_STATUS);
    refeq.closest(originData).attr('data-dispo',  value.DISPO);
    refeq.closest(originData).attr('data-deadline',  value.DELAI_SIMPLIFIE);
    refeq.find('.add-cart-piece').attr('data-availability',  value.DISPO);
    refeq.find('.btn-edit.choice-piece').attr('data-availability',  value.DISPO);
    if (refeq.closest(originData).find('#pvttc-'+value.CODREFEQ).length > 0) {
        refeq.closest(originData).find('#pvttc-'+value.CODREFEQ).val(String(value.PVTTC).replace(',', '.'))
    } else {
        refeq.closest(originData).append('<input id="pvttc-' + value.CODREFEQ + '" type="hidden" value="' +
            String(value.PVTTC).replace(',', '.') + '"/>')
    }
    //D = Available for DIMA and PGS, E = Available for CORA
    //N = Unavailable for DIMA and PGS, A = Unavailable for CORA
    var priorityOfAvailability = {'D': 1, 'E': 1, 'F': 2, 'I': 3, 'N':4, 'A':4};
    refeq.closest(originData).attr(DISPO_PRIORITY,  priorityOfAvailability[value.DISPO.slice(0,1)]);

    var availability = value.DISPO;
    const erpNokDistributor = $('.erp_nok_distributor').val();

    if ((manufacturerCode === 'CORA') && (availability === 'A') || (manufacturerCode === 'CORA') && (erpNoSiret === noSiretCora)) {
        refeq.find('.add-cart-piece').addClass('cora-article-not-available');
    }else{
        refeq.find('.add-cart-piece').removeClass('cora-article-not-available');
    }

    var blocDispo = refeq.find('.dispo-details');
    blocDispo.hide();
    var dispo = (value.DISPO).length > 1 ? (value.DISPO).slice(0,1) : value.DISPO;

    availability = showAppropriateAvailability(dispo, value, blocDispo);

        const inGlazingManufacturerAndHasAngAccess = $(hasAngAccessClass).length > 0 &&
            ['DIMA', 'PGS'].indexOf(value.CODEQU) > -1 ;
    availability = editBlocDispo(value, blocDispo, erpNokDistributor, inGlazingManufacturerAndHasAngAccess, availability);
    if (
        blocDispo.find(HAS_DATE_PICKER).parents('.dispoA:not(.hidden)').length > 0 &&
        blocDispo.find(HAS_DATE_PICKER).parents('.dispoA').css('display') === 'block'
    ) {
        availability = blocDispo.find(HAS_DATE_PICKER).attr('value');
    }
    availability = formatAvailability(availability);
    blocDispo.parent().attr('data-available-value', availability);

    if(value.FLAG_PFPT){
        refeq.find('.pfpt').show();
    } else {
        refeq.find('.pfpt').hide();
    }

    displayProductStatus(value, refeq)
    displayDestockingProduct(value, refeq)
}

function displayProductStatus(value, refeq, isTyre = false)
{
    const blockOutOfStock = refeq.find(OUT_OF_STORE);
    if (isTyre) {
        displayStatusTyres(value, refeq);
    } else if (value.PRODUCT_STATUS === OUT_OF_STORE_STATUS) {
        if (undefined !== value.IS_DROP   && true === value.IS_DROP ) {
            blockOutOfStock.find(CONTENT_STORE).html('Stock limité');
            blockOutOfStock.find(CONTENT_STORE).addClass('text-center');
        } else {
            blockOutOfStock.find('.nbr-of-store').html(value.STOCK);
        }
        blockOutOfStock.removeClass('hideStore');
        blockOutOfStock.removeClass('hidden');
    } else {
        blockOutOfStock.addClass('hidden');
    }
}

function displayDestockingProduct(value, refeq, isTyre = false)
{
    const blockOrigin = $(`.product-content.panier-input[data-refeq="${value.REFEQ}"]`);
    if (undefined !== value.DESTOCKING && '1' === value.DESTOCKING && (['D','C'].indexOf((value.DISPO).slice(0, 1)) === -1)) {
        blockOrigin.closest('.origin-datas').remove();
        updateListRefEquip();
    }
}

function displayStatusTyres(value, refeq) {
    const blockOutOfStockDistrib = refeq.find('.out-of-store.portal-customer-bloc-distrib');
    const blockOutOfStockDrop = refeq.find('.out-of-store.portal-customer-bloc-drop');
    if (value.MULTIPLE_LINES === true || !$(refeq).find('.tyre-price-bloc.portal-customer-bloc-distrib').is('.hidden')) {
        refeq.find('.tyre-availability-drop-bloc .v3-pricing-part').removeClass('tire-price-border');
        refeq.find('.tyre-price-drop-bloc .tire-price-border').addClass('bottom-price');
    }
    if (false === $(refeq).find('.tyre-price-drop-bloc.portal-customer-bloc-drop').is('.hidden')) {
        if (value.PRODUCT_STATUS_DROP === OUT_OF_STORE_STATUS && undefined !== value.IS_DROP  && true === value.IS_DROP) {
            blockOutOfStockDrop.find(CONTENT_STORE).html('Stock limité');
            blockOutOfStockDrop.find(CONTENT_STORE).addClass('text-center');
            blockOutOfStockDrop.removeClass('hideStore');
            blockOutOfStockDrop.removeClass('hidden');
        }
    }
    if (false === $(refeq).find('.tyre-price-bloc.portal-customer-bloc-distrib').is('.hidden')) {
        if (value.PRODUCT_STATUS === OUT_OF_STORE_STATUS) {
            blockOutOfStockDistrib.find('.nbr-of-store').html(value.STOCK);
            blockOutOfStockDistrib.removeClass('hideStore');
            blockOutOfStockDistrib.removeClass('hidden');
            handleQuantityUpdate(refeq, value.STOCK);
        }
    }

}

function showAppropriateAvailability(dispo, value, blocDispo) {
    let availability;
    var deadline = value.DELAI;
    var comment = value.COMMENT !== undefined && value.COMMENT !== null ? value.COMMENT : '';
    switch (dispo) {
        case 'D':
            setAvailabilityD(value, comment, blocDispo);
            availability = blocDispo.filter('.dispoD').text();
            blocDispo.find('.delaiSimplifie').text('');
            break;
        case 'C':
        case 'P':
        case 'F':
            blocDispo.find('.delaiSimplifie').text(value.DELAI_SIMPLIFIE);
            if (deadline !== null) {
                deadline = deadline.concat('<br>').concat(comment)
            }

            availability = blocDispo.filter(DATA_DISPOECPF).text();
            blocDispo.find('.delai').html(deadline);
            blocDispo.filter(DATA_DISPOECPF).show();
            break;
        case 'E':
            availability = blocDispo.filter('.DispoE').text();
            if (value.DELAI != '--' || value.DELAI != '' || value.DELAI != null) {
                blocDispo.filter('.DispoE').find('.tooltips').removeClass('hide');
                blocDispo.filter('.DispoE').find('.delai').text(value.DELAI);
            }
            blocDispo.filter('.DispoE').show();
            break;
        case 'A':
            blocDispo.filter('.dispoA').show();
            availability = blocDispo.filter('.dispoA').find('.available-on-order').length > 0 ?
                blocDispo.filter('.dispoA').find('.available-on-order').text() :
                blocDispo.filter('.dispoA').text();
            if ( blocDispo.filter('.dispoA').find('.available-on-order').length > 0 && $('#type_promo').data('type-promo') === 'flash_24') {
                availability = "Disponible"
                blocDispo.filter('.dispoA').find('.available-on-order').html(`<i class="fa fa-check-circle float-left mR-3"></i>` + availability);
                blocDispo.filter('.dispoA').find('.available-on-order').parent().find('span.text-description-green.inline-span').hide();
                blocDispo.filter('.dispoA').find('.available-on-order').parent().find('a.tooltips').hide();
            }
            break;
        case 'I':
            availability = blocDispo.filter('.dispoI').text();
            blocDispo.find('.quantity-requested').html(value.QUANTITE)
            blocDispo.find('.quantity-available').html(value.NBARTICLES_DISPO)
            blocDispo.filter('.dispoI').show();

            break;
        case 'N':
            blocDispo.filter('.dispoN').show();
            availability = blocDispo.filter('.dispoN').text();

            break;
    }

    return availability;
}

function editBlocDispo(value, blocDispo, erpNokDistributor, inGlazingManufacturerAndHasAngAccess, availability) {
    // Check if equipment is eligible TecCom
    const isEquipmentEligibleTecCom = (value.IS_EQUIPMENT_ELIGIBLE_TECCOM !== undefined)
        ? value.IS_EQUIPMENT_ELIGIBLE_TECCOM : false;

    if (inGlazingManufacturerAndHasAngAccess === false && isEquipmentEligibleTecCom === false &&
        ((value.PVHT === "0,00") || (value.PVHT === "0") || (value.PVHT === "--") || (value.PVHT === "--,--"))) {
        blocDispo.filter('.DispoE').addClass('hidden');
        blocDispo.filter(DATA_DISPOECPF).addClass('hidden');
        blocDispo.filter('.dispoA').addClass('hidden');
        blocDispo.filter('.dispoD').addClass('hidden');
        if (erpNokDistributor === 'erp-distrib-nok') {
            blocDispo.find(DISPO_PRICE_UNAVAILABLE).removeClass('hidden');
            availability = blocDispo.find('.dispo-price-service-unavailable').text();
        } else {
            blocDispo.filter('.bloc-contact-your-distributor').show();
            availability = blocDispo.filter('.bloc-contact-your-distributor').find('.available-on-demand').length > 0 ?
                blocDispo.filter('.bloc-contact-your-distributor').find('.available-on-demand').text() :
                blocDispo.filter('.bloc-contact-your-distributor').children(':not(.tooltips)').text();
        }
    }

    return updateAvailabilityBloc( blocDispo,
        value,
        availability,
        inGlazingManufacturerAndHasAngAccess,
        isEquipmentEligibleTecCom
    );
}

/**
 * Update the availability bloc
 */
function updateAvailabilityBloc(
    blocDispo,
    value,
    availability,
    inGlazingManufacturerAndHasAngAccess,
    isEquipmentEligibleTecCom
) {
    // Get warnings message
    const warnings = parseJSON($('#warnings-codes').val());
    const accessNotFound = parseJSON($('#access-not-found').val());

    if ((inGlazingManufacturerAndHasAngAccess || isEquipmentEligibleTecCom) &&
        (['D','F','N','I'].indexOf((value.DISPO).slice(0, 1)) === -1)) {
        blocDispo.filter(DATA_DISPOECPF).addClass('hidden');
        blocDispo.filter('.dispoI').addClass('hidden');
        blocDispo.filter('.dispoD').addClass('hidden');
        blocDispo.filter('.dispoN').removeClass('hidden');
        blocDispo.filter(DISPO_ERROR).show();
        availability = blocDispo.filter('.dispoError').text();

        // Update of the tooltip in the case of supplier access data not filled in for the logged in user
        $.each(accessNotFound, function (key, access) {
            const warningMessageAccess = getTransOfWarningMessage(access);
            blocDispo.filter(DISPO_ERROR).find(".tooltips > span.tooltips-text").html(warningMessageAccess)
        });

        // Update of the tooltip in the case of warnings of price / availability from ANG WS
        if (warnings !== null) {
            const warning = warnings[value.REFEQ] !== undefined ? warnings[value.REFEQ] : null;
            if (warning !== null) {
                const warningMessage = getTransOfWarningMessage(warnings[value.REFEQ]);
                blocDispo.filter(DISPO_ERROR).find(".tooltips > span.tooltips-text").html(warningMessage)
            }
        }
    }

    return availability;
}

function updateBlocDispoPriceToDefault (refeq){
    refeq.attr('data-dispo', 'A');
    var blocDispo = refeq.find('.dispo-details');
    blocDispo.hide();
    blocDispo.filter('.dispoA').show();
    refeq.find('.bloc-price .ht-price').text('--,-- € HT');
    refeq.find('.bloc-price .ttc-price').text('--,-- € TTC');
    refeq.find('.bloc-price .ht-pa-price').text('--,-- € HT');
    refeq.find('.bloc-price .remise-pourcent').text('--%');
}

export function updateBlocPrice(refeq, value) {
    const packageLengthContent = $(idPackagesAdContent).length;
    refeq.attr('data-price', value.PVHT);
    refeq.closest(originData).attr('data-price', value.PVHT);

    refeq.attr('data-price-ht', value.PAHT);
    refeq.closest(originData).attr('data-price', value.PAHT);

    const pvht = checkAndFormatPriceOrRemise(value.PVHT);
    const htPriceFull = pvht + ' € HT' + '<span class="show_star"></span>';
    refeq.find('.bloc-price .ht-price').html(htPriceFull);

    const pvttc = checkAndFormatPriceOrRemise(value.PVTTC);
    const ttcPriceFull = pvttc + ' € <span class="rate-type"> TTC</span>';
    refeq.find('.bloc-price .ttc-price').html(ttcPriceFull);

    const paht = checkAndFormatPriceOrRemise(value.PAHT);
    const htPaFull = paht.concat(' € HT').concat('<span class="show_star"></span>');
    refeq.find('.bloc-price .ht-pa-price, .net-price-rate-ttc').html(htPaFull);
    refeq.closest(originData).attr(PAHT, paht);

    if(packageLengthContent !== 0) {
        refeq.find('.bloc-price .ht-pa-price').html(paht);
    }

    const pattc = checkAndFormatPriceOrRemise(value.PATTC);
    refeq.find('.net-price-rate-ht > .net-price-rate-ttc').html(pattc+' € TTC');
    const publicPrice = refeq.find('.pricing-block.bloc-price .public');
    const modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');

    if (value.PAHT.toString().replace('.', ',') !== value.PVHT.toString().replace('.', ',')) {
        publicPrice.removeClass('same-price-ht-ttc');
        if (modePrice === true) {
            publicPrice.removeClass('hidden');
        }
    } else {
        publicPrice.addClass('same-price-ht-ttc');
    }

    refeq.find('.bloc-price .discount-complementary').hide();
    refeq.find('.bloc-price .discount-complementary-2').hide();
    let discount = parseInt(value.REMISEPOURCENT);
    if ($.isNumeric(discount)) {
        refeq.find('.bloc-price .remise-pourcent').text(discount + ' %');

        discount = parseInt(value.ADDED_DISCOUNT);
        if ($.isNumeric(discount)) {
            refeq.find('.bloc-price .discount-complementary').text(discount + ' %');
            refeq.find('.bloc-price .discount-complementary').show();

            discount = parseInt(value.THIRD_DISCOUNT);
            if ($.isNumeric(discount)) {
                refeq.find('.bloc-price .discount-complementary-2').text(discount + ' %');
                refeq.find('.bloc-price .discount-complementary-2').show();
            }
        }

    } else {
        refeq.find('.discount-container').hide();
    }
    ProductAttributes.fromLoadPrice(refeq, value);
    ItemAttributes.fromLoadPrice(refeq, value);
    getPromotionBand(value, refeq, modePrice);
}

function checkAndFormatPriceOrRemise(itemToFormat){
    var formatedItem = itemToFormat;
    if(/^[0-9]*\.[0-9]{3,}$/.test(itemToFormat)){
        formatedItem = formatPriceAndRemise(itemToFormat);
    }
    return formatedItem;
}

function formatPriceAndRemise(price) {
    var priceString = price.toString();
    var dotId = priceString.indexOf(".");
    var priceFormated = priceString.slice(0, dotId+3);

    return parseFloat(priceFormated);
}

export function toggleBlocDispoPriceToResults(refeq){

    refeq.find('.loader-dispo-price').toggle();
    refeq.find('.dispo-price-bloc').toggle();
}

export function toggleBlocDispoPriceToError(refeq){
    refeq.find('.loader-dispo-price').toggle();
    refeq.find(DISPO_PRICE_UNAVAILABLE).toggle();
}

export function updateDispoPriceBloc(referenceToUpdate, refesh) {
    var referenceDatas = [];
    var formatedRef = formatRefeqParamsForErpRequest(referenceToUpdate);
    referenceDatas.push(formatedRef);
    return jQuery.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(referenceDatas)
        },
        url: Routing.generate('autossimo_ajax_call_erp_get_price'),
        async: true,
        beforeSend: function(){
            $(FOLDER_TIMEOUT_ALERT).addClass('hidden');
        },
        success: function (data) {
            const errorCode = data.data.error;
            const value = data.data.results[0];
            const warnings = data.data.warnings !== undefined ? data.data.warnings : [];
            if(
                (errorCode == null && data.data.results !== []) ||
                errorCode.code === 0 ||
                errorCode === 12 ||
                (errorCode[CORA_NO_SIRET] === noSiretCora && data.data.results !== []) ||
                 (errorCode[CORA_NOK_BDD] === 'cora-bdd' && data.data.results !== [])
            ){
                updateBlocDispo(referenceToUpdate, value);
                if (refesh !== undefined && refesh) {
                    updateBlocPrice(referenceToUpdate, value);
                }
                toggleBlocDispoPriceToResults(referenceToUpdate);
            } else if(errorCode[ERP_NOK_DIST]){
                var erpNOK = errorCode[ERP_NOK_DIST];
                $('.erp_nok_distributor').attr('value', erpNOK);
                toggleBlocDispoPriceToError(referenceToUpdate);
            } else {
                updateBlocDispoPriceToDefault(referenceToUpdate);
                toggleBlocDispoPriceToResults(referenceToUpdate);
                jQuery(classServiceUnavailable).hide();
            }
            if (isInGlazingFamily && data.data.success === false) {
                $(FOLDER_TIMEOUT_ALERT).removeClass('hidden');
            }
            // Display the warning messages
            if (isInGlazingFamily || IS_ELIGIBLE_TECCOM_PROVIDER) {
                displayWarningMessages(warnings, errorCode);
            }
            if (IS_ELIGIBLE_TECCOM_PROVIDER) {
                referenceToUpdate.find('.update-in-progress').hide();
                referenceToUpdate.find('.dispo-price-bloc').show();
            }
        },
        onFailure: function (error) {
            console.log('error', error);
            toggleBlocDispoPriceToError(referenceToUpdate);
        }
    });
}

function sortWithNoPrio(refOrigine) {
    var refOrig = refOrigine;
    //On slice les 9 premiers caractères de data-refeq qui ne sont pas utiles ici (ex : refEquip-5Q0615601F)
    var refOrigNum = refOrig.data('refeq').slice(9);
    var refOrigAss = refOrig.data('assignment');
    var refeq = refOrig.find('tr[data-pr="4"]');
    var packageLengthContent = $(idPackagesAdContent).length;
    if(packageLengthContent !== 0) {
        refOrigNum = refOrig.data('refeq').slice(14);
    }
    $.when(sortRefeqClassic(refeq)).then(function (data){
        sortAndAppendRef(data, refOrigNum, refeq, refOrigAss);
    });
}

export function applySortAndPrioRules(refOe) {
    var refOrigList = $(RESULTS_CONTAINER);
    if (isCoraTrackingFolder) {
        refOrigList = $(refOe);
    }
    refOrigList.each(function(){
        var reforigine = $(this);
        var hasPrio = false;
        reforigine.find(originData).each(function(){
            var refeq = this;
            var prio = refeq.getAttribute('data-pr');
            if (prio == '1' || prio == '2' || prio == '3') {
                hasPrio = true;
                return false;
            }
        });

        if (hasPrio) {
            var refOrigNum = $(this).data('refeq').slice(9);
            var refOrigAss = $(this).data('assignment');
            var refeqPrioDispo = $(this).find('tr[data-pr]').not('tr[data-pr="4"]').not('tr[data-dispo="A"]');
            var refeqPrio = $(this).find('tr[data-pr]').not('tr[data-pr="4"]');
            var packageLengthContent = $(idPackagesAdContent).length;
            if(packageLengthContent !== 0) {
                refOrigNum = $(this).data('refeq').slice(14);
            }
            if (refeqPrioDispo.length > 0){
                $(this).find('tr[data-pr="4"]').remove();
                $.when(sortRefeq(refeqPrio)).then(function(data){
                    sortAndAppendRef(data, refOrigNum, refeqPrio, refOrigAss);
                });
            } else {
                var refeqNonPrioDispo = $(this).find('tr[data-pr="4"]').not('tr[data-dispo="A"]');
                if (refeqNonPrioDispo.length > 0) {
                    var refeqPrioNonDispo = $(this).find('tr[data-pr]').not('tr[data-pr="4"]');
                    $(this).find('tr[data-pr="4"][data-dispo="A"]').remove();
                    $.when(sortRefeq(refeqNonPrioDispo)).then(function (data){
                        sortAndAppendRef(data, refOrigNum, refeqNonPrioDispo, refOrigAss);
                        $.when(sortRefeq(refeqPrioNonDispo)).done(function(newData){
                            sortAndAppendRef(newData, refOrigNum, refeqPrioNonDispo, refOrigAss);
                        });
                    });
                } else if (refeqPrio.length > 0) {
                    $(this).find('tr[data-pr="4"]').remove();
                    $.when(sortRefeq(refeqPrio)).then(function(data){
                        sortAndAppendRef(data, refOrigNum, refeqPrio, refOrigAss);
                    });
                }
            }
        } else {
            sortWithNoPrio($(this));
        }
    });
}

function removeRefeqNonPrioOnErrorErp () {
    var refOrigList = $(RESULTS_CONTAINER);
    refOrigList.each(function(){
        var reforigine = $(this);
        reforigine.find(originData).each(function(){
            var prio = this.getAttribute('data-pr');
            if(prio == '1' || prio == '2' || prio == '3'){
                reforigine.find('tr[data-pr="4"]').remove();
                return false;
            }
        });
    });
}

function sortRefeq (references) {
    var defer = $.Deferred();
    $.when(sortRefByDispo(references)).then(function(data){
        $.when(sortRefByPrio(references, data)).then(function(data){
            $.when(sortRefByEquipAndRef(references, data)).then(function(data){
                defer.resolve(data);
            });
        });
    });
    return defer.promise();
}

function sortRefeqClassic (references) {
    var defer = $.Deferred();
    $.when(sortRefByDispo(references)).then(function(data){
        $.when(sortRefByPrice(references, data)).then(function(data){
            $.when(sortRefByEquipAndRef(references, data)).then(function(data){
                defer.resolve(data);
            });
        });
    });
    return defer.promise();
}

function sortRefByDispo(references){
    var defer = $.Deferred();
    var sortDiv = [];
    var dispoToDisplay = ['D','C','P','F','E','I','A','N'];
    $(references).each(function(index){
        var valueSort = $(this).attr('data-dispo').slice(0, 1);
        var pushed = false;
        for (var i = 0; i < dispoToDisplay.length && false == pushed ; i++) {
            if (valueSort[0] === dispoToDisplay[i]) {
                sortDiv.push({key: index, value: i });
                pushed = true;
            }
        }
    });
    defer.resolve(sortDiv);
    return defer.promise();
}

function sortRefByPrio(references, arrayDatas){
    var defer = $.Deferred();
    for(var i = 0; i < references.length; i++){
        var prio = references[i].getAttribute('data-pr');
        var sortedValue = arrayDatas[i].value + prio;
        arrayDatas[i].value = sortedValue;
    }
    defer.resolve(arrayDatas);
    return defer.promise();
}

function sortRefByPrice(references, arrayDatas){
    var defer = $.Deferred();
    var pricesOrder = [];
    for (var i = 0; i < references.length; i++) {
        var price = $(references[i]).find('.containerDispo').attr('data-price');
        pricesOrder.push({key:i, price: parseFloat(price)});
    }

    pricesOrder.sort(function (a, b) {
        if (a.price > b.price) {
            return -1;
        }
        else if (a.price < b.price) {
            return 1;
        }
        return 0;
    });

    var newPricesOrder = [];
    for (var k = 0; k < pricesOrder.length; k++) {
        if (pricesOrder[k].price === 0) {
            newPricesOrder.push(pricesOrder[k]);
        } else {
            newPricesOrder.unshift(pricesOrder[k]);
        }
    }

    for (var l = 0; l < references.length; l++) {
        var priceOrder;
        for (var j = 0; j < newPricesOrder.length; j++) {
            if (newPricesOrder[j].key == l) {
                if (newPricesOrder[j].price !== 0) {
                    priceOrder = j.toString();
                } else {
                    priceOrder = '';
                }
            }
        }
        var sortedValue = arrayDatas[l].value + '-' + priceOrder;
        arrayDatas[l].value = sortedValue;
    }
    defer.resolve(arrayDatas);
    return defer.promise();
}

function sortRefByEquipAndRef(references, arrayDatas){
    const defer = $.Deferred();
    for (let i = 0; i < references.length; i++) {
        const equip = $(references[i]).attr('data-libequip');
        const trimEquip = equip.replace(/ /g,"");
        const ref = $(references[i]).attr(DATA_REFEQUIP);
        const equiprefeq = trimEquip + ref;
        const sortedValue = arrayDatas[i].value + equiprefeq;
        arrayDatas[i].value = sortedValue;
    }
    defer.resolve(arrayDatas);
    return defer.promise();
}

function alphaNumSorter(as, bs){
    var a, b, a1, b1, i= 0, n, L,
        rx=/(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
    if (as.value=== bs.value) {
        return 0;
    }
    a= as.value.toLowerCase().match(rx);
    b= bs.value.toLowerCase().match(rx);
    L= a.length;
    while(i<L){
        if (!b[i]) {
            return 1;
        }
        a1= a[i];
        b1= b[i++];
        if (a1!== b1) {
            n= a1-b1;
            if (!isNaN(n)) {
                return n;
            }
            return a1>b1? 1:-1;
        }
    }
    return b[i]? -1:0;
}

function sortAndAppendRef(data, refOrigNum, refToSortAndAppend, refOrigAss) {
    var packageLengthContent = $(idPackagesAdContent).length;
    if (typeof(refOrigAss) != "number") {
        refOrigAss = refOrigAss.replace(/'/g,"\\'");
    }
    refOrigNum = refOrigNum.replaceAll('/',"\\");
    var attrRefeq = 'refEquip-' + refOrigNum;
    if(packageLengthContent !== 0) {
        attrRefeq = 'equipementRef-' + refOrigNum;
    }

    data.sort(function(a,b){
        return alphaNumSorter(a,b);
    });
    data.sort(function(a,b){
        return sortElementsByOutOfStore(a,b, refToSortAndAppend);
    });
    data.forEach(function (reference) {
        $("div[data-refeq='" + attrRefeq + "'][data-assignment='" + refOrigAss + "'] #table-content-"+refOrigNum).append($(refToSortAndAppend[reference.key]));
    });
}

function sortElementsByOutOfStore(referenceA, referenceB, refToSortAndAppend) {
    const firstElement = $(refToSortAndAppend[referenceA.key]);
    const secondElement = $(refToSortAndAppend[referenceB.key]);

    const isDisplayedA = firstElement.find(OUT_OF_STORE).css('display') === 'block';
    const isDisplayedB = secondElement.find(OUT_OF_STORE).css('display') === 'block';

    return compareElementsAvailability(firstElement, secondElement, isDisplayedA, isDisplayedB);
}

export function compareElementsAvailability(firstElement, secondElement, isDisplayedA, isDisplayedB) {
    const availabilityList = ['D', 'C', 'P', 'F', 'E', 'I', 'A', 'N'];
    let firstIndex = -1;
    const firstData = firstElement.data('dispo');
    if (firstData !== undefined && firstData !== null) {
        firstIndex = availabilityList.indexOf(firstData.slice(0, 1));
    }
    firstIndex = firstIndex !== -1 ? firstIndex : availabilityList.length;

    let secondIndex = -1;
    const secondData = secondElement.data('dispo');
    if (secondData !== undefined && secondData !== null) {
        secondIndex = availabilityList.indexOf(secondData.slice(0, 1));
    }
    secondIndex = secondIndex !== -1 ? secondIndex : availabilityList.length;

    if (isDisplayedA !== isDisplayedB) {
        return isDisplayedA ? -1 : 1;
    } else {
        return firstIndex - secondIndex;
    }
}

export function removeNonPriorisedEquipInGlobalFilter() {

    const visibleEquip = [];
    $(originData).each(function(){
        visibleEquip.push($(this).attr(DATA_EQUIP));
    });
    const equipList = _.uniq(visibleEquip);
    const options = $('#formfiltrer #selectEquip option');

    equipList.push('tous');
    options.not(function(){
        const value = this.getAttribute('value');

        return jQuery.inArray(value, equipList) < 0 ? false : true;
    }).remove();

    const optionsPrio = $('#formfiltrer #selectEquip option');
    const equipPrioFullName = ['TOUS LES EQUIPEMENTIERS'];

    _.forEach(optionsPrio, function(optionPrio){
        if($(optionPrio).data('content')){
            equipPrioFullName.push($(optionPrio).data('content'));
        }
    });

    $('#selectEquip').parent().find('ul li').not(function(){
        const content = $(this).text();

        return jQuery.inArray(content, equipPrioFullName) < 0 ? false : true;
    }).remove();


    $('#formfiltrer #selectEquip').selectpicker("refresh");
}

function removeNonPriorisedEquipInRefoFilters() {

    $(RESULTS_CONTAINER).each(function(){
        const refo = $(this);
        const visibleEquipInRefo = [];

        refo.find(originData).each(function(){
            visibleEquipInRefo.push($(this).attr(DATA_EQUIP));
        });

        const equipListInRefo = _.uniq(visibleEquipInRefo);
        equipListInRefo.push('tous');

        const optionsInRefo = refo.find('.filtreEquipementiers option');
        const singleOptionId = [];
        optionsInRefo.not(function(){
            const value = this.getAttribute('id');
            let firstEquipOption = false;
            if(jQuery.inArray(value, singleOptionId) < 0){
                singleOptionId.push(value);
                firstEquipOption = true;
            }
            var isIncluded = jQuery.inArray(value, equipListInRefo) < 0 ? false : true;
            return isIncluded && firstEquipOption;
        }).remove();

        const optionsPrioInRefo = refo.find('.filtreEquipementiers option');
        const equipPrioFullName = ['Filtrer...'];

        _.forEach(optionsPrioInRefo, function(optionPrioInRefo){
            if($(optionPrioInRefo).text()){
                equipPrioFullName.push($(optionPrioInRefo).text());
            }
        });

        refo.find('.filtreEquipementiers ul li').not(function(){
            var content = $(this).text();

            return jQuery.inArray(content, equipPrioFullName) < 0 ? false : true;
        }).remove();

        refo.find('#equipementiers').selectpicker("refresh");
    });
}

function updateQuantityBlock(references) {
    references.each(function(index, reference) {
        const productAvailabilty = $(reference).attr('data-dispo').slice(0, 1);
        const productEquipment = $(reference).attr(DATA_EQUIP);
        const productPrice = $(reference).attr('data-price-ht') !== undefined ?
            $(reference).attr('data-price-ht') :
            $(reference).attr('data-price');
        const chooseDisabled = checkChooseDisabled(productAvailabilty, productPrice, productEquipment);
        if (chooseDisabled) {
            $(reference).find('.qty-btn').addClass('disabled');
            $(reference).find(inputQuantity).prop('readonly', true);
            clickChoicePiece($(reference).find('.btn-edit input'), true);
        }
        // AD-7971 update IAM quantities to match that of OE
        $(reference).find('.block-quantity').find('input[type="number"]').val($(reference)
            .closest(REF_CONTAINER).attr(DATA_QUANTITY));
    })
}

function checkChooseDisabled(productAvailabilty, productPrice, productEquipment) {
    // CORA unavailable
     return ($.inArray(productAvailabilty, ['', 'A']) > -1 && productEquipment === 'CORA') ||
    // ANG unavailable
    ($.inArray(productAvailabilty, ['I', 'N'])) > -1 ||
    (productAvailabilty === 'A' && productPrice === '--,--') ||
    // no price AND (not tracking folder OR CORA+tracking folder)
    (
        productPrice === '--,--' &&
        (!isCoraTrackingFolder || (isCoraTrackingFolder && productEquipment === 'CORA'))
    );
}

/**
 * Display the warning message
 *
 * @param warnings
 * @param errorErp
 */
export function displayWarningMessages(warnings, errorErp = null) {
    // Get data info
    const data = getDataInfo();
    // Get list of referencesIAM
    const referencesIAM = data.referencesIAM;

    if (warnings !== null) {
        $.each(warnings, function (key, warning) {
            if ((referencesIAM.indexOf(key) !== -1) || (key === 'TECHNICAL_ERROR')) {
                $('.glassmaker-alert.' + warning).removeClass('hidden');
            }
            if(IS_ELIGIBLE_TECCOM_PROVIDER) {
                $('.glassmaker-alert.' + warning).removeClass('hidden');
            }
        });
    }

    // Display Cora warning
    if (errorErp !== null) {
        if (data.existCoraProduct === true) {
            displayCoraError(errorErp);
        }
    }

    $(FOLDER_TIMEOUT_ALERT).addClass('hidden');
}

/**
 * Get data info
 */
function getDataInfo() {
    let referencesIAM = [];
    let existCoraProduct = false;

    // Build bloc selector
    let containerBloc = $('div.results-container.stopGetPrice');

    // If reference search then update bloc selector
    if ($('.reference-produits-container.results-search-refeq').length > 0 ||
        $('.reference-produits-container.reference-origine-results').length > 0 ) {
        containerBloc = $('div.references-produit-list');
    }

    // Foreach of container bloc of references to get list of displayed references IAM
    containerBloc.not('.hide').each(function () {
        $(this).find(originData).each(function () {
            referencesIAM.push($(this).attr('data-refequip'));
            if ($(this).attr(DATA_EQUIP) === 'CORA') {
                existCoraProduct = true;
            }
        });
    });

    if ($(folderGlassMakers).length > 0) {
        const data = getDataCaseFolderGlassMakers();
        referencesIAM = data.referencesIAM;
        existCoraProduct = data.existCoraProduct;
    }

    return {
        'referencesIAM': referencesIAM,
        'existCoraProduct': existCoraProduct
    };
}

/**
 * Get data info for folder glass makers case
 */
function getDataCaseFolderGlassMakers() {
    const referencesIAM = [];
    let existCoraProduct = false;

    $('.ref-oe-container[data-nb-iam-refs!="0"]').each(function (index, value) {
        if (($(value).attr(DATA_STATUS) !== 'ORDERED') && ($(value).attr(DATA_STATUS) !== 'CANCELED')) {
            $.each($(value).find('tr.origin-equivalence').not('.hide'), function () {
                if (($(this).attr(DATA_STATUS) !== 'ORDERED') && ($(this).attr(DATA_STATUS) !== 'CANCELED')) {
                    referencesIAM.push($(this).attr(DATA_REFEQUIP));
                    if($(this).attr(DATA_EQUIP) === 'CORA') {
                        existCoraProduct = true;
                    }
                }
            });
        }
    })

    return {
        'referencesIAM': referencesIAM,
        'existCoraProduct': existCoraProduct
    };
}

/**
 * Parse json content
 *
 * @param content
 */
export function parseJSON(content) {
    let parsedContent;
    try {
        parsedContent = JSON.parse(content);
    } catch (e) {
        parsedContent = null;
    }

    return parsedContent;
}

/**
 * Get trans of warning message
 *
 * @param warning
 * @returns {string}
 */
function getTransOfWarningMessage(warning) {
    let warningMessage = "";
    switch (warning) {
        case 'ang_technical_error':
            warningMessage = 'Une erreur technique ne permet pas de récupérer tous vos prix et disponibilités.'.concat(
                '<br>Veuillez réessayer ultérieurement. Si le problème persiste, contactez le support.'
            );
            break;
        case 'ang_invalid_pilkington_credentials_error':
            warningMessage = 'Vos identifiants <strong>PILKINGTON</strong> sont incorrectes.<br> '.concat(
                'Vous devez les modifier pour pouvoir récupérer vos tarifs et disponibilités chez ce fournisseur.'
            );
            break;
        case 'ang_autover_error':
            warningMessage = 'Impossible de récupérer vos tarifs pour <strong>AUTOVER</strong>. <br>'.concat(
                'Contactez votre distributeur Autodistribution pour mettre à jour votre compte.'
            );
            break;
        case 'PILKINGTON':
            warningMessage = 'Impossible de récupérer vos tarifs pour <strong>PILKINGTON</strong> <br>'.concat(
                'Vous devez renseigner votre compte pour pouvoir récupérer vos tarifs et disponibilités chez ce fournisseur.'
            );
            break;
    }

    return warningMessage;
}
/*
 * Display cora error
 *
 * @param errorErp
 */
function displayCoraError(errorErp) {
    // Display Cora no-siret warning
    if (errorErp[CORA_NO_SIRET] === noSiretCora) {
        $('.glassmaker-alert.cora-no-siret').removeClass('hidden');
    }
    // Display Cora error BDD
    if (errorErp[CORA_NOK_BDD] === 'cora-bdd') {
        $('.glassmaker-alert.alert-message-service-unavailable').removeClass('hidden');
    }
}

/**
 * Set bloc simplified deadline and leadTime for 'D' availability
 */
function setAvailabilityD(value, comment, blocDispo){
    let deadline = value.DELAI;
    const isAttachmentDepositDelay = value.DELAY_FROM_ATTACHEMENT;
    const isEquipmentEligibleTecCom = value.IS_EQUIPMENT_ELIGIBLE_TECCOM !== undefined
        ? value.IS_EQUIPMENT_ELIGIBLE_TECCOM : false;

    if (deadline !== null && deadline !== '--') {
        blocDispo.find('.tooltips').removeClass('hidden');
        if (isAttachmentDepositDelay !== true) {
            deadline = 'A partir de '.concat(deadline.concat('<br>').concat(comment));
        } else {
            blocDispo.find('.delaiSimplifie').text(value.DELAI_SIMPLIFIE);
        }
    }

    blocDispo.find('.delai').html(deadline);

    // Set leadTime limit and simplified deadline for equipment eligible TecCom
    if (isEquipmentEligibleTecCom === true) {
        blocDispo.find('.delaiSimplifie').text(value.DELAI_SIMPLIFIE);
        blocDispo.find('.delai').html(value.DELAI);
    }

    blocDispo.filter('.dispoD').show();
}
