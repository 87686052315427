import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import ViewAvailabilitiesNormalizersResolver from "./ViewAvailabilitiesNormalizersResolver";
export default class ViewAvailabilitiesDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new ViewAvailabilitiesNormalizersResolver());
        }
        return this.builderInstance;
    }
}
