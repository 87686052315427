import {fillInPriceDispo} from "./pubtech-devis-step4";
import {loadPriceDependingOnViscosity, updateInfosRef} from "./pubtech-vehicule-affectation-origine";

require('bootstrap-select');
const OPTION_SELECTED = 'option:selected';
const MYSELECT_OIL = '#mySelectOil';
const DATA_OIL_TYPE = 'data-oiltype';
const ENGINE_OIL_SELECTOR = 'tr[type-objet="Engine oil"]';
const SELECT_VISCO = 'select.selectVisco';
const ALL_ENGINE_OILS = '#allEngineOils';
const ENGINE_OIL_CODFAM = 'MLI';
const ALL_FILTERS_LANDMARK = '12-10-6';
const SELECT_VISO = '.selectVisco';
const POP_IN_VEHICLE_MODEL_SELECTION = '#popinVehicleModelSelection';
const BLOCK_OIL_ENGINE = '.blocOilEngine';
const ENGINE_OIL_LOADER = '#engine_oil_loader';
const POPIN_REPAIR_TIMES = '#popinTempsBaremes';
const POPIN_MULTIPLE_REPAIR_TIMES = '#popinTempsBaremes .multiple-repairer-times';
const POPIN_REPAIR_TIMES_AUTODATA = '#popinTempsBaremesAutodata';
const BARED_TIME = '.bared_time';
const AFFECTATION_ORIGIN = '#affectation-origine-ajax';

$(document).ready(function () {
    // Update blocks on initial page load
    checkCheckboxesMultipleModels();

    $(document).on('change', MYSELECT_OIL, function () {
        getOilPrice($(this));
    })

    // Object to track if it's the first click
    let clickState = { firstClick: true };

    // Target checkboxes with for model vehicle
    $(document).on('click', '.model-selection-mid, .vehicle-model-checkbox', function (event) {
        // Get the 'MID' and 'errorAutodata' if exist from the data attribute
        const mid = $(this).attr("data-mid");
        const errorAutodata = $(this).attr("data-error");
        const isOil = $(this).attr("data-isOil");

        const vehicleModelData = {
            'mid' :  $(this).data('mid'),
            'model' : $(this).data('model'),
            'extra_info' : $(this).data('extra_info'),
            'start_year' : $(this).data('start_year'),
            'enginecode' : $(this).data('enginecode'),
            'end_year' : $(this).data('end_year'),
            'din_hp' : $(this).data('din_hp'),
        }

        // Fetch recommended oils based on model ID (mid) only if we are in oils page
        if (isOil) {
            // Clear the value of the input field (filterEngineOil)
            $("#filterEngineOil").val('');

            // Hide the bloc info choice models
            $(".must-choice-models").addClass('hidden');

            $(SELECT_VISO).selectpicker({
                title: "Choisir viscosité"
            });
            $(SELECT_VISO).selectpicker('val', []);

            // Get the data oils
            getOilsData(mid, vehicleModelData, event, clickState, errorAutodata);
        }

        // Hide the model
        $(POP_IN_VEHICLE_MODEL_SELECTION).modal('hide');

        // Get the detail of bared time
        if ($(event.target).hasClass('model-selection-mid')) {
            getDetailOfBaredTime(mid, vehicleModelData);
        }
    });

    // Get all option models
    $(document).on('click', '#optionModels', function (e) {
        $.ajax({
            url: Routing.generate('autossimo_autodata_get_all_vehicle_model'),
            type: "GET",
            success: function (data) {
                updatePopin(data);
            }
        });
    });

    // Listen for checkbox changes (event delegation)
    const checkboxes = $('.vehicle-model-selection');
    checkboxes.on("change", ".vehicle-model-checkbox", checkCheckboxesMultipleModels);
});

// Function to check if at least one checkbox is selected
function checkCheckboxesMultipleModels() {
    // Select the elements
    const selectionAlert = $('.must-choice-models');
    const results = $(AFFECTATION_ORIGIN);
    const modelSelection =$('.vehicle-model-selection');

    const checkboxes = modelSelection.find(".vehicle-model-checkbox");
    const isChecked = checkboxes.filter(":checked").length > 0;

    // If checkboxes exist but none are checked, show alert and hide results
    if (checkboxes.length > 0) {
        results.toggleClass('hidden', !isChecked);
        selectionAlert.toggleClass('hidden', isChecked);
    } else {
        // If no checkboxes exist, show results directly
        results.removeClass('hidden');
        selectionAlert.addClass('hidden');
    }
}

// Get the detail of bared time
function getDetailOfBaredTime(mid = '', vehicleModelData = {}) {
    const codfam = $(BARED_TIME).attr('data-codfam');
    const urlParams = {
        codfam: codfam,
        landmark: $(BARED_TIME).attr('data-landmark'),
        codmar: $(BARED_TIME).attr('data-codmar'),
        numOrd: $(BARED_TIME).attr('data-numOrd'),
        sVin: $(BARED_TIME).attr('data-sVin'),
        immat: $(BARED_TIME).attr('data-immat'),
        typeMine: $(BARED_TIME).attr('data-typeMine'),
        iDmec: $(BARED_TIME).attr('data-iDmec'),
        type: $(BARED_TIME).attr('data-type'),
        libFa: $(BARED_TIME).attr('data-libFa'),
        libSG: $(BARED_TIME).attr('data-libSG'),
        libRep: $(BARED_TIME).attr('data-libRep'),
    };

    // Get bared time
    $.ajax({
        url:  Routing.generate('autossimo_popin_temps_baremes', {codfam:codfam}),
        type: "GET",
        data: {
            urlParams,
            mid,
            vehicleModelData,
        },
        success: function (data) {
            $(POPIN_REPAIR_TIMES).html(data);
            $(POPIN_REPAIR_TIMES_AUTODATA).modal('hide');
            $(POPIN_REPAIR_TIMES).modal('show');
        }
    });
}

function updatePopin(data){
    $(POPIN_REPAIR_TIMES).modal('hide');
    $(POPIN_MULTIPLE_REPAIR_TIMES).modal('hide');
    $(POPIN_REPAIR_TIMES_AUTODATA).modal('hide');
    $(POP_IN_VEHICLE_MODEL_SELECTION).html(data);
    $(POP_IN_VEHICLE_MODEL_SELECTION).modal('show');
}

export function getOilsData(mid, vehicleModelData, event, clickState, errorAutodata = '') {
    const checkbox = $(".vehicle-model-selection .radio-models input[data-mid='" + mid + "']");

    $.ajax({
        url: Routing.generate('autossimo_recommended_oils_get'),
        type: "GET",
        data: {
            mid: mid,
            vehicleModelData: vehicleModelData
        },
        beforeSend: function () {
            // Prevent displaying 0 of origin reference results
            $('.nb-ref-origine').filter(function () { return $(this).html().trim() === '0'; }).html('');

            // Open the oil filter
            $("#showFilters").trigger("click");
        },
        success: function (data) {
            $(ENGINE_OIL_LOADER).removeClass('hidden');
            $("#error-autodata-bloc").addClass('hidden');
            $(BLOCK_OIL_ENGINE).html('');

            if (!errorAutodata) {
                setTimeout(function() {
                    // Update the content in BLOCK_OIL_ENGINE with the new data
                    $(BLOCK_OIL_ENGINE).html(data);

                    // Update the info of references
                    updateInfosRef();

                    // Update the selection viscosity
                    updateSelectViscosity();

                    // Load price depending on viscosity
                    if ($(event.target).hasClass('vehicle-model-checkbox')
                        && (clickState && typeof clickState.firstClick !== 'undefined') && clickState.firstClick) {
                        loadPriceDependingOnViscosity();
                        // Change the state after the first click
                        clickState.firstClick = false;
                    }

                    // Hide the loader after content is updated
                    $(ENGINE_OIL_LOADER).addClass("hidden");
                }, 2000);

                // Prop the checkbox
                if (checkbox.length > 0) {
                    checkbox.prop("checked", true);
                }
            } else {
                $('.error-autodata-bloc').removeClass('hidden');
            }
        },
        onFailure: function () {
            $('.error-autodata-bloc').removeClass('hidden');
        }
    });
}

export function getPriceOil(id, pvht) {
    const sellPrice = $(`#selling-${id} .DEprixVenteEF`);
    let priceOil = sellPrice.val();
    const refName = $('#bill-name-' + id).find('.ref_name').text().trim();
    const featureFlagOil = $('#oil_price_feature_flag').data('value');
    if (refName === 'Huile moteur' && featureFlagOil === true) {
        priceOil = parseFloat(priceOil) * parseFloat($(`#appoint-${id} + input.capacity_value`).val());
        priceOil = Math.ceil(priceOil);
        priceOil = priceOil.toFixed(2).replace('.', ',');
        sellPrice.val(priceOil);
        sellPrice.attr('data-value', parseFloat(priceOil).toFixed(2).replace(',', '.'));
    } else {
        sellPrice.val(pvht.toFixed(2).replace('.', ','));
        sellPrice.attr('data-value', pvht.toFixed(2).replace(',', '.'));
    }
}

export function getOilPrice(element) {
    const oilType = $("#mySelectOil :selected").attr(DATA_OIL_TYPE);
    const codref = element.val().split(' ');
    $.ajax({
        url: Routing.generate('autossimo_get_oil_price'),
        type: "GET",
        data: {
            codrep: element.attr('data-codrep'),
            codref: codref[1],
            oilType: oilType,
        },
        success: function (data) {
            showPreferred(element);
            if (!data.length) {
                const line = $(MYSELECT_OIL).parent().parent().attr('data-id');
                $(`#selling-${line} .DEprixVenteEF`).val('0,00');
            } else {
                $.each(data, function (index, value) {
                    if (value.oilType === element.find(OPTION_SELECTED).attr(DATA_OIL_TYPE)) {
                        element.closest(ENGINE_OIL_SELECTOR).find('.DEprixVenteEF').val(value.literPrice);
                        let capacity = parseFloat(element.closest(ENGINE_OIL_SELECTOR).find('.capacity_value').val());
                        capacity = Math.ceil(capacity * value.literPrice);
                        element.closest(ENGINE_OIL_SELECTOR).find('.DEprixVenteEF').attr(
                            'data-oldPrice',
                            capacity.toFixed(2)
                        );
                        fillInPriceDispo(element.closest(ENGINE_OIL_SELECTOR).attr('data-id'), element.val());
                    }
                })
            }
        },
    });
}

export function updateOilPrice(element) {
    const featureFlagOil = $('#oil_price_feature_flag').data('value');
    if (featureFlagOil && element.length > 0) {
        const line = $(MYSELECT_OIL).parent().parent().attr('data-id');
        let priceOil = $(`#selling-${line} .DEprixVenteEF`).val();
        const capacity = parseFloat($(`#appoint-${line} + input.capacity_value`).val());
        const oilType = element.find(OPTION_SELECTED).attr(DATA_OIL_TYPE);
        priceOil = priceOil.replace(',', '.');
        let sellPrice = parseFloat(priceOil) / parseFloat(capacity);
        sellPrice = sellPrice.toFixed(3).slice(0, -1)
        const viscosity = element.val().split(' ');

        $.ajax({
            url: Routing.generate('autossimo_update_oil_price'),
            type: "POST",
            dataType: "json",
            data: {
                'dataOil': {
                    oilType: oilType,
                    viscosity: viscosity[1],
                    literPrice: sellPrice,
                }
            },
        });
    }
}

function showPreferred(element) {
    // Store the parent of the current element
    const $parent = element.parent();
    // Clear the strong text
    $parent.find('.oil-preferred').remove();
    $parent.find('.oil-infos').remove();
    // displaying the preferred label from the data-preferred attribute
    $parent.prepend(`<strong class='oil-preferred'>${element.find(OPTION_SELECTED).data('preferred')}</strong>`);
    // displaying the oil type from the data-oiltype
    element.parent().append(`<strong class='oil-infos'>${element.find(OPTION_SELECTED).data('oiltype')}</strong>`);
}

/**
 * Test if equipment Principal match && equipment parent match && viscosity match && && Capacity match with the element.
 */
export function isProductDisplayed(element) {
    const selectedItemsViscosity = $(SELECT_VISCO).val();
    const selectedItemsCapacity = $('select.select-capacity').val();
    const selectedItemsEquipementParent = element.parent().parent().find('select#equipementiers').val();
    const selectedItemsEquipementPrincipal = $('#selectEquip').val();
    let viscoSelected = true, equipementParentSelected = true, equipementPrincipalSelected = true,
        capacitySelected = true;
    const isAllFilters = $('.blocFilters').data('landmark') === ALL_FILTERS_LANDMARK;
    const codFam = $(element).find('td div[id^="quote-data-"]').data('fam');
    if (selectedItemsViscosity != null) {
        viscoSelected = selectedItemsViscosity.some(function (value) {
            if ((true === isAllFilters && codFam === ENGINE_OIL_CODFAM) || false === isAllFilters) {
                return element.attr('data-visco') === value;
            }
            return true;
        });
    }

    if (selectedItemsEquipementParent != null) {
        equipementParentSelected = selectedItemsEquipementParent.some(function (value) {
            return element.attr('data-equip') === value;
        });
    }

    if (selectedItemsCapacity != null) {
        capacitySelected = selectedItemsCapacity.some(function (value) {
            if ((true === isAllFilters && codFam === ENGINE_OIL_CODFAM) || false === isAllFilters) {
                return element.attr('data-capacity').replace(' ', '') === value.replace(' ', '');
            }
            return true;
        });
    }

    if (selectedItemsEquipementPrincipal != null) {
        equipementPrincipalSelected = selectedItemsEquipementPrincipal.some(function (value) {
            return element.attr('data-equip') === value;
        });
    }

    return equipementPrincipalSelected && viscoSelected && equipementParentSelected && capacitySelected;
}

function onChangeSelectFilterOil(selectedCriteria, selectId, isEngineOil = false) {
    const subResultTrs = $('div.sub_result tbody tr');
    selectMultiDisplayedText(selectedCriteria, selectId);
    subResultTrs.each(function () {
        const element = $(this);
        const codFam = $(element).find('td div[id^="quote-data-"]').data('fam');
        if ((codFam === ENGINE_OIL_CODFAM && isEngineOil ) || !isEngineOil) {
            (isProductDisplayed(element) ? element.removeClass('hide') : element.addClass('hide'));
        }
        if ($('div.results-container.noequip').not('.hide').length > 0) {
            $('div.results div.noresults').removeClass('hide').insertAfter($('div.results-container').not('.noequip').last());
        }
    });

    toggleDisplaySubResult(isEngineOil);
}

function selectMultiDisplayedText(selectedCriteria, selectId) {
    if (selectedCriteria != null && selectedCriteria.length > 1) {
        $(`button[data-id="${selectId}"]`).attr('title', selectedCriteria.length + " sélectionnés");
        $(`button[data-id="${selectId}"] > span:first-child`).html(selectedCriteria.length + " sélectionnés");
    }
}

function toggleDisplaySubResult(isEngineOil = false) {
    $('div.results-container').each(function () {
        const isElementDisplayedAndHasntNoequip = !$(this).hasClass('hide') && !$(this).hasClass('noequip');
        const hasntNoEquipementiers = $(this).find('.choix-equipementiers > .noEquipementiers').length === 0;
        const isAllFilters = $('.blocFilters').data('landmark') === ALL_FILTERS_LANDMARK;
        const displayEngineOil =
            isEngineOil && !isAllFilters && isElementDisplayedAndHasntNoequip && hasntNoEquipementiers;
        $(this).find('.sub_result').toggle(
            (isElementDisplayedAndHasntNoequip && hasntNoEquipementiers && !isEngineOil) || displayEngineOil
        );
    });
}

//Filtrer par viscosity
$(SELECT_VISO).selectpicker({
    showIcon: true,
});

$(document).on('change', SELECT_VISCO , function () {
    onChangeSelectFilterOil($(this).val(), 'selectVisco');
});

//Filtrer par Capacity
$('.select-capacity').selectpicker({
    showIcon: true,
});

$(document).on('change', 'select.select-capacity', function () {
    onChangeSelectFilterOil($(this).val(), 'select-capacity')
});

export function updateSelectViscosity()
{
    const selectVisco = $(SELECT_VISCO);
    const oilEngineClass = $('.infosOilEngineDouble').length > 0 ? '.infosOilEngineDouble' : '.infosOilEngine';
    const oilBloc = $('#shelter22').find('.blocOilEngine');
    const blocViscosity = $(oilBloc).find(oilEngineClass.concat(' tbody span.viscosity'));
    if (selectVisco.length > 0) {
        selectOptionVisco(blocViscosity);
        if ($(ALL_ENGINE_OILS).length > 0) {
            const mid = $(ALL_ENGINE_OILS).data('mid');
            const errorAutodata = $(ALL_ENGINE_OILS).data('error');
            $.ajax({
                url: Routing.generate('autossimo_popin_engine_oils'),
                type: "GET",
                async: false,
                data: {
                    mid : mid
                },
                success: function (data) {
                    if(!errorAutodata) {
                        $('#popinEngineOils .modal-body table tbody').html(data);
                        const blocVisco = $('#listOilEngine').find('tbody span.viscosity');
                        selectOptionVisco(blocVisco);
                    }
                }
            });
        }
        if (null !== selectVisco.val() && selectVisco.val().length !== 0) {
            onChangeSelectFilterOil(selectVisco.val(), 'selectVisco', true);
        }
    }
}

function selectOptionVisco(blocVisco)
{
    const tabViscosity = [];
    $(blocVisco).each(function () {
        let viscosity = $(this).text();
        if (viscosity.trim().length > 0) {
            viscosity = viscosity.replace(/[\r\n\t\--]+/g,'').trim();
            const regex = new RegExp('[0-9]{1,2}W[0-9]{1,2}');
            viscosity = viscosity.match(regex)[0];
            $('select.selectVisco option[value="'.concat(viscosity).concat('"]')).attr('selected','selected');
            $(SELECT_VISO).selectpicker('refresh');
            tabViscosity.push(viscosity);
        }
    });

    const lengthTabViscosity = tabViscosity.length;
    if (lengthTabViscosity > 0) {
        $(SELECT_VISO).val(tabViscosity).selectpicker('refresh');
    }
}
