import { formatAvailability } from "./manage-ga";

let $ = require('jquery');

import {getPriceLevel} from "./price-level";
import {updateBasket, ajouterPanierSidexa,ajouterPanier} from './pubtech-references-action';
import {initDatePickerAjax, switchModeDisplayCartPricing} from "./pubtech-main-v2";
import {
    toggleBlocDispoPriceToResults,
    updateDispoPriceBloc,
    displayWarningMessages,
    updateBlocPrice,
    toggleBlocDispoPriceToError,
    formatRefeqParamsForErpRequest
} from "./pubtech-dispo-price"
import {recalculateTotals} from "./pubtech-devis-step4";
import { calculTotal } from './pubtech-punchout';
import {alertForbidden} from './pubtech-popin';
import {handlePanierImageError} from "./imageErrors";
import {showPromoBands} from './promotion-bands';
import {isValidPromotionsInCart} from "./promotion/manage-advertising-banner-in-cart";
import {validStockCart} from "./manage-product-status";
import EventHandler from './analytics/amplitude/EventHandler';
import { getDataQuantityChanged } from "./analytics/utilities";
import { ProductAttributes } from "./analytics/amplitude/dto/product/ProductAttributes";
import { FOLDER_COST } from "./analytics/constants";
import {checkQuantityPricing} from "./quantityManager";
import { ItemAttributes } from "./analytics/ga/dto/item/ItemAttributes";
import GaEventHandler from "./analytics/ga/GaEventHandler";


var disabled = 'disabled';
const SPAN_EMPTY_CART = 'span.emptyCart';
const C_CONTAINER_DISPO = '.containerDispo';
const DATA_QUANTITY = 'data-quantity';
const CONTAINER_DISPO_DATA_REFEQ = '.containerDispo[data-refeq]';
const DISPO_PRICE_SERVICE_UNAVAILABLE = '.dispo-price-service-unavailable:visible';
const QUANT_CART = 'quant-cart';
const MODAL_CONTENT = '.modal-content'
const CART_BOX = 'panier-box';
const SECTION_BODY_ITEM = '.section_cart--body-item';
const PRODUCT_DETAIL = '.productDetail';
const CART_ITEM = '.section_cart--body-item';
const IS_ELIGIBLE_TECCOM_PROVIDER = $('.isEligibleTecComProvider').length > 0;
const LOADER_CART_IN_PROGRESS = '.loaderCartInProgress';
const LOADER_CART_IN_PROGRESS_SCROLL = '.loaderCartInProgressScroll';
const LOADER_SCROLL = '.loaderScroll';
const ERROR_HAS_OCCURRED = '.error-has-occurred';
const BTN_TRANSMIT_SCROLL = '#boutonTransmettreScroll';
const LOADER_TRANSMIT_SCROLL = '#loaderTransmissionScroll';
const CART_PRICING_DELETE_ALL = '.cart-pricing-delete-all';
const ADD_OUT_OF_CATALOG_ITEM = '.add-out-of-catalog-item';
const BASKET_SEND_ORDER = '.basket-send-order';
const RESET_BTN = '.resetbutton';
const BASKET_DOWNLOAD_PUNCHOUT = '.basket-download-punchout';
const LOADER_DOWNLOAD = '#loaderDownload';

$(document).ready(function () {
    var onAffectationOrigine = testOnAffectationAndFicheProduitOpen();
    var modePrice = $('#toggle_currency_mode').prop('checked');
    if (modePrice && !onAffectationOrigine) {
        var options = $(document).find('.containerDispo .selectPriceLevel option');
        var input = $(document).find('div.cart-action input');
        if (typeof (options.val()) !== 'undefined') {
            input.val(options.val());
            input.attr(DATA_QUANTITY, options.val());
            input.attr('value', options.val());
        }
    }
    
    if(window.location.pathname === '/panier') {
        showPromoBands([], 'cart');
    }

    //Clic sur +
    $(document).on('click', '.cart-action .plus, .custom-cart-action .cart-plus', function (e) {
        var quantityInput = $(this).parent('div').find('input');

        onQuantityChange(e, quantityInput, 'plus');
    });

    //Clic sur -
    $(document).on('click', '.cart-action .minus, .custom-cart-action .cart-minus', function (e) {
        var quantityInput = $(this).parent('div').find('input');

        onQuantityChange(e, quantityInput, 'minus');
    });

    //Changement de valeur dans l'input
    $(document).on('change', ".cart-action .value, .custom-cart-action .value", function (e) {

        onQuantityChange(e, $(this), null);
    });

    $(document).on('click', 'table.table-commande .delete-action', function (e) {
        e.preventDefault();

        //Pubtech
        var numligne = $(this).parent('td').parent('tr').attr('numligne');
        var curLine = $(this);

        jQuery.ajax({
            type: "POST",
            data: { numligne: numligne },
            url: Routing.generate('autossimo_ajax_delete_article_panier'),
            async: false,
            success: function (data) {     // define call back function
                curLine.parents('.product').fadeOut();
                curLine.remove();
                majMontantPanier(data['nbarticles'], data['montant']);

                window.location.reload();
            }, // end function
            onFailure: function (data) {      // define call back function
            } // end function
        });
        return false;
    });

    $(document).on('click', '.delete-pricing-product', function (e) {
        e.preventDefault();
        GaEventHandler.removeFromCart($(this), 'cart_details');
        EventHandler.removeFromCart($(this), 'cart_details');
        //Pubtech
        var numligne = $(this).parent().parent().parent().attr('numligne');
        var curLine = $(this).parent().parent().parent();

        jQuery.ajax({
            type: "POST",
            data: { numligne: numligne },
            url: Routing.generate('autossimo_ajax_delete_article_panier'),
            async: false,
            success: function (data) {     // define call back function
                curLine.fadeOut();
                curLine.remove();
                majMontantPanier(data['nbarticles'], data['montant']);

                window.location.reload();
            }, // end function
            onFailure: function (data) {      // define call back function
            } // end function
        });
        return false;
    });

    $(document).on('click', 'div.commande .action-delete-all, .cart-pricing-delete-all', function (e) {
        e.preventDefault();
        GaEventHandler.removeFromCart($(this), 'cart_details');
        EventHandler.removeFromCart($(this), 'cart_details');
        jQuery.ajax({
            type: "POST",
            url: Routing.generate('autossimo_ajax_delete_panier'),
            async: false,
            success: function (data) {     // define call back function
                console.log('DONE');
                window.location.href = Routing.generate('autossimo_panier');
            }, // end function
            onFailure: function (data) {      // define call back function
                console.log('NOT DONE')
            } // end function
        });
        return false;
    });

    $('.panier-input .checkbox1 input[type=radio]+label').click(function (e) {
        $(SPAN_EMPTY_CART).hide();
    });

    $('.bloc-reference-disabled a.action-close-reference').click(function () {
        $(SPAN_EMPTY_CART).hide();
    });
    // 2 boutons mise au panier
    $('.sidexa2 .btn-cart')
        .before('<span class="emptyCart">Votre panier est vide</span><span class="ajaxError">Une erreur s\'est produite, veuillez nous contacter</span>')
        .prepend('<img class="spinner" src="' + SPINNER_PATH + '"/>')
        .click(function () {
            if ($('form .checkbox1 input[type=radio]:checked').not('form.disabled-ref .checkbox1 input[type=radio]').length
                || $('form .refOHC').not('form.disabled-ref .refOHC').length) {
                $(this).find('span').hide();
                $(this).find('img.spinner').show();// remplacement icône par spinner
                var isIdgPartener = $(this).data('isidg');
                ajouterPanierSidexa(isIdgPartener);
            } else {
                $(this).prevAll(SPAN_EMPTY_CART).show();// pr effacer msg panier vide
            }
            return false;
        });

    // On check que l'on est bien sur la page panier
    var cart = $(".cart");
    if (typeof cart !== 'undefined' && cart.length > 0) {
        // Auquel cas on initialise les datePicker avec l'init panier du datePicker
        $(".dispoA").each(function () {
            initPanierDatePicker(this);
        });
    }

    /**
     * Function called when click, to remove Part From Cart
     */
    $(document).on('click', '.remove-part-product-sheet', function () {
        if ($('.global-management-folder').length === 0) {
            EventHandler.removeFromCart($(this), FOLDER_COST);
            removePartFromCart($(this));
        }
    })

    $(document).on('submit', 'form[name="off_catalog_add_article"].cart', function (event) {
        // prevent reload page
        event.preventDefault();
        var ajaxurl = $(this).attr('data-ajaxurl');
        ajoutPanierHc(ajaxurl);
    })

    handlePanierImageError();
    disablePlusButtonOnLoad();
});

function disablePlusButtonOnLoad() {
    $('article.section_cart--body-item').each(function (index, element) {
        const targetElement = $(element);
        const quantityInput = targetElement.find('input.input-quantity');
        if (targetElement.find('.out-of-store').length > 0) {
            // called to check and disable the plus button
            checkQuantityPricing(quantityInput, getNewQuantity(quantityInput, 'load'));
        }
    });
}
/**
 * Handle Quantity change of product to add to cart
 *
 * @param event         Current event
 * @param quantityInput Quantity text element
 * @param action        Action on quanity (plus|minus|change)
 */
function onQuantityChange(event, quantityInput, action = 'change') {
    event.preventDefault();
    const target = event.target;
    if (disableQuantityBtn($(target))) {
        return false;
    }

    //test si on est sur la page résultats affectation origine (transition pour découplage erp) et si la fiche produit est ouverte.
    var onOriginAffectation = testOnAffectationAndFicheProduitOpen();
    var typeLigne = $(this).closest(C_CONTAINER_DISPO).attr('typeligne');

    let newval = getNewQuantity(quantityInput, action);
    if (newval === -1) {
        return false;
    }
    const quantity = checkQuantityPricing(target, newval);
    newval = quantity !== null ? quantity  : newval;
    getPriceLevel(target, newval);

    quantityInput.val(newval);
    quantityInput.attr(DATA_QUANTITY, newval);
    quantityInput.attr('value', newval);
    const data = getDataQuantityChanged(quantityInput);
    const origin = data.origin;
    if (onOriginAffectation) {
        var parentRefeq = $(target).closest(CONTAINER_DISPO_DATA_REFEQ);
        var errorErpDispoPrice = parentRefeq.find(DISPO_PRICE_SERVICE_UNAVAILABLE).length;
        if (errorErpDispoPrice) {
            toggleBlocDispoPriceToError(parentRefeq);
        } else {
            toggleBlocDispoPriceToResults(parentRefeq);
        }
        updateDispoPriceBloc(parentRefeq).always(function(response) {
            EventHandler.editPartQuantity(quantityInput, origin);
          });
    }
    else if (typeLigne !== 'H') {
        refreshDispo(quantityInput).always(function(response) {
            EventHandler.editPartQuantity(quantityInput, origin);
          });
        }

    if (($(target).parent().hasClass(QUANT_CART) && !$(target).closest(MODAL_CONTENT).length) &&
        !IS_ELIGIBLE_TECCOM_PROVIDER) {
        recalculateTotals();
    }

    // Gestion page Panier
    if (($(target).parent().hasClass(CART_BOX) || $(target).parent().parent().hasClass(CART_BOX))
        && !IS_ELIGIBLE_TECCOM_PROVIDER) {
        refreshForCart(target);
        updateEcotaxes();
    }
    return true;
}

function getNewQuantity(quantityInput, action) {
    let newval = parseInt(quantityInput.val());
    if (action === 'minus') { // Minus click
        if (newval <= 1) {
            return -1;
        }
        newval--;
    } else if (action === 'plus') { // Plus click
        newval++;
    } else if (quantityInput.val() !== true && Number.isInteger(quantityInput.val())) { // Input change
        alert('Veuillez indiquer une quantité, merci.');
        return -1;
    } else { // Check input empty
        newval = quantityInput.val() === '' ? 1 : quantityInput.val();
    }

    return newval;
}

function updateEcotaxes() {
    var totalEco = 0;
    var quantity = 1;
    $('.ecotaxe_container--link-value').each(function (index, el) {
        quantity = $(el).closest(SECTION_BODY_ITEM).find('.input-quantity').val();
        totalEco += parseInt(quantity) * parseFloat($(el).text().replace(',', '.'));
    });

    totalEco = Number(totalEco).toFixed(2).toString().replace('.', ',');

    $('.section_cart--body-item--info_price-ecotaxe--value').text(totalEco);
}

/**
 * Function : log punchout Movex
 * @param numCde
 * @param messageError
 */
function logPunchoutMovex(numCde, messageError) {
    jQuery.ajax({
        type: "POST",
        data: {
            numCde: numCde,
            messageError: messageError
        },
        url: Routing.generate('autossimo_command_log_punchout_movex'),
        async: false,
        success: function (response) {
            return false;
        },
        onFailure: function (response) {
            return false;
        }
    });
}

function getCheckCollectBasket(data, className)
{
    var $collectAtTheCounter = $(className).data('collectAtTheCounter');
    data['send_order']['productForcheckCollectAtTheCounter'] = $collectAtTheCounter;

    $collectAtTheCounter.forEach(function(lignCommand) {
        data['send_order']['checkCollectBasket_' + lignCommand] = $('#send_order_checkCollectBasket_' + lignCommand).prop('checked') ? 1 : 0;
    });

    return data;
}
export function sndcmd(numcde, refcde, messagerep, path, pathOk, pathCart, type, csrfToken, layer) {
    if (!refcde || refcde == '') {
        refcde = numcde;
    }

    const dataSndCmd = {
        numcde: numcde,
        refcde: refcde,
        messagerep: messagerep,
        path: path,
        pathOk: pathOk,
        pathCart: pathCart,
        type: type,
        layer: layer,
        csrfToken: csrfToken
    };

    const $sendOrderNumMarche = $('#send_order_numMarche');
    const $selectCodeService = $('#send_order_numCodeService');

    var data = {
        send_order: {
            numcde: numcde,
            refcde: refcde,
            messagerep: messagerep,
            numMarche: $sendOrderNumMarche.length > 0 && !layer ? $sendOrderNumMarche.val() : '',
            numCodeService: $selectCodeService.length > 0 && !layer ? $selectCodeService.val() : '',
            _token: csrfToken
        },
        layer: layer
    };

    const $collectBasketHighText = $('#collectBasketHighText');
    if (typeof $collectBasketHighText != 'undefined' && $collectBasketHighText.length > 0) {
        data = getCheckCollectBasket(data, '.section_cart--body');
    }

    const $collectBasketFormattingLayer = $('#collectBasketFormattingLayer');
    if (typeof $collectBasketFormattingLayer != 'undefined' && $collectBasketFormattingLayer.length > 0) {
        data = getCheckCollectBasket(data, '.pricing_cart_layout--body');
    }

    // Hide previous displayed errors
    var $sendOrderError = layer === true ? $('.send-order-layer-error') : $('.send-order-error');
    $sendOrderError
        .html('')
        .addClass('hidden');

    if (type === 'transmission') {
        const referencesDatas = [];
        const isFolderPage =  $('.folders-content').length > 0;
        let references;
        if (layer) {
            references = $('.pricing_cart_layout--body .lineItem');
        } else if (isFolderPage) {
            references = $('#popinFolderInstantOrder .modal-body .row');
        } else {
            references = $(SECTION_BODY_ITEM);
        }
        references.each(function(index, article){
            const formatRef = formatRefeqParamsForErpRequest($(article), isFolderPage, layer);
            referencesDatas.push(formatRef);
        })
        if (validStockCart(referencesDatas) && isValidPromotionsInCart(numcde, dataSndCmd)) {
            // Hide the loader of checking the cart
            if ($(LOADER_CART_IN_PROGRESS).length > 0) {
                $(LOADER_CART_IN_PROGRESS).addClass('hide');
            }
            if ($(LOADER_CART_IN_PROGRESS_SCROLL).length > 0) {
                $(LOADER_CART_IN_PROGRESS_SCROLL).addClass('hide');
            }

            // Hide the error icon
            if ($(ERROR_HAS_OCCURRED).length > 0) {
                $(ERROR_HAS_OCCURRED).addClass('hide');
            }

            $('#boutonTransmettre').hide();
            $(BTN_TRANSMIT_SCROLL).hide();
            if ($('#validerTransmission').length > 0) {
                $('#validerTransmission').hide();
            }
            $('#loaderTransmission').show();
            $(LOADER_TRANSMIT_SCROLL).show();

            // Show the loader scroll
            if ($(LOADER_SCROLL).length > 0) {
                $(LOADER_SCROLL).show();
            }

            // Hide items when transmission is in progress OK
            transmissionInProgress();

            var stopError = true;
            if (!layer && ($sendOrderNumMarche.length > 0 || $selectCodeService.length > 0)) {
                $.ajax({
                    type: 'POST',
                    url: Routing.generate('check_chorus_command_form_action'),
                    data: {
                        'selectMarche': $sendOrderNumMarche.length > 0 ? $sendOrderNumMarche.val() : '',
                        'selectCodeService': $selectCodeService.length > 0 ? $selectCodeService.val() : '',
                    },
                    dataType: 'json',
                    async: false,
                    success: function (response) {
                        $('.form-error').remove();

                        if (response.error !== undefined && '' !== response.error) {
                            for (var item in response.error) {
                                $('#' + response.error[item].id).parent().parent().find('.label').append('<span class="form-error"> ' + response.error[item].message + '</span>');
                                $('#loaderTransmission').hide();
                                $(LOADER_TRANSMIT_SCROLL).hide();

                                // Show items when transmission is finalized and hide the scroll loader
                                transmissionFinalized();
                            }

                            stopError = false;
                        }
                    }
                });
            }

            if (stopError) {
                //Traitement carte bnp
                if ($sendOrderNumMarche.length > 0) {
                    if ('' !== $sendOrderNumMarche.val() && $('#gestionMarche').length > 0) {
                        $('#refCdeMarche').val(refcde);
                        $('#msgCdeMarche').val(messagerep);
                        $('#marcheSelect').val($sendOrderNumMarche.val());
                        $('#gestionMarche').submit();
                        return false;
                    }
                }

                $.ajax({
                    type: 'POST',
                    url: path,
                    data: data,
                    dataType: 'json',
                    success: function (response) {
                        if (true === response.success) {
                            window.location.href = pathOk;
                        } else {
                            if (response.failureInstantOrderMessage !== undefined) {
                                $('.send-order-error').after(response.failureInstantOrderMessage);
                                $('#loaderTransmission').hide();
                                $(LOADER_TRANSMIT_SCROLL).hide();
                                $('#validerTransmission').show();
                            } else if ($('#errorTransmission').length > 0) {
                                $('#errorTransmission').show();
                                if ($('#popinTransmissionCommande').is(':visible') === true) {
                                    $('#popinTransmissionCommande').hide();
                                }
                            } else if (response.infoMessage !== undefined) {
                                window.location.href = Routing.generate('autossimo_panier', { 'warning': 'erreur-deja-transmise' });
                                return false;
                            } else {
                                window.location.href = pathCart;
                                return false;
                            }

                            // Show items when transmission is finalized and hide the scroll loader
                            transmissionFinalized();

                            // Show the error icon
                            if ($(ERROR_HAS_OCCURRED).length > 0) {
                                $(ERROR_HAS_OCCURRED).removeClass('hide');
                            }
                        }
                    },
                    error: function (response) {
                        if (422 === response.status) {
                            $sendOrderError
                                .html(response.responseJSON.message)
                                .removeClass('hidden');

                            $('#loaderTransmission').hide();
                            $(LOADER_TRANSMIT_SCROLL).hide();
                            if ($('#validerTransmission').length > 0) {
                                $('#validerTransmission').show();
                            }

                            // Show items when transmission is finalized and hide the scroll loader
                            transmissionFinalized();

                            // Show the error icon
                            if ($(ERROR_HAS_OCCURRED).length > 0) {
                                $(ERROR_HAS_OCCURRED).removeClass('hide');
                            }
                        }
                        else {
                            window.location.href = pathCart;
                        }

                        return false;
                    }
                });
            }
        }
    } else if (type === 'punchout') {
        delete data.send_order.numCodeService;
        delete data.send_order.numMarche;
        $('#boutonTransmettre').hide();
        $('#loaderTransmission').show();
        $('.btnSendCmdPunchout').hide();
        $(LOADER_DOWNLOAD).show();

        if ($(BTN_TRANSMIT_SCROLL).length > 0) {
            $(BTN_TRANSMIT_SCROLL).hide();
        }
        if ($(LOADER_TRANSMIT_SCROLL).length > 0) {
            $(LOADER_TRANSMIT_SCROLL).show();
        }
        if ($(LOADER_SCROLL).length > 0) {
            $(LOADER_SCROLL).show();
        }

        // Hide items when transmission is in progress OK
        transmissionInProgress();

        try {
            $.ajax({
                type: 'POST',
                url: path,
                data: data,
                async: false,
                dataType: 'json',
                success: function (response) {
                    if (true === response.success) {
                        window.location.href = pathOk;
                    } else {
                        if ($('#errorTransmission').length > 0) {
                            $('#errorTransmission').show();
                            window.location.href = pathCart;
                            return false;
                        } else {
                            logPunchoutMovex(numcde, response.errorMessage);
                            window.location.href = pathCart;
                            return false;
                        }
                    }
                },
                onFailure: function (errorFailure) {
                    logPunchoutMovex(numcde, "Error Js : " + errorFailure);
                    window.location.href = pathCart;
                    return false;
                },
                error: function (error) {
                    if (403 === error.status) {
                        $('#popinTransmissionCommande').modal('toggle');
                        alertForbidden();
                    }
                    if (422 === error.status) {
                        $sendOrderError
                            .html(error.responseJSON.message)
                            .removeClass('hidden');

                        if ($('.btnSendCmdPunchout').length > 0) {
                            $('.btnSendCmdPunchout').css({
                                'background-color': 'red',
                                'pointer-events': 'unset',
                                'cursor': 'pointer'
                            });
                        }

                        // Show items when transmission is finalized and hide the scroll loader
                        transmissionFinalized();

                        // Show the error icon
                        if ($(ERROR_HAS_OCCURRED).length > 0) {
                            $(ERROR_HAS_OCCURRED).removeClass('hide');
                        }
                    }
                }
            });
        } catch (errorCatch) {
            logPunchoutMovex(numcde, "Error Js : " + errorCatch);
            window.location.href = pathCart;
            return false;
        }
    }
    if ($('#errorTransmission').length > 0) {
        $('#loaderTransmission').hide();
        $('#boutonTransmettre').show();
        $(BTN_TRANSMIT_SCROLL).hide();
        $(LOADER_TRANSMIT_SCROLL).hide();
    }
    return false;
}

// Show items when transmission is finalized and hide the scroll loader
export function transmissionFinalized() {
    if ($(CART_PRICING_DELETE_ALL).length > 0) {
        $(CART_PRICING_DELETE_ALL).show();
    }
    if ($(ADD_OUT_OF_CATALOG_ITEM).length > 0) {
        $(ADD_OUT_OF_CATALOG_ITEM).show();
    }
    if ($(BASKET_SEND_ORDER).length > 0) {
        $(BASKET_SEND_ORDER).show();
    }
    if ($(RESET_BTN).length > 0) {
        $(RESET_BTN).show();
    }
    if ($(BASKET_DOWNLOAD_PUNCHOUT).length > 0) {
        $(BASKET_DOWNLOAD_PUNCHOUT).show();
    }
    if ($(LOADER_SCROLL).length > 0) {
        $(LOADER_SCROLL).hide();
    }

    if ($(LOADER_DOWNLOAD).length > 0) {
        $(LOADER_DOWNLOAD).hide();
        $('.btnSendCmdPunchout').show();
    }
}

// Hide items when transmission is in progress and show the loader of scroll
export function transmissionInProgress() {
    if ($(CART_PRICING_DELETE_ALL).length > 0) {
        $(CART_PRICING_DELETE_ALL).hide();
    }
    if ($(ADD_OUT_OF_CATALOG_ITEM).length > 0) {
        $(ADD_OUT_OF_CATALOG_ITEM).hide();
    }
    if ($(BASKET_SEND_ORDER).length > 0) {
        $(BASKET_SEND_ORDER).hide();
    }

    if ($(RESET_BTN).length > 0) {
        $(RESET_BTN).hide();
    }
    if ($(BASKET_DOWNLOAD_PUNCHOUT).length > 0) {
        $(BASKET_DOWNLOAD_PUNCHOUT).hide();
    }
}

$(document).on('click', '.layer-sndcmd', function () {
    var csrfToken = $('#send_order__token_layer').val();
    var messagerep = $('#send_order_messagerep_layer').val();
    var refcde = $('#send_order_refcde_layer').val();

    sndcmd(
        $(this).data('order'),
        refcde,
        messagerep,
        $(this).data('url'),
        $(this).data('url-ok'),
        $(this).data('url-cart'),
        $(this).data('type'),
        csrfToken,
        true
    )
});
export function refreshDispo(obj) {
    var isPricing = $('#is_pricing').length > 0;
    var selector = isPricing === true ? 'article' : 'tr';

    var inputQte = obj;

    var codequ = inputQte.attr('codequ');
    var gencode = inputQte.attr('gencode');
    const codarbo = inputQte.attr('codarbo');
    var refeq = inputQte.attr('refeq');
    var qte = inputQte.val();
    var typeligne = inputQte.attr('typeligne');
    var codfam = inputQte.attr('codfam');
    var inputDrop = inputQte.data('dropshipping');
    var tirePricing = inputQte.attr('tirePricing');
    var distribAndDropshippingResult = $(obj).closest('.containerDispo').hasClass('dropshipping');
    var blocHtml = "";
    const isInGlazingFamily = inputQte.data('is-in-glazing-family');

    var parentObj = $(obj).parents('.containerDispo').length > 0 ? '.containerDispo' : selector;
    var divDispo = $(obj).closest(parentObj).find('center.relative').length > 0 ? $(obj).closest(parentObj).find('center.relative') : $(obj).closest(parentObj).find('div.relative');
    var panierId = $(divDispo).find('span.id-btn-panier').data("id-btn-panier");

    // On test si dropshipping
    // inputDrop n'est pas setté Bool car avec un appel Ajax, true ou false sont transfomé en string
    if (distribAndDropshippingResult) {
        if (inputDrop) {
            divDispo = $(obj).parents(parentObj).find('tr.dispo-dropshipping .dispo-details');
            inputDrop = 'O';
        } else {
            divDispo = $(obj).parents(parentObj).find('tr.dispo-distrib .dispo-details');
            inputDrop = 'N';
        }
    }

    if (tirePricing == 1) {
        if (inputQte.data('dropshipping')) {
            divDispo = $(obj).parents('.dropshipping-availability').find('.relative');
            inputDrop = 'O';
        } else {
            divDispo = $(obj).parents('.details-availability').find('.relative');
            inputDrop = 'N';
        }
    }

    blocHtml = '<div class="update-bloc-pricing"><img src="/build/images/icon-update-gray.png">&nbsp;&nbsp;Mise à jour ...</div>';
    divDispo.html(blocHtml); //remplacer par loader

    return $.ajax({
        type: "POST",
        data: {
            codequ: codequ,
            gencode: gencode,
            refeq: refeq,
            typeligne: typeligne,
            qte: qte,
            codfam: codfam,
            dropshipping: inputDrop,
            isInGlazingFamily: isInGlazingFamily,
            codarbo: codarbo
        },
        url: Routing.generate('autossimo_panier_check_dispo'),
        //async: false,
        beforeSend: function () {
            $('.folder-timeout-alert').addClass('hidden');
        },
        success: function (result) {
            const data = result.availabilityContent;
            const dataPrice = result.dataPrice;
            const warnings = result.warnings !== undefined ? result.warnings : [];
            const netPrice = dataPrice.netPrice !== null ? dataPrice.netPrice : '--';
            const netPriceTTC = dataPrice.netPriceTTC !== null ? dataPrice.netPriceTTC : '--';
            const rawPrice = dataPrice.rawPrice !== null ? dataPrice.rawPrice : '--';
            const rawPriceTTC = dataPrice.rawPriceTTC !== null ? dataPrice.rawPriceTTC : '--';
            const discountValue = dataPrice.discountValue !== null ? dataPrice.discountValue : '--';
            const addedDiscount = dataPrice.addedDiscount !== null ? dataPrice.addedDiscount : '--';
            const thirdDiscount = dataPrice.thirdDiscount !== null ? dataPrice.thirdDiscount : '--';
            const isCart = $(obj).parents('.section_cart--body-item').length > 0;
            const appEnvironment = $('.app_environment').val();
            const devProviderID = 25;
            const delaiSimplifie = result.delai_simplifie;
            const isTecComAvailable = result.is_teccom_available;
            let providerID = 23;
            if (appEnvironment === 'dev') {
                providerID = devProviderID;
            }

            const value = {
                'PVHT': rawPrice,
                'PAHT': netPrice,
                'PVTTC': rawPriceTTC,
                'PATTC': netPriceTTC,
                'REMISEPOURCENT': discountValue,
                'ADDED_DISCOUNT': addedDiscount,
                'THIRD_DISCOUNT': thirdDiscount,
                'DELAI_SIMPLIFIE': delaiSimplifie,
            };

            if (IS_ELIGIBLE_TECCOM_PROVIDER && isTecComAvailable) {
                updateBlocPrice($(obj).parents('.containerDispo'), value);
                if ($(data).attr('data-availability') !== 'F' + providerID) {
                    $(obj).parents('.containerDispo').find('.external-availability-price').removeClass('hide');
                } else {
                    $(obj).parents('.containerDispo').find('.external-availability-price').addClass('hide');
                }
            }

            // define call back function
            // the check-dispo function returns the available block
            divDispo.replaceWith(data);
            divDispo = $(obj).parents(selector).length > 0 ? $(obj).parents('tr').find('.dispo-details') : $(obj).parents('.containerDispo').find('.dispo-details');
            var isProductSheetTemplate = $(obj).parents('#popinFicheProduit').length > 0;
            if (isProductSheetTemplate) {
                divDispo.find('.pfpt-bloc').hide();
                $(obj).parents('.containerDispo').find('.ps-cart-btn').attr('data-availability', $(data).attr('data-availability'));
                if (IS_ELIGIBLE_TECCOM_PROVIDER) {
                    updatePriceBloc(obj, netPrice, rawPrice, rawPriceTTC);
                }
            }
            if (isCart && IS_ELIGIBLE_TECCOM_PROVIDER) {
                $(obj).parents(CART_ITEM).find('.net-price.net-price-rate-ttc').html(netPrice.concat(' € HT'));
                $(obj).parents(CART_ITEM).find('.ht-price.rate-ht').html(rawPrice.concat(' € HT'));
                $(obj).parents(CART_ITEM).find('.ttc-price').html(rawPriceTTC.concat(' € TTC'));
                refreshForCart(inputQte);
                updateEcotaxes();
                recalculateTotals();
            }
            $(obj).parents('.containerDispo').find('.add-cart-piece').attr('data-availability', $(data).attr('data-availability'));
            $(obj).parents('.containerDispo').find('.btn-cart').attr('data-availability', $(data).attr('data-availability'));
            $(obj).parents('.containerDispo').find('.item-product').attr('data-availability', $(data).attr('data-availability'));
            if (!divDispo.hasClass('dispo-details')) {
                divDispo = $(obj).closest('.result-main').find('.dispo-details');
                divDispo.closest('.relative').addClass('disponibilite');
            }
            $(divDispo).parent().find('span.id-btn-panier').data('id-btn-panier', panierId);
            initDatePickerAjax();

            if ($(obj).parents('.containerDispo').hasClass('sidexaDispo')) {
                calculTotal();
            }
            if (isInGlazingFamily && result.availabilitySuccess === false) {
                $('.folder-timeout-alert').removeClass('hidden');
            }

            if ($(obj).parents('.DEelementFacturee').length > 0) {
                $('.step5.dispo center').removeClass('text-left');
                $.each($('td.step5.dispo div.dispo-details.dispoD .text-description-green'), function () {
                    let trimDispo = $.trim($(this).text());
                    $(this).text(trimDispo);
                });
            }

            // Display the warning messages depending on the status code returned from the WS ANG
            if (isInGlazingFamily || IS_ELIGIBLE_TECCOM_PROVIDER) {
                displayWarningMessages(warnings);
            }

            let availability = divDispo.children(':visible').text();
            if (divDispo.find('input.hasDatepicker').length > 0) {
                availability = divDispo.find('input.hasDatepicker').attr('value');
            } else if (divDispo.find('.available-on-demand').length > 0) {
                availability = divDispo.find('.available-on-demand').text();
            } else if (divDispo.find('.available-on-order').length > 0) {
                availability = divDispo.find('.available-on-order').text();
            }

            availability = formatAvailability(availability);
            divDispo.attr('data-available-value', availability);

            ProductAttributes.fromCheckDispo(obj, value);
            ItemAttributes.fromCheckDispo(obj, value);

        }, // end function
        onFailure: function (data) {
            // define call back function
        } // end function
    });
}

/**
 * Update the bloc of price.
 */
function updatePriceBloc(obj, netPrice, rawPrice, rawPriceTTC) {
    $(obj).parents(PRODUCT_DETAIL).find('.net-price.net-price-rate-ttc').html(netPrice.concat(' € HT'));
    $(obj).parents(PRODUCT_DETAIL).find('.ht-price.rate-ht').html(rawPrice.concat(' € HT'));
    $(obj).parents(PRODUCT_DETAIL).find('.ttc-price').html(rawPriceTTC.concat(' € TTC'));
    if (rawPrice === '--,--' && rawPriceTTC === '--,--') {
        $(obj).parents(PRODUCT_DETAIL).find('.discount-container').hide();
    } else {
        $(obj).parents(PRODUCT_DETAIL).find('.discount-container').show();
    }
}

function ajoutPanierHc(ajaxurl) {
    var codequ = '';
    var design = '';
    var refeq = '';
    var qte = '';
    var prix = '';
    var tx = '';
    var _token = '';

    codequ = $('#off_catalog_add_article_hc_equipementier').val();
    design = $('#off_catalog_add_article_hc_designation').val();
    refeq = $('#off_catalog_add_article_hc_reference').val();
    qte = $('#off_catalog_add_article_hc_quantite').val();
    prix = $('#off_catalog_add_article_hc_prixVenteHT').val();
    tx = $('#off_catalog_add_article_hc_tva').val();
    _token = $('#off_catalog_add_article__token').val();

    jQuery.ajax({
        type: "POST",
        data: { codequ: codequ, design: design, refeq: refeq, qte: qte, prix: prix, tx: tx, _token: _token },
        url: ajaxurl,
        async: false,
        beforeSend: function () {
            $('form[name="off_catalog_add_article"] .errors').text('');
        },
        success: function (data) {     // define call back function
            majMontantPanier(data['nbarticles'], data['montant']);
            window.location.reload();
        }, // end function
        error: function (errorData) {      // define call back function
            if (422 === errorData.status) {
                var errors = JSON.parse(errorData.responseText).errors;
                $.each(errors, function (propName, propVal) {
                    $('form[name="off_catalog_add_article"] #' + propName).text(propVal);
                });
            }
        } // end function
    });
}
function getLayerPanier(url, event) {
    var modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');

    if (!event.isTrigger) {
        EventHandler.viewCartLayout();
    }
    getCartContent(url, event);

    switchModeDisplayCartPricing(modePrice)
    if (!modePrice) {
        $('.badge_promo_horiz').addClass('hidden');
    } else {
        $('.badge_promo_horiz').removeClass('hidden');
    }
}
$('a.cart-opener').click(function (e) { getLayerPanier($(this).data('url'), e); });
// TODO a retirer avec la fin de pricing
$(document).on('click', 'tr.valid-panier', function (e) { getLayerPanier($(this).data('url'), e); });

export function majMontantPanier(nbarticles, montanttotal) {
    $('a.cart-opener span.nbPanier').html(nbarticles);
    $('a.cart-opener span.total').html(montanttotal);
    $('#layerPanier span.total').html(montanttotal);
    $('#layerPanier span.nbPanier').html(nbarticles);
}

export function majMontantPanierPricing(nbarticles, montanttotal) {
    if (nbarticles == 0) {
        $('span.badge.nbPanier').html('0');
        $('.associated-families').remove();
        $('div.holder.cart').addClass('empty cart_empty');
        $('div.holder.cart').html('<div id="close_layer" class="close_layout">X</div><p class="empty_cart">Votre panier est vide</p>');
    } else {
        $('a.cart-opener span.nbPanier').html(nbarticles);
        $('a.cart-opener span.total').html(montanttotal);
        $('.pricing_cart_layout--header-total_items').html('<span>' + nbarticles + '</span> article(s)');
        if (montanttotal == 0) {
            montanttotal = '0,00';
        }
        $('.pricing_cart_layout--header-total_price--container-value').find('.total_value').html(montanttotal.replace('.', ','));
    }

}

function cart_click() {
    $('.overlay').hide();
    $('#layerPanier').css('top', '27px');
    $('#layerPanier').css('overflow', 'inherit');
    var scroll = 0;
    $.each($('.cart-list-products tr').not('.grey_line22'), function () {
        scroll += $(this).height();
    });
    $('.cart-list-products').animate({ scrollTop: scroll }, '1500');
}

function ajouterPanierSEE(parameters) {
    ajouterPanier({
        obj: parameters.obj,
        dataProductId: parameters.dataProductId,
        ajaxurl: parameters.ajaxurl,
        codrefco: parameters.codrefco,
        codrefeq: parameters.codrefeq,
        qte: parameters.qte,
        typeligne: parameters.typeligne,
        availability: parameters.availability,
        refeq: parameters.refeq,
        manufacturerCode: parameters.manufacturerCode,
    });
}
$(document).on('click', 'span.see22', function () {
    ajouterPanierSEE(
        {
            obj: $(this),
            dataProductId: $(this).attr('data-productId'),
            ajaxurl: $(this).attr('data-url'),
            codrefco: $(this).attr('data-codrefco'),
            codrefeq: $(this).attr('data-codrefeq'),
            qte: $(this).attr('data-qte'),
            typeligne: $(this).attr('data-typeligne'),
            availability: $(this).attr('data-availability'),
            refeq: $(this).attr('data-refeq-cart'),
            manufacturerCode: $(this).attr('data-manufacturer-code'),
        }
    )
})

$(document).on('click', "table tr.product, .pricing_cart_layout--body-item", function () {
    var line = $(this).attr("numligne");
    $('.associated-families').hide();
    $('#line_' + line).show();
    $("table tr.product, .pricing_cart_layout--body-item").removeClass('grey_line22');
    $(this).addClass('grey_line22');
});

$(document).on('click', '#getAssociatedProduct', function () {
    $("#produit_associe").toggle();
});

/**
 * @param {string}  origin   Where function is called (page|layer)
 * @param {int}     numcde   Order number
 * @param {string}  ctxt_button what button called the function
 */
function sendCmdPunchout(origin, numcde, ctxt_button) {
    try {
        // Display loader
        $('#boutonTransmettre').hide();
        $('#loaderTransmission').show();
        $('.btnSendCmdPunchout').hide();
        $(LOADER_DOWNLOAD).show();

        // Update order status to DIALOG
        var data = {
            send_order: {
                numcde: numcde,
                ctxt_button: ctxt_button,
                _token: (origin == 'layer') ? $('#send_order__token_layer').val() : $('#send_order__token').val()
            }
        };

        // Hide the loader
        if ($(LOADER_TRANSMIT_SCROLL).length > 0) {
            $(LOADER_TRANSMIT_SCROLL).show();
            $(LOADER_TRANSMIT_SCROLL).removeClass('hide');
        }
        if ($(LOADER_SCROLL).length > 0) {
            $(LOADER_SCROLL).show();
        }

        // Hide items when transmission is in progress OK
        transmissionInProgress();

        jQuery.ajax({
            type: "POST",
            data: data,
            url: Routing.generate('autossimo_commande_update_state_dialog'),
            async: true,
            success: function (data) {
                if (true === data.success) {
                    // Submitting the form to the hookURL (URL of the DMS)
                    var iframeHookURL = (origin == 'layer') ? $('#postHookUrlLayer') : $('#postHookUrl');
                    // use setimeout to guarantee the recovery of xml content
                    setTimeout(
                        function () {
                            var downloadBasket = iframeHookURL[0].contentDocument.getElementById('downloadBasket');
                            if (downloadBasket !== null) {
                                jQuery.ajax({
                                    type: "POST",
                                    data: { numcde: numcde, status: 'OK', msg_reponse: 'OK' },
                                    url: Routing.generate('autossimo_commande_trace_log'),
                                    async: true,
                                    success: function (data) {
                                        return false;
                                    },
                                    onFailure: function (data) {
                                        return false;
                                    },
                                    error: function (error) {
                                        showErrorPopup(error.status);

                                        return false;
                                    }
                                });
                                downloadBasket.submit();
                                // Display confirmation page
                                setTimeout(
                                    function () {
                                        window.location.href = Routing.generate('autossimo_commande_transmission_punchout_ok');
                                    },
                                    1000
                                );
                            } else {
                                jQuery.ajax({
                                    type: "POST",
                                    data: {
                                        numcde: numcde,
                                        status: 'NOK',
                                        msg_reponse: 'Error JS : Iframe iframeHookURL not found'
                                    },
                                    url: Routing.generate('autossimo_commande_trace_log'),
                                    async: true,
                                    success: function (data) {
                                        return false;
                                    },
                                    onFailure: function (data) {
                                        return false;
                                    },
                                    error: function (error) {
                                        showErrorPopup(error.status);
                                    },
                                });
                                setTimeout(
                                    function () {
                                        window.location.href = Routing.generate('autossimo_order_transmission_punchout_nok');
                                    },
                                    1000
                                );
                                return false;
                            }
                        },
                        2000
                    );
                } else {
                    $('#boutonTransmettre').show();
                    $('#loaderTransmission').hide();
                    setTimeout(
                        function () {
                            window.location.href = Routing.generate('autossimo_order_transmission_punchout_nok');
                        },
                        1000
                    );
                    return false;
                }
            },
            onFailure: function (error) {
                $('#boutonTransmettre').show();
                $('#loaderTransmission').hide();
                jQuery.ajax({
                    type: "POST",
                    data: { numcde: numcde, status: 'NOK', msg_reponse: error },
                    url: Routing.generate('autossimo_commande_trace_log'),
                    async: false,
                    success: function (data) {
                        return false;
                    },
                    onFailure: function (data) {
                        return false;
                    },
                    error: function (err) {
                        showErrorPopup(err.status);
                    },
                });
                setTimeout(
                    function () {
                        window.location.href = Routing.generate('autossimo_order_transmission_punchout_nok');
                    },
                    1000
                );
            }
        });
    } catch (err) {
        $('#boutonTransmettre').show();
        $('#loaderTransmission').hide();
        jQuery.ajax({
            type: "POST",
            data: { numcde: numcde, status: 'NOK', msg_reponse: err.message },
            url: Routing.generate('autossimo_commande_trace_log'),
            async: false,
            success: function (data) {
                return false;
            },
            onFailure: function (data) {
                return false;
            },
            error: function (failure) {
                showErrorPopup(failure.status);
            },
        });
        setTimeout(
            function () {
                window.location.href = Routing.generate('autossimo_order_transmission_punchout_nok');
            },
            1000
        );
    }

    return false;
}

$(document).on('click', '.basket-download-punchout', function () {
    if ($(this).prev('.action-open-popin:not(.action-open-popin-message-produit)').length > 0) {
        GaEventHandler.beginCheckout($('.section_cart--body-item'),'cart-details-punchout');
        GaEventHandler.checkoutProgress($('.section_cart--body-item'),'cart-details');
    }
    sendCmdPunchout(
        $(this).data('origin'),
        $(this).data('order')
    );
})

$(document).on('click', '.btnSendCmdPunchout', function () {
    GaEventHandler.checkoutProgress($('.pricing_cart_layout--body .lineItem'),'cart-layer');
});
/**
 *
 * @param numCde
 * @param status
 * @param msgResponse
 */
function logPunchoutDMS(numCde, status, msgResponse) {
    jQuery.ajax({
        type: "POST",
        data: { numcde: numCde, status: status, msg_reponse: msgResponse },
        url: Routing.generate('autossimo_commande_trace_log'),
        async: true,
        success: function (data) {
            return false;
        },
        onFailure: function (data) {
            return false;
        }
    });
}

//Test fiche produit ouverte ou non pour activation du découplage erp ou non
function testOnAffectationAndFicheProduitOpen() {
    //test si on est sur la page résultats affectation origine (transition pour découplage erp).
    var onAffectationOrigine = $(document).find('#affectation-origine-ajax').length;

    //Test si on est dans la fiche produit de la page affectation origine
    if (onAffectationOrigine && $('body').hasClass('modal-open')) {
        onAffectationOrigine = false;
    }

    return onAffectationOrigine;
}

// Pour changer la date wanted en base
function changeDate(dateWanted) {
    var numLigne = $(this).closest("article").find(".num-ligne").data("num-ligne");
    if (dateWanted != null) {
        $(this).closest("td").find(".date-wanted").data("date-wanted", dateWanted);
    }
    ajaxChangeDate(numLigne, dateWanted);
}

// Pour reset la date en base (la passer a null)
function resetDate(numLigne) {
    ajaxChangeDate(numLigne, null);
}

// Appel Ajax pour changer la date en base pour un produit du panier
function ajaxChangeDate(numLigne, dateWanted) {
    const forbidden = 'Vous n\'êtes pas autorisé à effectuer cette opération';
    $.ajax({
        type: "POST",
        data: { num_ligne: numLigne, date_wanted: dateWanted },
        url: Routing.generate('autossimo_ajax_update_date_panier'),
        async: false,
        success: function (data) {
        }, // end function
        onFailure: function (data) {
            alert("attention, la date n'a pas pu être changé.");
        }, // end function
        error: function (error) {
            if (403 === error.status) {
                displayCartModal(forbidden);
            }
        }
    });
}

/*
 * A utiliser pour initialiser le date picker apres le passage de initPanierDatePicker
 * Surchage la date, si presente, et la fonction sur le onSelect
 *
 * $blockDispo -> element dom de la div dispo-details de la dispo
 */
function initPanierDatePicker(blockDispo) {
    var subselector = '.pricing_dispo';
    var dateWanted = $(blockDispo).closest(subselector).find(".date-wanted").data("date-wanted");
    var datePicker = $(blockDispo).find(".enable-jquery-datepicker");
    if (typeof datePicker !== 'undefined' && datePicker.length > 0) {
        // On reset le datePicker
        $(datePicker).datepicker("destroy");
        // Et on l'initialise proprement
        $(datePicker).datepicker(
            $.extend(
                {
                    'onSelect': changeDate
                },
                $.datepicker.regional['fr']
            )
        );
        // on check si une dateWanted est disponible, si dispo on remplit le datePicker
        if (typeof dateWanted !== 'undefined' && dateWanted.length > 0) {
            let date = dateWanted.split('/')
            $(datePicker).datepicker("option", "dateFormat", "dd/mm/yy");
            $(datePicker).datepicker("option", "defaultDate", new Date(date[2] + '-' + date[1] + '-' + date[0]));
            $(datePicker).datepicker('setDate', new Date(date[2] + '-' + date[1] + '-' + date[0]));
        }
    }
}

// Refresh datePicker si dispoA (indispo)
function refreshPanierDatePicker(blockQte) {
    var selector = 'article';
    var subselector = '.pricing_dispo';
    var blockDispo = $(blockQte).closest(selector).find(".dispo-details");
    if (typeof blockDispo !== 'undefined' && blockDispo.length > 0) {
        if ($(blockDispo).hasClass('dispoA')) {
            initPanierDatePicker(blockDispo);
        } else {
            var blockDateWanted = $(blockDispo).closest(subselector).find(".date-wanted");
            var dateWanted = $(blockDateWanted).data("date-wanted");
            if (typeof dateWanted !== 'undefined' && dateWanted.length > 0) {
                $(blockDateWanted).removeData("date-wanted");
                // Si data-date-wanted à ete remplit dans le DOM alors il faut removeAttr
                $(blockDateWanted).removeAttr("data-date-wanted");
                resetDate($(blockQte).closest(selector).find(".num-ligne").data("num-ligne"));
            }
        }
    }
}

// Gestion partie panier du changement de quantité
export function refreshForCart(blockQte) {
    // Gestion quantité
    var path = Routing.generate('autossimo_ajax_update_qte_panier');
    var qte = $(blockQte).parent().parent().find('input').attr('data-quantity');
    var numligne = $(blockQte).parent().parent().find('input').attr('numligne');
    updateBasket(path, qte, numligne, blockQte);

    // Gestion datePicker
    refreshPanierDatePicker(blockQte);
}

// AD-5239 : remove the lines related to the current line deleted
export function deleteAssociateLinesCart(numligne, dataDeleted) {
    $(dataDeleted).each(function (index, value) {
        if (value !== numligne) {
            $('.lineItem[numligne="' + value + '"]').fadeOut();
        }
    });
}

// Restrictions quantité //
// Désactive les caractères spéciaux
$(document).on("keypress", '.input-quantity, .cart-action .quantity input', function (event) {
    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which != 0) && (event.which != 8) && (event.which != 44 || $(this).val().indexOf(',') != -1) && (event.which < 48 || event.which > 57)) {
        event.preventDefault();
    }
});

// Instanciation à 1 quand on sort de l'input.
$(document).on("focusout", '.input-quantity, .cart-action .quantity input', function () {
    var priceValue = $(this).val();
    if ((priceValue == '') || isNaN(priceValue)) {
        $(this).val('1');
        $(this).attr('data-quantity', '1');
    }
});

// Désactive le scroll quand on est dans l'input.
$(document).on('focus', '.input-quantity, .cart-action .quantity input', function (e) {
    $(this).on('wheel', function (e) {
        e.preventDefault();
    });
});

$(document).on('blur', '.input-quantity, .cart-action .quantity input', function (e) {
    $(this).off('wheel');
});

// Désactive les touches haut et bas
$(document).on('keydown', '.input-quantity, .cart-action .quantity input', function (e) {
    if (e.which == 38 || e.which == 40)
        e.preventDefault();
});


function getCartContent(url, event) {
    var itemsIntheCart = 0;
    $.ajax({
        url: url,
        data: {
            lastCart: $('a.cart-opener span.nbPanier').attr('data-last')
        },
        beforeSend: function () {
            $('#layerPanier').html("")
        },
        async: false,
        type: "post",
        success: function (data) {
            var result = $(data);
            itemsIntheCart = $(result.find('.pricing_cart_layout--body').children().get().reverse()).length;
            if (itemsIntheCart == 0) {
                if (!event.isTrigger) {
                    window.location.href = Routing.generate('autossimo_panier');
                }
            } else {
                $('#layerPanier').html(data);
            }
        }
    }).done(function (data) {
        cart_click();
        var total_height = 0;
        var body_height = 0;
        var counter = 0;
        $($('.pricing_cart_layout--body').children().get().reverse()).each(function (index, el) {
            total_height += $(el).height();
            if (index < 4) {
                body_height += $(el).outerHeight(true);
                counter++;
            }
        });
        if (counter == 0) {
            if (!event.isTrigger) {
                window.location.href = Routing.generate('autossimo_panier');
            }
        } else {
            if (counter < 4) {
                body_height += (4 - counter) * 84;
            }
            $('.pricing_cart_layout--body').css('height', body_height + 'px');
            $('.pricing_cart_layout').attr('style', 'height: ' + (body_height + 113) + 'px !important');

            $('.pricing_cart_layout--body').children().last().addClass('grey_line22')
            $('.pricing_cart_layout--body').niceScroll({
                cursorcolor: '#a7a7a7',
                railalign: 'right'
            });
            if ($('.pricing_cart_layout--body').length && $('.pricing_cart_layout--body').getNiceScroll(0)) {
                $('.pricing_cart_layout--body').getNiceScroll(0).doScrollTop(total_height, 1000);
            }
        }        
    });
}

function disableQuantityBtn(element) {
    return element.hasClass(disabled) || element.siblings('.plus').hasClass(disabled);
}

/**
 * Remove part element from cart.
 * @param element
 */
function removePartFromCart(element) {
    var numLine = element.attr('data-num-line-order');
    $.ajax({
        url: Routing.generate('autossimo_ajax_delete_article_panier'),
        type: "POST",
        dataType: 'JSON',
        data: {
            numligne: numLine,
        },
        success: function (data) {
            $(SECTION_BODY_ITEM).each(function (index, article) {
                // Check if number of article exist in deleted results
                if (data.deleted.includes(parseInt($(article).attr('numligne')))) {
                    // Remove article removed from cart page
                    $(article).remove();

                    // Hide modal of product sheet
                    $('#popinFicheProduit').modal('hide');

                    // Update count of number article in cart
                    $('.section_cart--header-count_results > span').text(data.nbarticles);
                    $('.nbPanier').text(data.nbarticles);
                }
            });
        }
    });
}

/**
 * Displays a popup according to the given status error.
 *
 * @param errorStatus
 */
function showErrorPopup(errorStatus) {
    if (403 === errorStatus) {
        alertForbidden();
    }
}
