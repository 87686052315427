import StartSearchDataBuilder from "./startSearch/StartSearchDataBuilder";
import ApplyFiltersDataBuilder from "./applyFilters/ApplyFiltersDataBuilder";
import { AmplitudeProcessor } from "./AmplitudeProcessor";
import { getCategory, getDataUser } from "../../manage-ga";
import ViewVehicleResultsDataBuilder from "./viewVehicleResults/ViewVehicleResultsDataBuilder";
import ViewItemResultsDataBuilder from "./viewItemResults/ViewItemResultsDataBuilder";
import RemoveFromCartDataBuilder from "./removeFromCart/RemoveFromCartDataBuilder";
import { PATH } from '../constants';
import AddToCartDataBuilder from "./addToCart/AddToCartDataBuilder";
import StartAddToQuotationDataBuilder from "./startAddToQuotation/StartAddToQuotationDataBuilder";
import AddPartToQuotationDataBuilder from "./addPartToQuotation/AddPartToQuotationDataBuilder";
import ViewVehicleTechnicalDataBuilder from './viewVehicleTechnicalData/ViewVehicleTechnicalDataBuilder';
import ViewLaborTimeBuilder from './viewLaborTime/ViewLaborTimeBuilder';
import ViewCartLayoutDataBuilder from "./viewCartLayout/ViewCartLayoutDataBuilder";
import ViewCartDetailsDataBuilder from "./viewCartDetails/ViewCartDetailsDataBuilder";
import CompleteOrderBuilder from './completeOrder/CompleteOrderBuilder';
import StartOrderDataBuilder from "./startOrder/StartOrderDataBuilder";
import EditPartQuantityDataBuilder from "./editPartQuantity/EditPartQuantityDataBuilder";
import { FeatureFlag } from "../FeatureFlag";
import ResultsNotFoundDataBuilder from "./resultsNotFound/ResultsNotFoundDataBuilder";
import IdentifyUserDataBuilder from "./identifyUser/IdentifyUserDataBuilder";
import { getGADataBlock } from "../utilities";
import ViewItemDetailsDataBuilder from "./viewItemDetails/ViewItemDetailsDataBuilder";
import ViewItemPictureDataBuilder from "./viewItemPicture/ViewItemPictureDataBuilder";
import DisplayIamForOemDataBuilder from "./displayIamForOem/DisplayIamForOemDataBuilder";
import ViewMatchingTiresDataBuilder from "./viewMatchingTires/ViewMatchingTiresDataBuilder";
import ViewCampaignDataBuilder from "./viewCampaign/ViewCampaignDataBuilder";
import ViewCampaignDetailDataBuilder from "./viewCampaignDetail/ViewCampaignDetailDataBuilder";
import PdfQuotationBuilder from "./pdfQuotation/PdfQuotationBuilder";
import CompareQuotationBuilder from "./compareQuotation/CompareQuotationBuilder";
import PdfComparativeQuotationBuilder from "./pdfComparativeQuotation/PdfComparativeQuotationBuilder";
import ViewAvailabilitiesDataBuilder from "./viewAvailabilities/ViewAvailabilitiesDataBuilder";
export default class EventHandler {
    static identifyUser() {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = IdentifyUserDataBuilder.create();
        const dto = builder.build(getDataUser(getGADataBlock()), null);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.identify(dto);
    }
    static startSearch(element, catalog) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = StartSearchDataBuilder.create();
        const dto = builder.build(element, catalog);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('start_search', dto.export());
    }
    static handleConsumableAndPaintEvent() {
        let container = $('#search-paints-block');
        if (0 === container.length) {
            container = $('#search-consumables-block');
        }
        if ($('.peinturesnav').hasClass('active')) {
            EventHandler.startSearch(container, 'paints');
        }
        else if ($('.consommablesnav').hasClass('active')) {
            EventHandler.startSearch(container, 'consumable');
        }
        else {
            console.warn('Something is wrong with consumable and paint tabs');
        }
    }
    static resultsNotFound(element, catalog) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ResultsNotFoundDataBuilder.create();
        const dto = builder.build(element, catalog);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('results_not_found', dto.export());
    }
    static viewVehicleResults(blockData, category) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewVehicleResultsDataBuilder.create();
        const dto = builder.build(blockData, category);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_vehicle_results', dto.export());
    }
    static viewItemResults(blockData, category) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewItemResultsDataBuilder.create();
        const dto = builder.build(blockData, category);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_item_results', dto.export());
    }
    static viewMatchingResults(blockData, context) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewMatchingTiresDataBuilder.create();
        const dto = builder.build(blockData, context);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_matching_tires', dto.export());
    }
    static applyFilters(element, context) {
        var _a, _b;
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ApplyFiltersDataBuilder.create();
        const catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : getCategory(PATH);
        const dto = builder.build(element, {
            filterType: (_b = context.filterType) !== null && _b !== void 0 ? _b : null,
            catalog: catalog,
        });
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('apply_filters', dto.export());
    }
    // cart
    static removeFromCart(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = RemoveFromCartDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('remove_from_cart', dto.export());
    }
    // cart
    static addToCart(element, origin, status = false) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = AddToCartDataBuilder.create();
        const dto = builder.build(element, { origin, status });
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('add_to_cart', dto.export());
    }
    // cart
    static startAddToQuotation(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = StartAddToQuotationDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('start_add_to_quotation', dto.export());
    }
    // cart
    static addPartToQuotation(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = AddPartToQuotationDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('add_part_to_quotation', dto.export());
    }
    static vehicleTechnicalData(origin, vehicleDataBlock) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewVehicleTechnicalDataBuilder.create();
        const dto = builder.build({ vehicleDataBlock: vehicleDataBlock }, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_vehicle_technical_data', dto.export());
    }
    static viewLaborTime() {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewLaborTimeBuilder.create();
        const dto = builder.build(null, null);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_labor_time', dto.export());
    }
    // cart
    static viewCartLayout(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewCartLayoutDataBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_cart_layout', dto.export());
    }
    // cart
    static viewCartDetails(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        if (PATH.indexOf('/panier') >= 0) {
            const builder = ViewCartDetailsDataBuilder.create();
            const dto = builder.build(element, origin);
            if (null === dto) {
                return;
            }
            AmplitudeProcessor.track('view_cart_details', dto.export());
        }
    }
    // cart
    static completeOrder(element, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = CompleteOrderBuilder.create();
        const dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('complete_order', dto.export());
    }
    // cart
    static startOrder(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = StartOrderDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('start_order', dto.export());
    }
    // cart
    static editPartQuantity(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = EditPartQuantityDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('edit_part_quantity', dto.export());
    }
    // Fiche produit AD-9888
    static viewItemDetails(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewItemDetailsDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_item_details', dto.export());
    }
    static viewItemPicture(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewItemPictureDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_item_picture', dto.export());
    }
    // AD-10073 Display_iam_for_oe
    static displayIamForOem(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = DisplayIamForOemDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('display_iam_for_oem', dto.export());
    }
    static viewCampaignDetail(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewCampaignDetailDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_campaign_details2', dto.export());
    }
    static viewCampaign(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewCampaignDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_campaign', dto.export());
    }
    static pdfQuotation(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = PdfQuotationBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('pdf_quotation', dto.export());
    }
    static compareQuotation(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = CompareQuotationBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('compare_quotation', dto.export());
    }
    static pdfComparativeQuotation(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = PdfComparativeQuotationBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('compare_quotation_pdf', dto.export());
    }
    static viewAvailabilities(elements, origin) {
        if (false === FeatureFlag.isEnabled()) {
            return;
        }
        const builder = ViewAvailabilitiesDataBuilder.create();
        const dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor.track('view_availabilities', dto.export());
    }
}
