import {initImgWithLazyLoading} from './init-images-carousel';
import {initOwlCarousel} from "./carousel";
import {isProductDisplayed, updateSelectViscosity} from './pubtech-oil';
import {adjustFooter} from './pubtech-main-v2';
import {getOEMByGetPrice} from './pubtech-dispo-price';
import {calculateNumberReferences} from "./packages/autossimo-packages-ad";

const SELECT_LABEL = " sélectionnés";
const RESULTAT_REF_ORIGINE = '.result-ref-origine';
const ROTATED_180 = 'rotated_180';
const HIDE_DETAIL_IMG = '.hide_detail img';
const SHELTER = '#shelter22';
const CHECKBOX = '#checkbox1';
const SELECT_EQUIP = '#selectEquip';
const ALL_FILTERS_LANDMARK = '12-10-6';

export function showHideProductReference(node){
    if(node !== undefined) {
        var $element = $(node);
        if($element.hasClass("hide")) {
            $element.removeClass("hide")
        }
    }
    const block = $(node).parents(RESULTAT_REF_ORIGINE);
    const resultElement = block.find('.style-subResult');
    const container = resultElement.parent();
    const id = resultElement.attr('id');
    let selector = $(`.lienRefRoRe[data-div='${id}']`);
    let message = container.find('.message');
    let img = container.find('.waiting img');
    const bottom = container.find(HIDE_DETAIL_IMG);
    if (selector.length > 0) {
        if (resultElement.is(':visible')) {
            message.html('MASQUER');
            img.addClass(ROTATED_180);
            bottom.addClass(ROTATED_180);
            resultElement.find('div.hide_detail span.message').html('MASQUER');
        } else {
            message.html('AFFICHER');
            resultElement.find('div.hide_detail span.message').html('AFFICHER');
            img.removeClass(ROTATED_180);
            bottom.removeClass(ROTATED_180);
        }
    } else {
        selector = $(`.filterClass[data-refeq='${id}']`);
        message = selector.find('.message');
        img = selector.find('.waiting img');
        if (resultElement.is(':visible')) {
            message.html('MASQUER');
            img.addClass(ROTATED_180);
            bottom.addClass(ROTATED_180);
        } else {
            message.html('VOIR LES RESULTATS');
            img.removeClass(ROTATED_180);
            bottom.removeClass(ROTATED_180);
        }
    }
    adjust();
    return false;
}

$(document).ready(function () {
    updateInfosRef();
    updateSelectViscosity();
    $(document).on('click', '.init-filter',function(event){
        event.preventDefault();
        resetFilter();
    });

    // Filtre équipementiers mono ref origine
    $(document).on('change', 'div.filtreEquipementiers select#equipementiers', function () {
        const refEquipSelected = $(this).val();
        const refEqList = $(this).closest('table').find('tbody tr.origin-datas');

        refEqList.each(function(){
            const element = $(this);

            const hasEquipSelected = isProductDisplayed(element)

            if (!hasEquipSelected) {
                element.addClass('hide');
            } else {
                element.removeClass('hide');
            }
        });

        if (refEquipSelected !== null && refEquipSelected.length > 1) {
            var nbEquipSelected = 0;
            for (var i = 0; i < refEquipSelected.length; i++) {
                if ($(this).find('option[value="'.concat(refEquipSelected[i]).concat('"]')).hasClass('hide') === false) {
                    nbEquipSelected++;
                }
            }

            $(this).parent().find('button[data-id="equipementiers"]').attr('title',nbEquipSelected+ SELECT_LABEL);
            $(this).parent().find('button[data-id="equipementiers"] > span:first-child').html(nbEquipSelected+ SELECT_LABEL);
        }

        updateInfosRef();
        calculateNumberReferences($('.filter-option.pull-left'));
    });

    checkDmecDate();
    disabledIfNoRefOrig();
    disabledIfRefOrig();
    //Switch btn 1ere MEC
    $(document).on('click', CHECKBOX, function () {
        toggleDmecSwitch(getDMecDate());
        disabledIfNoRefOrig();
        disabledIfRefOrig();
        adjust();
    });

    loadPriceDependingOnViscosity();

    //Filtrer par ref Equipementier
    $(document).on('change', SELECT_EQUIP, function(){
        $('div.results div.noresults').addClass('hide');
        const refEquipSelected = $(this).val();

        $('div.sub_result tbody tr').each(function(){
            const element = $(this);
            const hasFilteredEquip = isProductDisplayed(element)

            if(!hasFilteredEquip){
                element.addClass('hide');
            } else {
                element.removeClass('hide');
                element.removeClass('noequip');
                element.closest('.results-container').removeClass('noequip');
                element.closest('.results-container').find(RESULTAT_REF_ORIGINE).removeClass('noequip');
            }
        });
        $('div.results-container.noequip').each(function(){
            const element = $(this);
            element.find('div.sub_result tbody tr').each(function(){
                $(this).removeClass('hide');
            });
        });

        updateListRefEquip();

        if (refEquipSelected != null && refEquipSelected.length > 1) {
            let nbEquipSelected = 0;
            for (var i = 0; i < refEquipSelected.length; i++) {
                if ($(this).find('option[value="'.concat(refEquipSelected[i]).concat('"]')).hasClass('hide') === false) {
                    nbEquipSelected++;
                }
            }

            $('button[data-id="selectEquip"]').attr('title',nbEquipSelected+ SELECT_LABEL);
            $('button[data-id="selectEquip"] > span:first-child').html(nbEquipSelected+ SELECT_LABEL);
        }

        // j'actualise les chiffres des résultats
        countNbRefEquip();

        updateInfosRef();

        // On réordonne les refs origine en plaçant celles qui n'ont pas le(s) équipementier(s) selectionné(s) en dernier
        // Et on affiche juste avant le message en question
        const elem = $('.results-container').sort(sortByClass);
        $('.results').append(elem);

        if ($('div.results-container.noequip').not('.hide').length > 0) {
            $('div.results div.noresults').removeClass('hide').insertAfter($('div.results-container').not('.noequip').last());
        }

        //Plié/Déplié des refs origine
        $('div.results-container').each(function(){
            if (!$(this).hasClass('hide') && !$(this).hasClass('noequip') && $(this).find('.choix-equipementiers > .noEquipementiers').length === 0) {
                $(this).find('.sub_result').toggle(true);
            } else {
                $(this).find('.sub_result').toggle(false);
            }
            showHideProductReference();
            adjustFooter();
        });

        //Désactivation des critères qui ne se trouvent pas dans les refs origine restantes
        //Pour empêcher d'avoir 0 résultat
        disabledIfNoRefOrig();
        disabledIfRefOrig();
        displayCriteres();
        hideButtonIfNotHasRef();
        updateTotalRef();
    });

    update_toggle_button_labels();
    openOilFilter();

    $('.handle-filters').on('click', function () {
        applyRemoveFilter($(this));
        activateFiltre();
    });
});

export function loadPriceDependingOnViscosity() {
    const loadGetPrice = $('.loadGetPrice');
    const loadGetPriceNotHiden = $('.results-container:not(.hide) .loadGetPrice');
    const selectVisco = $('select.selectVisco');
    const filterViscocity = (selectVisco.length > 0 && null !== selectVisco.val() && selectVisco.val().length !== 0 && loadGetPrice.length > 1);
    const isAllFilters = $('.blocFilters').data('landmark') === ALL_FILTERS_LANDMARK;

    if ((loadGetPrice.length > 1 && loadGetPriceNotHiden.length <= 1) || (filterViscocity && !isAllFilters)
    ) {
        $('.results-container:not(.hide)').addClass('stopGetPrice');
        const references = $('.results-container:not(.hide) .origin-datas .containerDispo');
        references.addClass('priceLoaded');
        getOEMByGetPrice(references, undefined, undefined, true);
    }
}

function hideButtonIfNotHasRef() {
    $(RESULTAT_REF_ORIGINE).each(function(index, referenceOe){
        $(referenceOe).removeClass('has-no-iam')
        $(referenceOe).find('.loadGetPrice').removeClass('invisible');
        if ($(referenceOe).find('.origin-datas:not(.hide)').length === 0) {
            $(referenceOe).addClass('has-no-iam')
            $(referenceOe).find('.sub_result').hide();
        }
        $(referenceOe).find('.choix-equipementiers strong:eq(0)').text(
            $(referenceOe).find('.origin-datas:not(.hide)').length
        );
    })
}

function updateTotalRef(){
    $('.nb-ref-equi-total').html(
        $('.origin-datas:not(.hide)').length
    )
}

export function openOilFilter()
{
    const oilBloc = $(SHELTER).find('.blocOilEngine');
    const vehicleModelSelection = $(SHELTER).find('.vehicle-model-selection');

    if ((oilBloc.length > 0 && oilBloc.html().trim().length > 0)
        || (vehicleModelSelection.length > 0 && vehicleModelSelection.html().trim().length > 0)) {
        $("#showFilters").trigger("click");
    }
}

export function countNbRefEquip () {
    var nbVisibleRefOrigine = countVisibleRefOrigine();
    if (nbVisibleRefOrigine === 1) {
        if($("#listreforiginselect").val() != '') {
            var refs = $("#listreforiginselect").val().split(":");
            $.each(refs, function (indx, vle){
                var id = "refEquip-" + vle;
                var selector = $(`.lienRefRoRe[data-div='${id}']`), bottom =  $("#" + id).find(".hide_detail");
                if ($('#' + id).is(':visible')) {
                    selector.find('.message')
                        .html('MASQUER');
                    selector.find('img')
                        .not('.img-vignette, .owl-lazy,.zoom-imgs')

                        .addClass(ROTATED_180);
                    $('.img-vinoe-verfied').removeClass(ROTATED_180);
                    $('.img-vinoe-verfied-global').removeClass(ROTATED_180);
                    bottom.find('.message').html('MASQUER');
                    bottom.find('img')
                        .not('.img-vignette, .owl-lazy,.zoom-imgs')
                        .addClass(ROTATED_180);
                }
                else {
                    selector.find('.message')
                        .html('AFFICHER');
                    selector.find('img')
                        .removeClass(ROTATED_180);
                    bottom.find('.message').html('AFFICHER');
                    bottom.find('img')
                        .removeClass(ROTATED_180);
                }
            })
        }

        $('div.results-container').not('.hide').each(function(){
            var subResult = $(this).find('div.sub_result');
            var nbRefEquip = subResult.find('.containerDispo').not('.hide').length;
            subResult.prev('div.result-ref-origine').find('.choix-equipementiers p strong').html(nbRefEquip);
        });
    } else {
        $('div.results-container').not('.noequip').each(function(){
            var refOrig = $(this).find('div.result-ref-origine');
            var subResult = $(this).find('div.sub_result');
            var nbRefEquip = subResult.find('.containerDispo').not('.hide').length;
            refOrig.find('.choix-equipementiers p strong').html(nbRefEquip);

            if (nbRefEquip === 0 && refOrig.find('.choix-equipementiers > .noEquipementiers').length === 0) {
                $(this).addClass('noequip');
                refOrig.addClass('noequip');
                moveRefOrigToBottom($(this));
            } else {
                $(this).removeClass('noequip');
                refOrig.removeClass('noequip');
            }
        });

    }
}

// Ref orig at the bottom with mention line if no ref equip
// Show all ref equip and refresh results nb
function moveRefOrigToBottom (element) {
    var refOrigineSelected = element.find('div.result-ref-origine');
    element.find('table tbody tr').each(function(){
        $(this).removeClass('hide');
    });
    var nbRefEquipInRefOrigNoResult = element.find('table.equiv-product tbody tr.containerDispo').removeClass('hide').length;
    refOrigineSelected.find('.choix-equipementiers p strong').html(nbRefEquipInRefOrigNoResult);
}

function getDMecDate () {
    var dmecMonth = $('.input-mec-mois').val();
    var dmecYear = $('.input-mec-annee').val();

    return dmecYear + dmecMonth;
}

function toggleDmecSwitch (dmecSelected) {

    //si 'on'
    if(!($(CHECKBOX).is(':checked'))) {
        $(RESULTAT_REF_ORIGINE).each(function(){
            if ($(this).attr('data-daffectation').indexOf('/') !== -1) {
                var dmecRef = $(this).attr('data-daffectation').split('/');
                var nbMonthDebut = monthCount(dmecRef[0]);
                var nbMonthFin = null;
                if (dmecRef[1]){
                    nbMonthFin = monthCount(dmecRef[1]);
                }
            } else {
                var dmecRef = $(this).attr('data-daffectation');
                if (dmecRef != $('#dateNow').val()) {
                    if ($(this).find('.dates_affectation_piece > strong').hasClass('start-affectation')) {
                        var nbMonthDebut = monthCount(dmecRef);
                        var nbMonthFin = null;
                    } else if ($(this).find('.dates_affectation_piece > strong').hasClass('end-affectation')) {
                        var nbMonthFin = monthCount(dmecRef);
                        var nbMonthDebut = null;
                    }
                } else {
                    var nbMonthDebut = 0;
                }

            }

            var nbMonthSaisie = monthCount(dmecSelected);
            if((nbMonthDebut - 3) > parseInt(nbMonthSaisie) ||  (nbMonthSaisie > nbMonthFin + 3 && nbMonthFin != null)){
                $(this).parent().addClass('hide');
                $(this).addClass('hide');
                $(this).parent().find('.sub_result').addClass('hide');
            }
        });

        var nbVisibleRefOrigine = countVisibleRefOrigine();
        if (nbVisibleRefOrigine === 1) {
            if ($('.choix-equipementiers').find('.noEquipementiers').length === 0) {
                $('div.results-container').not('.hide').find('.sub_result').toggle(true);
            }
        }


        if ($('div.results-container.noequip').not('.hide').length > 0 && !$('div.results-container.noequip').find('.noEquipementiers')) {

            var elem = $('.results-container').sort(sortByClass);
            $('.results').append(elem);

            $('div.results div.noresults').removeClass('hide').insertAfter($('div.results-container').not('.noequip').last());
        } else {
            if ($('div.results div.noresults').hasClass('hide') === false) {
                $('div.results div.noresults').addClass('hide');
            }
        }

    } else {
        $(RESULTAT_REF_ORIGINE).each(function(){
            var refOrigCriterias = JSON.parse($(this).attr('data-criteres'));
//            On teste la présence de tous les critères dans la ref origine
            var criteriasList = [];

            _.forEach(selectedFilters, function(value, key){
                if(refOrigCriterias.hasOwnProperty(key) && refOrigCriterias[key].indexOf(value) !== -1){
                    criteriasList.push(value);
                }
            });
            var selectedFiltersLength = _.size(selectedFilters);
            var hasSelectedFilter = (criteriasList.length === selectedFiltersLength);

            if (hasSelectedFilter) {
                $(this).parent().removeClass('hide');
                $(this).removeClass('hide');
                $(this).parent().find('.sub_result').removeClass('hide');

                var nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;

                if (nbRefOrigineVisible > 1) {
                    $(RESULTAT_REF_ORIGINE).not('.hide').each(function(){
                        if ($(this).find('.choix-equipementiers > .noEquipementiers').length === 0) {
                            $(SHELTER).removeClass('hide');
                            return false;
                        }
                    });
                }
            }


            //Plié/Déplié des refs origine
            var nbVisibleRefOrigine = countVisibleRefOrigine();
            if (nbVisibleRefOrigine > 1 && $(SELECT_EQUIP).val() === null) {
                const resultsContainer = $('div.results-container');
                resultsContainer.not('.hide').find('.sub_result').toggle(false);
                resultsContainer.find('.message').html('AFFICHER')
                resultsContainer.find('img.rotated_180').removeClass('rotated_180');
            } else if (nbVisibleRefOrigine > 1 && $(SELECT_EQUIP).val() !== null) {
                $('div.results-container').each(function(){
                    if (!$(this).hasClass('hide') && !$(this).hasClass('noequip')) {
                        $(this).find('.sub_result').toggle(true);
                    } else {
                        $(this).find('.sub_result').toggle(false);
                    }
                    showHideProductReference();
                    adjustFooter();
                });
            }
        });

        var elem = $('.results-container').sort(sortByClass);
        $('.results').append(elem);

        if ($('div.results-container.noequip').not('.hide').length > 0) {
            $('div.results div.noresults').removeClass('hide').insertAfter($('div.results-container').not('.noequip').last());
        }

        if(applySidexa){
            $(CHECKBOX).attr('disabled', 'disabled');
            $('.mec').css('opacity', '.5');
        }
    }
    updateInfosRef();
}


//Prise en compte d\'une nouvelle dmec dans input
function filterDateMec() {

    var datesaisie = getDMecDate ();

    if (datesaisie.length !== 6) {
        alert('Veuillez renseigner une date de 1ere mise en circulation svp.');
        return false;
    }

    if($('.btn.activermec').hasClass('active')){
        $('.btn.activermec').removeClass('active');
        $('.btn.activermec').blur();
        $('.btn.activermec span.activate').toggleClass('hide');
        $(RESULTAT_REF_ORIGINE).each(function(){
            if ($(this).attr('data-daffectation')){
                if ($(this).attr('data-daffectation').indexOf('/') !== -1) {
                    var dmecRef = $(this).attr('data-daffectation').split('/');
                } else if ($(this).attr('data-daffectation') !== '' && $(this).attr('data-daffectation') != 'undefined') {
                    var dmecRef = $(this).attr('data-daffectation');
                }
            } else {
                var dmecRef = '';
            }

            if (dmecRef instanceof Array || dmecRef != '') {
                var refOrigCriterias = JSON.parse($(this).attr('data-criteres'));
                //            On teste la présence de tous les critères dans la ref origine
                var criteriasList = [];

                _.forEach(selectedFilters, function(value, key){
                    if(refOrigCriterias.hasOwnProperty(key) && refOrigCriterias[key].indexOf(value) !== -1 && refOrigCriterias[key].length === value[0].length){
                        criteriasList.push(value);
                    }
                });
                var selectedFiltersLength = _.size(selectedFilters);
                var hasSelectedFilter = (criteriasList.length === selectedFiltersLength);

                if (hasSelectedFilter) {
                    $(this).parent().removeClass('hide');
                    $(this).removeClass('hide');
                    $(this).parent().find('.sub_result').removeClass('hide');
                }
            }
        });
        $('.showError').addClass('hide');
        $('.showError').next('.row.sort-separator').addClass('hide');
        // Toggle references equipementiers list if it was displayed (ex: unfold when only 1 references origine list with MEC activated)
        var nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
        if (nbRefOrigineVisible > 1) {
            $('div.results-container').not('.hide').find('.sub_result').toggle(false);
        }
    } else {
        $('.btn.activermec').addClass('active');
        $('.btn.activermec span.activate').toggleClass('hide');
        $(RESULTAT_REF_ORIGINE).each(function(){
            var dmecRef = $(this).attr('data-daffectation').split('/');
            var nbMonthFin = null;
            if (dmecRef[1]) {
                var nbMonthDebut = monthCount(dmecRef[0]);
                nbMonthFin = monthCount(dmecRef[1]);
            } else {
                if (dmecRef[0] != $('#dateNow').val()) {
                    if ($(this).find('.dates_affectation_piece > strong').hasClass('start-affectation')) {
                        var nbMonthDebut = monthCount(dmecRef[0]);
                        var nbMonthFin = null;
                    } else if ($(this).find('.dates_affectation_piece > strong').hasClass('end-affectation')) {
                        var nbMonthFin = monthCount(dmecRef[0]);
                        var nbMonthDebut = null;
                    }
                } else {
                    var nbMonthDebut = 0;
                }
            }

            var nbMonthSaisie = monthCount(datesaisie);
            if((nbMonthDebut - 3) > nbMonthSaisie ||  (nbMonthSaisie > (nbMonthFin + 3) && nbMonthFin != null)){
                $(this).parent().addClass('hide');
                $(this).addClass('hide');
                $(this).parent().find('.sub_result').addClass('hide');
            }
        });
        var nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
        if (nbRefOrigineVisible === 1) {
            if ($('.choix-equipementiers').find('.noEquipementiers').length === 0) {
                $('div.results-container').not('.hide').find('.sub_result').toggle(true);
            }
        } else if (nbRefOrigineVisible === 0) {
            $(RESULTAT_REF_ORIGINE).each(function(){
                var refOrigCriterias = JSON.parse($(this).attr('data-criteres'));
                //On teste la présence de tous les critères dans la ref origine
                var criteriasList = [];

                _.forEach(selectedFilters, function(value, key){
                    if(refOrigCriterias.hasOwnProperty(key) && refOrigCriterias[key].indexOf(value) !== -1 && refOrigCriterias[key].length === value[0].length){
                        criteriasList.push(value);
                    }
                });
                var selectedFiltersLength = _.size(selectedFilters);
                var hasSelectedFilter = (criteriasList.length === selectedFiltersLength);

                if (hasSelectedFilter) {
                    $(this).parent().removeClass('hide');
                    $(this).removeClass('hide');
                    $(this).parent().find('.sub_result').removeClass('hide');
                }
            });
            getPopin($('document'), '/pieces/date-affectation-origine', 'popinDateAffectationOrigine');
            $('.showError').removeClass('hide');
            $('.showError').next('.row.sort-separator').removeClass('hide');
        }
    }
    displayCriteres();
    updateListRefEquipMultiOrig();
    updateInfosRef();
}

//compte et actualise le nb de ref origine total affiché.
//compte et actualise le nb de ref equip total affiché.
export function updateInfosRef (sidexa) {
    if (sidexa === undefined) {
        sidexa = false;
    }

    var nbRefEquiTotal = 0;
    var elementOrigine = '';
    if(sidexa){
        elementOrigine = '.verified';
        $('.nb-ref-origine').html($(elementOrigine).parent().filter(":visible").length);
        $(elementOrigine).not('.hide').not('.noequip').each(function(){
            var subResult = $(this).parent().filter(":visible").find('.sub_result');
            var refeq = subResult.find('.produits').attr('reforigine');
            subResult.find(`table.table-condensed > tbody[id="table-content-${refeq}"] > tr`).not('.hide').not('.noequip').each(function(){
                nbRefEquiTotal++;
            });
        });
    } else {
        elementOrigine = RESULTAT_REF_ORIGINE;
        $('.nb-ref-origine').html($(elementOrigine).not('.noequip').not('.hide').length);
        $(elementOrigine).not('.hide').not('.noequip').each(function(){
            var subResult = $(this).parent().find('.sub_result');
            var refeq = subResult.find('.produits').attr('reforigine');
            subResult.find(`table.table-condensed > tbody[id="table-content-${refeq}"] > tr`).not('.hide').not('.noequip').each(function(){
                nbRefEquiTotal++;
            });
        });
    }

    // Mise à jour des éléments indiquant le nombre de références affichées
    $('.nb-ref-equi-total').html(nbRefEquiTotal);
    $('.choix-equipementiers strong.loader-filter-result-piece').html(nbRefEquiTotal);
    adjust();
}


// Filtre critères position
var selectedFilters = {};
var listCriteriasShowable = [];
function applyRemoveFilter(element, sidexa) {

    if (sidexa === undefined) {
        sidexa = false;
    }

    var filterDiv = element.closest('div');
    var filterName = element.attr('data-filterName');
    var filterValue = element.attr('data-filterValue');

    var criteriaSelected = $('input.criteria-btn:checked').last();
    var nbCriteriaSelected = $('input.criteria-btn:checked').length;

    //Si on clique
    if(element.is(':checked')){

        if (nbCriteriaSelected === 1) {
            filterDiv.find('input').addClass('masterFilter');
        }
        //Passe bouton en bleu
        filterDiv.addClass('active');
        //On ajoute le filtre sélectionné dans la liste des filtres
        if(selectedFilters[filterName]){
            selectedFilters[filterName].push(filterValue);
        } else {
            selectedFilters[filterName] = [filterValue];
        }
        hideShowRefOrigByCriteriaSelected();

        var nbVisibleRefOrigine = countVisibleRefOrigine();
        if (nbVisibleRefOrigine === 1){
            if ($('.choix-equipementiers').find('.noEquipementiers').length === 0) {
                $('div.results-container').not('.noequip').find('.sub_result').toggle(true);
            }
        } else {
            $('div.results-container').not('.noequip').find('.sub_result').toggle(false);
        }

    } else {//Si on déclique

        if (filterDiv.find('input').hasClass('masterFilter')) {
            filterDiv.find('input').removeClass('masterFilter');
        }
        //On passe le btn en gris
        filterDiv.removeClass('active');
        filterDiv.blur();
        //On enlève le filtre de la liste
        if(selectedFilters[filterName] != 'undefined'){
            if(selectedFilters[filterName].length === 1){
                _.unset(selectedFilters, filterName);
            } else {
                _.pull(selectedFilters[filterName], filterValue);
            }
        }
        //On passe tout en visible
        $(RESULTAT_REF_ORIGINE).each(function(){
            $(this).parent().removeClass('hide');
            $(this).removeClass('hide');
            $(this).parent().find('.sub_result').removeClass('hide');
        });
        hideShowRefOrigByCriteriaSelected();

        var nbVisibleRefOrigine = countVisibleRefOrigine();
        if ($(SELECT_EQUIP).val() === null) {
            if (nbVisibleRefOrigine === 1) {
                $('div.results-container').not('.hide').not('.noequip').find('.sub_result').toggle(true);
            } else {
                $('div.results-container').not('.hide').not('.noequip').find('.sub_result').toggle(false);
            }
        } else if ($(SELECT_EQUIP).val() !== null) {
            $('div.results-container').not('.hide').not('.noequip').find('.sub_result').toggle(true);
        }
    }
    update_toggle_button_labels();

    /*
     * Partie permettant la multi-sélection de critères (AD-2013)
     * Il s'agit ici de réactiver les critères en fonction des cas
     */
    if (nbCriteriaSelected === 0) {
        listCriteriasShowable = [];
    } else if (nbCriteriaSelected === 1) {
        listCriteriasShowable = [];
        $(RESULTAT_REF_ORIGINE).not('.hide').not('.noequip').each(function() {
            var dataCriteres = $.parseJSON($(this).attr('data-criteres'));
            $.each(dataCriteres, function (key, critereValue){
                if (critereValue !== ".") {
                    if ($.inArray(critereValue, listCriteriasShowable) === -1) {
                        listCriteriasShowable.push(critereValue);
                    }
                }
            });
        });

        $('div.filtres').find('input').each(function(){
            var dataFilterName = $(this).attr('data-filtername');
            var dataFilterValue = $(this).attr('data-filtervalue');
            if ($(CHECKBOX).length > 0 && !($(CHECKBOX).is(':checked'))) {
                if (dataFilterName == criteriaSelected.attr('data-filtername') && !isHiddenByDMec(dataFilterName, dataFilterValue)) {
                    $(this).closest('div').removeClass('disabled');
                }
            } else {
                if (dataFilterName == criteriaSelected.attr('data-filtername')) {
                    $(this).closest('div').removeClass('disabled');
                }
            }
        });
    } else {
        var nbSelectedFilterName = getNbSelectedFilterName();

        if (nbSelectedFilterName === 1) {

            $('div.filtres').find('input').each(function(){
                var dataFilterName = $(this).attr('data-filtername');
                var dataFilterValue = $(this).attr('data-filtervalue');
                if (!($(CHECKBOX).is(':checked'))) {
                    if (dataFilterName == criteriaSelected.attr('data-filtername') && !isHiddenByDMec(dataFilterName, dataFilterValue)) {
                        $(this).closest('div').removeClass('disabled');
                    }
                } else {
                    if (dataFilterName == criteriaSelected.attr('data-filtername') ) {
                        $(this).closest('div').removeClass('disabled');
                    }
                }
            });

        } else {
            var nbCriteriaForVisibleRefOrig = getNbCriteriaForVisibleRefOrig();
            var nbVerifiedRefOrig = $('.result-ref-origine.verified').length;
            if (nbVerifiedRefOrig > 0) {
                listCriteriasShowable = [];
                $('.result-ref-origine.verified').not('.noequip').not('.hide').each(function() {
                    var dataCriteres = $(this).attr('data-criteres');
                    var nbCriteriaForRefOrig = getNbCriteriaForRefOrig($.parseJSON(dataCriteres));
                    $('input.criteria-btn:not(:checked)').each(function() {
                        var dataFilterValue = $(this).attr('data-filterValue');
                        var dataFilterName = $(this).attr('data-filterName');
                        if (_.includes(dataCriteres, dataFilterName) && _.includes(dataCriteres, dataFilterValue) && nbCriteriaForRefOrig === nbCriteriaForVisibleRefOrig) {
                            if ($.inArray(dataFilterValue, listCriteriasShowable) === -1) {
                                listCriteriasShowable.push(dataFilterValue);
                            }
                        } else if (dataFilterName == criteriaSelected.attr('data-filtername')) {
                            $(this).closest('div').removeClass('disabled');
                        }
                    });
                });
            } else {
                var masterFilter = $('div.filtres').find('.masterFilter');
                $(RESULTAT_REF_ORIGINE).not('.noequip').each(function() {
                    var dataCriteres = $(this).attr('data-criteres');
                    var nbCriteriaForRefOrig = getNbCriteriaForRefOrig($.parseJSON(dataCriteres));
                    if (_.includes(dataCriteres, masterFilter.attr('data-filterValue')))
                    {
                        $('input.criteria-btn:not(:checked)').each(function() {
                            var dataFilterValue = $(this).attr('data-filterValue');
                            var dataFilterName = $(this).attr('data-filterName');
                            if (_.includes(dataCriteres, dataFilterName) && _.includes(dataCriteres, dataFilterValue) && nbCriteriaForRefOrig === nbCriteriaForVisibleRefOrig) {
                                if ($.inArray(dataFilterValue, listCriteriasShowable) === -1) {
                                    listCriteriasShowable.push(dataFilterValue);
                                }
                            }
                        });
                    }
                });
            }

            $('div.filtres').find('input').each(function(){
                var dataFilterName = $(this).attr('data-filtername');
                var dataFilterValue = $(this).attr('data-filtervalue');
                if ($.inArray(dataFilterValue, listCriteriasShowable) !== -1) {
                    $(this).closest('div').removeClass('disabled');
                }
            });
        }
    }

    updateInfosRef(sidexa);
    update_toggle_button_labels();
}

function hideShowRefOrigByCriteriaSelected () {

    //Pour chaque ref origine, on vérifie la présence de tous les filtres sélectionnés
    $(RESULTAT_REF_ORIGINE).not('.noequip').each(function(){
        //la liste des critères de la ref orig
        var refOrigCriterias = JSON.parse($(this).attr('data-criteres'));
        //On teste la présence de tous les critères dans la ref origine
        var criteriasList = [];

        _.forEach(selectedFilters, function(value, key){
            if (value.length === 1) {
                if(refOrigCriterias.hasOwnProperty(key) && refOrigCriterias[key].indexOf(value) !== -1 && refOrigCriterias[key].length === value[0].length){
                    criteriasList.push(value);
                }
            } else {
                if(refOrigCriterias.hasOwnProperty(key) && $.inArray(refOrigCriterias[key], value) !== -1){
                    criteriasList.push(value);
                }
            }
        });

        var selectedFiltersLength = _.size(selectedFilters);
        var hasSelectedFilter = (criteriasList.length === selectedFiltersLength);
        var thisDataRefeq = $(this).attr('data-refeq');
        if(hasSelectedFilter){
            $(this).parent().removeClass('hide');
            $(this).removeClass('hide');
            $('div#'+ thisDataRefeq).removeClass('hide');
        } else {
            $(this).parent().addClass('hide');
            $(this).addClass('hide');
            $('div#'+ thisDataRefeq).addClass('hide');
        }

        if ($(CHECKBOX).length > 0) {
            if (!$(CHECKBOX).is(':checked')) {
                var dmecInput = getDMecDate();
                if ($(this).attr('data-daffectation').indexOf('/') !== -1) {
                    var dmecRef = $(this).attr('data-daffectation').split('/');
                    var nbMonthDebut = monthCount(dmecRef[0]);
                    var nbMonthFin = null;
                    if (dmecRef[1]){
                        nbMonthFin = monthCount(dmecRef[1]);
                    }
                } else {
                    var dmecRef = $(this).attr('data-daffectation');
                    if (dmecRef != $('#dateNow').val()) {
                        if ($(this).find('.dates_affectation_piece > strong').hasClass('start-affectation')) {
                            var nbMonthDebut = monthCount(dmecRef);
                            var nbMonthFin = null;
                        } else if ($(this).find('.dates_affectation_piece > strong').hasClass('end-affectation')) {
                            var nbMonthFin = monthCount(dmecRef);
                            var nbMonthDebut = null;
                        }
                    } else {
                        var nbMonthDebut = 0;
                    }

                }

                var nbMonthSaisie = monthCount(dmecInput);
                var dateNowFormatted = getDateNowFormatted();

                if (dmecRef != 'undefined' && dmecRef[0] != dateNowFormatted) {
                    if((nbMonthDebut - 3) > parseInt(nbMonthSaisie) ||  (nbMonthSaisie > nbMonthFin + 3 && nbMonthFin != null)){
                        $(this).parent().addClass('hide');
                        $(this).addClass('hide');
                        $(this).parent().find('.sub_result').addClass('hide');
                    }
                }
            }
        } else {
            if($('.btn.activermec').hasClass('active')){
                var dmecInput = getDMecDate();
                var dmecRef = $(this).attr('data-daffectation').split('/');


                var nbMonthFin = null;
                if (dmecRef[1]) {
                    var nbMonthDebut = monthCount(dmecRef[0]);
                    nbMonthFin = monthCount(dmecRef[1]);
                } else {
                    if (dmecRef[0] != $('#dateNow').val()) {
                        if ($(this).find('.dates_affectation_piece > strong').hasClass('start-affectation')) {
                            var nbMonthDebut = monthCount(dmecRef[0]);
                            var nbMonthFin = null;
                        } else if ($(this).find('.dates_affectation_piece > strong').hasClass('end-affectation')) {
                            var nbMonthFin = monthCount(dmecRef[0]);
                            var nbMonthDebut = null;
                        }
                    } else {
                        var nbMonthDebut = 0;
                    }
                }

                var nbMonthInput = monthCount(dmecInput);
                var dateNowFormatted = getDateNowFormatted();

                if (dmecRef != 'undefined' && dmecRef[0] != dateNowFormatted && nbMonthFin != null) {
                    if((nbMonthDebut - 3) > nbMonthInput ||  nbMonthInput > (nbMonthFin - 3)){
                        $(this).parent().addClass('hide');
                        $(this).addClass('hide');
                        $(this).parent().find('.sub_result').addClass('hide');
                    }
                } else if (dmecRef != 'undefined' && dmecRef[0] != dateNowFormatted && nbMonthFin == null) {
                    if((nbMonthDebut - 3) > nbMonthInput){
                        $(this).parent().addClass('hide');
                        $(this).addClass('hide');
                        $(this).parent().find('.sub_result').addClass('hide');
                    }
                }
            }
        }
    });

    var nbVerifiedRefOrig = $('.result-ref-origine.verified').length;
    if(applySidexa || nbVerifiedRefOrig > 0) {
        applySidexaRulesOnResults();
    }

    var nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
    if (nbRefOrigineVisible === 0) {
        $(RESULTAT_REF_ORIGINE).each(function(){
            var refOrigCriterias = JSON.parse($(this).attr('data-criteres'));
            //On teste la présence de tous les critères dans la ref origine
            var criteriasList = [];

            _.forEach(selectedFilters, function(value, key){
                if(refOrigCriterias.hasOwnProperty(key) && refOrigCriterias[key].indexOf(value) !== -1){
                    criteriasList.push(value);
                }
            });
            var selectedFiltersLength = _.size(selectedFilters);
            var hasSelectedFilter = (criteriasList.length === selectedFiltersLength);

            if (hasSelectedFilter) {
                $(this).parent().removeClass('hide');
                $(this).removeClass('hide');
                $(this).parent().find('.sub_result').removeClass('hide');
            }
        });
    }

    countNbRefEquip();

    disabledIfNoRefOrig();
    disabledIfRefOrig();

    var listVisibleEquip = getVisibleEquip();
    $('#selectEquip option').each(function(){
        if ($.inArray($(this).val(),listVisibleEquip) === -1) {
            $(this).attr('disabled','disabled');
        } else {
            $(this).removeAttr('disabled');
        }
    });
    $(SELECT_EQUIP).selectpicker('refresh');

    if ($('#selectEquip option:selected').length > 1) {
        var nbEquipSelected = $('#selectEquip option:selected').length;
        $('button[data-id="selectEquip"]').attr('title',nbEquipSelected);
        $('button[data-id="selectEquip"] > span:first-child').html(nbEquipSelected+ SELECT_LABEL);
    }
}

export function disabledIfRefOrig() {

    $('input.criteria-btn:not(:checked)').each(function(){
        $(this).closest('div').removeClass('active');
    });

    var refOrigResults = $(RESULTAT_REF_ORIGINE).not('.hide');

    var uncheckedCriterias = $('input.criteria-btn:not(:checked)');

    uncheckedCriterias.each(function(){
        var uncheckedCriteriaDiv = $(this).closest('div');
        var uncheckedCriteriaValue = $(this).attr('data-filterValue');
        var uncheckedCriteriaName = $(this).attr('data-filterName');
        var nbResultsForUncheckedCriterias = 0;
        if (!uncheckedCriteriaDiv.hasClass('disabled')) {
            refOrigResults.each(function(){
                var refOrigCriterias = $.parseJSON($(this).attr('data-criteres'));
                $.each(refOrigCriterias, function(key, value){
                    if (value === uncheckedCriteriaValue && key === uncheckedCriteriaName) {
                        nbResultsForUncheckedCriterias++;
                    }
                });
            });

            if (nbResultsForUncheckedCriterias === refOrigResults.length) {
                uncheckedCriteriaDiv.addClass('disabled');
                uncheckedCriteriaDiv.addClass('active');
            }
        }
    });

}

//Check s'il y a des résultats pour les critères restants. Si non, on passe le critère en disable
export function disabledIfNoRefOrig () {
    // je passe tous les filtres en enable
    $('.criteria-btn').each(function(){
        $(this).closest('div').removeClass('disabled');
    });

    // Je récupère les ref origines affichées (OK)
    var refOrigResults = $(RESULTAT_REF_ORIGINE).not('.hide').not('.noequip');

    var unckeckedCriterias = $('input.criteria-btn:not(:checked)');

    // Pour chaque critère unckecked
    unckeckedCriterias.each(function(){
        var unckeckedCriteriaDiv = $(this).closest('div');
        var unckeckedCriteriaValue = $(this).attr('data-filterValue');
        var uncheckedCriteriaName = $(this).attr('data-filterName');

        //je teste la présence de sa valeur dans les ref origines affichées
        var hasResultsForUncheckedCriterias = [];
        refOrigResults.each(function(){
            var refOrigCriterias = $.parseJSON($(this).attr('data-criteres'));
            var itemResult = false;

            $.each(refOrigCriterias, function(key, value){
                if (key.toString().toUpperCase() === uncheckedCriteriaName.toString().toUpperCase()
                    && value.toString().toUpperCase() === unckeckedCriteriaValue.toString().toUpperCase()
                ) {
                    itemResult = true;
                }
            });
            hasResultsForUncheckedCriterias.push(itemResult);
        });
        //Si pas présent dans aucun, j'applique disable sur le filtre
        if(!(_.includes(hasResultsForUncheckedCriterias, true))){
            unckeckedCriteriaDiv.addClass('disabled');
        }
    });
}

function getNbCriteriaForVisibleRefOrig() {
    var refOrig = $(RESULTAT_REF_ORIGINE).not('.hide').not('.noequip');
    var dataCriteres = $.parseJSON(refOrig.attr('data-criteres'));

    return getNbCriteriaForRefOrig(dataCriteres);
}

function getNbCriteriaForRefOrig(dataCriteres) {
    var nbCriteriaForRefOrig = 0;
    $.each(dataCriteres, function(key, value) {
        if (value != '.') {
            nbCriteriaForRefOrig++;
        }
    });
    return nbCriteriaForRefOrig;
}

function getNbSelectedFilterName() {
    var listSelectedFilterName = [];
    $('input.criteria-btn:checked').each(function(){
        if ($.inArray($(this).attr('data-filterName'), listSelectedFilterName) === -1) {
            listSelectedFilterName.push($(this).attr('data-filterName'));
        }
    });

    return listSelectedFilterName.length;
}

function countVisibleRefOrigine() {
    return $('div.result-ref-origine').not('.noequip').not('.hide').length;
}

function getVisibleEquip() {
    var listEquip = ['tous'];
    $('div.results-container').not('.hide').each(function(){
        $(this).find('.sub_result tbody tr.origin-datas').each(function(){
            var equip = $(this).attr('data-equip');
            if ($.inArray(equip,listEquip) === -1) {
                listEquip.push(equip);
            }
        });
    });
    listEquip.sort();

    return listEquip;
}

function sortByClass(a,b) {
    var aClassName = a.className;
    var bClassName = b.className;

    if (aClassName > bClassName) {
        return 1;
    } else if (aClassName < bClassName) {
        return -1;
    }

    return 0;
}

function resetFilter() {
    window.location.reload();
}

//Calcul le nombre de mois total
function monthCount(date){
    if (date)
    {
        var yearDate = parseInt(date.substring(0, 4));
        var monthDate = parseInt(date.substring(4, 6));
        var rslt = parseInt(yearDate*12 + monthDate);
        if (isNaN(rslt)) {
            return -1;
        }
        return rslt;
    }
    return -1;
}

//Prise en compte de la date de 1ère mise en circulation au chargement de la page
//Si nous récupérons une dmec pour le vehicule recherché
//et qu'il n'y a pas de résultats incluant celle-ci, on affiche un message à ce propos
export function checkDmecDate(){
    const dmecInput = getDMecDate();
    const selfDiagnosisPage = $('.autodiagnostic-tool').length;
    let nbRefOrigineVisible;
    if (dmecInput) {
        toggleDmecSwitch(dmecInput);
        updateInfosRef();
        if ($('.results').length > 0) {
            nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
            if (nbRefOrigineVisible === 0) {
                $(CHECKBOX).attr('checked', 'checked');
                $(CHECKBOX).attr('disabled', 'disabled');
                $('.mec').css('opacity', '.5');
                toggleDmecSwitch(dmecInput);
                updateInfosRef();
                getPopin(jQuery('document'), '/pieces/date-affectation-origine', 'popinDateAffectationOrigine');
                $('.showError').removeClass('hide');
                $('.showError').next('.row.sort-separator').removeClass('hide');
            }
            nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
            if (nbRefOrigineVisible === 1) {
                $('div.results-container').not('.hide').find('.sub_result').toggle(true);
                if ($('#formfiltrer > .col-xs-9').hasClass('hide') && $(RESULTAT_REF_ORIGINE).not('.hide').find('.choix-equipementiers > .noEquipementiers')) {
                    $(SHELTER).addClass('hide');
                }
            }
        } else {
            nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;

            if (nbRefOrigineVisible === 1) {
                if ($('.choix-equipementiers').children('.noEquipementiers').length === 0) {
                    $('div.results-container').not('.hide').find('.sub_result').toggle(true);
                }

                if ($('#formfiltrer > .col-xs-9').hasClass('hide') && $(RESULTAT_REF_ORIGINE).not('.hide').find('.choix-equipementiers > .noEquipementiers')) {
                    $(SHELTER).addClass('hide');
                }
            }
        }
    } else if (selfDiagnosisPage > 0) {
        updateInfosRef();
        nbRefOrigineVisible = $(RESULTAT_REF_ORIGINE).not('.hide').length;
        if (nbRefOrigineVisible === 1) {
            $('div.results-container').not('.hide').find('.sub_result').toggle(true);
        }
    }
}

export function updateListRefEquip() {
    const isConstructor = $('#constructeurs').length > 0;
    const listEquip = [];
    const originDatas = isConstructor === true ?
        $('tbody tr.origin-datas'):
        $('div.sub_result tbody tr.origin-datas').not('.hide');
    originDatas.each(function(){
        const element = $(this);
        const dataEquip = element.attr('data-equip');
        if (listEquip.indexOf(dataEquip) === -1) {
            listEquip.push(dataEquip);
        }
        const listSelect = isConstructor === true ?
            $('#equipementiers option') :
            $(this).closest('table').find('select:not(.selectPriceLevel) option');
        listSelect.each(function(){
            if ($(this).val() !== 'tous') {
                if ($.inArray($(this).val(),listEquip) === -1) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            }
        });
    });
    if (true === isConstructor) {
        $('#equipementiers').selectpicker('refresh');
    } else {
        $('div.sub_result table').each(function(){
            $(this).find('select:not(.selectPriceLevel)').selectpicker('refresh');
        });
    }
}

function updateListRefEquipMultiOrig() {
    var listEquip = [];

    $('div.results-container').not('.hide').find('div.sub_result tbody tr.origin-datas').each(function(){

        var element = $(this);

        listEquip.push(element.attr('data-equip'));

        $('#selectEquip option').each(function(){
            if ($(this).val() !== 'tous') {
                if ($.inArray($(this).val(),listEquip) === -1) {
                    $(this).addClass('disabled');
                } else {
                    $(this).removeClass('disabled');
                }
            }
        });
        $(SELECT_EQUIP).selectpicker('refresh');

    });
}

// Masque/Affiche le(s) critere(s) lié(s) à une(des) ref(s) origine(s)
// dépendante(s) de l'activation ou non du filtre 1ere mec
export function displayCriteres() {
    var listCriteresVisible = [];
    $(RESULTAT_REF_ORIGINE).not('.hide').not('.noequip').each(function(){
        var criteres = $.parseJSON($(this).attr('data-criteres'));
        $.each(criteres, function(key, value) {
            if (value !== '.') {
                listCriteresVisible.push(value);
            }
        });
    });

    var criteriaSelected = $('input.criteria-btn:checked');
    var nbCriteriaSelected = criteriaSelected.length;

    if (nbCriteriaSelected == 1) {
        $('div.filtres').find('input').each(function() {
            var dataFilterName = $(this).attr('data-filterName');
            if (dataFilterName == criteriaSelected.attr('data-filterName')) {
                $(this).closest('div').removeClass('disabled');
            }
        });
    } else {
        $(RESULTAT_REF_ORIGINE).each(function() {
            var criteres = $.parseJSON($(this).attr('data-criteres'));
            $('div.filtres').find('input').each(function (){
                var dataFilterValue = $(this).attr('data-filterValue');
                if (_.includes(criteres, dataFilterValue)) {
                    $.each(criteres, function(key, value) {
                        $(`input[data-filterValue='${value}']`).closest('div').removeClass('disabled');
                    });
                }
            });
        });
    }
}


var codePositionSelected = '';
var getSidexa = false;
var applySidexa = false;

/**
 * La fonction permet de faire un call ajax au WS Sidexa
 * @param string element
 * @param string url
 * @param string target
 */
export function callSidexaAjax(element,url,target) {

    // Récupérer les paramètres
    var bDateMec = $('#bDateMec').val();
    var bAction = $('#bAction').val();
    var dateMec_mois = $('#dateMec_mois').val();
    var dateMec_annee = $('#dateMec_annee').val();
    var callSidexa = $('#callSidexa').val();

    var listValues = [];
    var position= [];
    var hasMatchedSidexa = false;
    var formatedTarget = target.toUpperCase();

    if(!getSidexa){
        $('div.result-ref-origine').not('.noequip').not('.hide').each(function(){
            var criteres = $(this).attr('data-criteres');
            var criteresParsed = $.parseJSON(criteres);
            var reforig = $(this);
            $.each(criteresParsed, function(key, value) {
                if (key == 'position') {
                    listValues.push(value);
                    var isVerified = reforig.hasClass('verified');
                    if(getSidexa && (value == formatedTarget) && isVerified){
                        hasMatchedSidexa = true;
                    }
                }
            });
        });
    } else {
        $('div.result-ref-origine').not('.noequip').each(function(){
            var criteres = $(this).attr('data-criteres');
            var criteresParsed = $.parseJSON(criteres);
            var reforig = $(this);
            $.each(criteresParsed, function(key, value) {
                if (key == 'position') {
                    listValues.push(value);
                    var isVerified = reforig.hasClass('verified');
                    if(getSidexa && (value == formatedTarget) && isVerified){
                        hasMatchedSidexa = true;
                    }
                }
            });
        });
    }


    var av = 0;
    var ar = 0;
    for (var i = 0; i < listValues.length; i++) {
        if (listValues[i] == 'AV') {
            av++;
        } else if (listValues[i] == 'AR') {
            ar++;
        }
        if ($.inArray(listValues[i],position) === -1) {
            position.push(listValues[i]);
        }
    }

    var cible;
    if (target == 'av') {
        cible = av;
    } else if (target == 'ar') {
        cible = ar;
    }

    //On vient de cliquer sur un critère sidexa oui/non
    applySidexa = element.is(':checked') && hasMatchedSidexa ? true : false;

    //Le filtre dmec est-il à on ?
    var filterDmecOn = !($(CHECKBOX).is(':checked'));

    //Si nécessaire, je passe le switch dmec à off
    if(getSidexa && applySidexa && filterDmecOn){
        $(CHECKBOX).click();
    }

    //Si je clique et que switch dmec est sur off, je le disable
    if(getSidexa && applySidexa && !filterDmecOn){
        $(CHECKBOX).attr('disabled', 'disabled');
        $('.mec').css('opacity', '.5');
    }

    //Si je déclique sur un critère sidexa, j'enlève le disable sur switch dmec
    if(getSidexa && !(element.is(':checked')) && hasMatchedSidexa){
        $(CHECKBOX).removeAttr("disabled");
        $('.mec').css('opacity', '1');
    }

    // Si on clique
    if(element.is(':checked') && !getSidexa && cible > 1 && callSidexa) {

        getSidexa = true;
        var param = {
            'bDateMec': bDateMec,
            'bAction': bAction,
            'dateMec_mois': dateMec_mois,
            'dateMec_annee': dateMec_annee,
            'listposition': position
        };

        $('.showError').addClass('hide');
        $('#loading-message').show();  // show the loading message

        $.ajax({
            type: "POST",
            url: url,
            data: param,
            success: function (response) {

                var hasVerifiedCriteria = _.some(response.data.referencesOrigine, {verified: true});

                if (response === false || !hasVerifiedCriteria) {
                    $('#loading-message').hide(); // hide the loading message
                    // MAJ des réfs
                    updateInfosRef();

                    // On applique les filtres
                    applyRemoveFilter(element);

                    $('.showError').addClass('hide');

                    return '';
                } else {
                    // Affectation code position seléctionné : ar / av
                    $( "div.affectation-origine" ).addClass( "matched-sidexa" );

                    applySidexa = true;

                    //On passe le filtre dmec à off si pas déjà
                    if (filterDmecOn){
                        $(CHECKBOX).click();
                    } else {
                        $(CHECKBOX).attr('disabled', 'disabled');
                        $('.mec').css('opacity', '.5');
                    }

                    var refOrigResults = $("div.results-container div.result-ref-origine");
                    refOrigResults.each(function(){
                        var refcon = $(this).attr('data-refeq').slice(9);
                        var index = 0;
                        for (var refOrigine in response.data.referencesOrigine) {
                            if (response.data.referencesOrigine[refOrigine].refcon === refcon) {
                                if (response.data.referencesOrigine[refOrigine].verified) {
                                    $(this).addClass('verified');
                                } else {
                                    $(this).parent().addClass('hide');
                                    $(this).addClass('hide');
                                }
                                // Suppression de la ligne courante de résultat correspondant avec la référence constructeur
                                // pour ne pas reboucler dessus pour rien à la prochaine boucle
                                response.data.referencesOrigine.splice(index, 1);
                                // sortie de la boucle "for" dès que la ligne courante de résultat correspond avec la référence constructeur
                                // pour ne pas poursuivre la boucle pour rien
                                break;
                            }
                            index++;
                        }
                    });

                    // MAJ des réfs
                    updateInfosRef(true);

                    // On applique les filtres
                    applyRemoveFilter(element, true);

                    $('.showError').addClass('hide');
                }
                $('#loading-message').hide(); // hide the loading message
            }, // end function
            error: function () {
                $('#loading-message').hide(); // hide the loading message
                return '';
            } // end function
        });

    } else {
        // On applique les filtres
        applyRemoveFilter(element);
    }

}

function applySidexaRulesOnResults(){
    var refOrigin = $("div.results-container div.result-ref-origine");
    refOrigin.each(function(){
        if(!$(this).hasClass('verified')) {
            $(this).parent().addClass('hide');
            $(this).addClass('hide');
        }
    });
}

/**
 * activer le filtre en cours
 * @param element
 */
function activateFiltre(target) {
    if (target == null) {
        target = codePositionSelected;
        $('#position-'+ target).removeClass('disabled');
    }
    // On active le filtre en cours
    $('#position-' + target).addClass('active');
}

function getDateNowFormatted() {
    var now = new Date();
    var year = now.getFullYear().toString();
    var month = '';
    if (now.getMonth() < 10) {
        month = '0'+(now.getMonth()+1).toString();
    } else {
        month = (now.getMonth()+1).toString();
    }

    return year + month;
}

/**
 * Vérifie si le critère passé en paramètre est caché par la dmec
 *
 * @param filterName categorie du critere
 * @param filterValue valeur du critere
 * @returns Boolean
 */
function isHiddenByDMec(filterName, filterValue) {
    var isHiddenByDmec = false;
    $('.result-ref-origine.hide').not('.noequip').each(function() {
        var refOrigCriterias = $.parseJSON($(this).attr('data-criteres'));
        var dmecRef = $(this).attr('data-daffectation').split('/');
        var nbMonthFin = null;
        if (dmecRef[1]) {
            var nbMonthDebut = monthCount(dmecRef[0]);
            nbMonthFin = monthCount(dmecRef[1]);
        } else {
            if (dmecRef[0] != $('#dateNow').val()) {
                if ($(this).find('.dates_affectation_piece > strong').hasClass('start-affectation')) {
                    var nbMonthDebut = monthCount(dmecRef[0]);
                    var nbMonthFin = null;
                } else if ($(this).find('.dates_affectation_piece > strong').hasClass('end-affectation')) {
                    var nbMonthFin = monthCount(dmecRef[0]);
                    var nbMonthDebut = null;
                }
            } else {
                var nbMonthDebut = 0;
            }
        }

        var datemec = getDMecDate();
        var nbMonthSaisie = monthCount(datemec);
        if((nbMonthDebut - 3) > nbMonthSaisie ||  (nbMonthSaisie > (nbMonthFin + 3) && nbMonthFin != null)){
            if(refOrigCriterias.hasOwnProperty(filterName) && refOrigCriterias[filterName].indexOf(filterValue) !== -1 && refOrigCriterias[filterName].length === filterValue.length){
                isHiddenByDmec = true;
                return false;
            }
        }
    });

    return isHiddenByDmec;
}

/*** On lance la fonction pour le chargement des vignettes en lazy-loading ***/
$(window).load(function () {
    initImgWithLazyLoading();
});

/*** Initialisation du handler d'init du carousel pour la fiche produit ***/
$(document).on('show.bs.modal', '#popinFicheProduit', function () {
    //Si pas d'image, on initialise l'image par défaut
    if($('#popinFicheProduit').find('.lazy-loading').length != 0){
        initImgWithLazyLoading();
    }
    //Et dans tous les cas on initialise le carousel (uniquement de la vignette)
    initOwlCarousel($('#popinFicheProduit').find('.productDetail').first());
});


function update_toggle_button_labels()
{
    $("div.results-container").each(function(){
        var resultContainer = $(this);
        var equipList = resultContainer.find("div.sub_result");
        var id = equipList.attr('id');
        var selector = $(`.lienRefRoRe[data-div='${id}']`);
        if(equipList.is(":hidden")) {
            //Changing top button
            resultContainer.find('img')
                .removeClass(ROTATED_180);
            resultContainer.find(HIDE_DETAIL_IMG)
                .removeClass(ROTATED_180);
            if(selector.length > 0) {
                resultContainer.find('.message')
                    .html("AFFICHER");
                resultContainer.find(HIDE_DETAIL_IMG).find('span.message')
                    .html("AFFICHER");
            } else {
                resultContainer.find('.message')
                    .html("VOIR LES RESULTATS");
                resultContainer.find(HIDE_DETAIL_IMG).find('.message')
                    .html("VOIR LES RESULTATS");
            }
        } else {
            resultContainer.find('.message')
                .html('MASQUER');
            resultContainer.find(HIDE_DETAIL_IMG).find('span.message')
                .html('MASQUER');
            resultContainer.find('.lienRefRoRe  img')
                .addClass(ROTATED_180);
            resultContainer.find(HIDE_DETAIL_IMG)
                .not('.img-vignette, .owl-lazy,.zoom-imgs')
                .addClass(ROTATED_180);
        }
    });
}

/**
 * Adjustment of the number of  ReEq
 */
function adjust()
{
    var total = 0;
    const numberEquip = 'div.bloc-reference div.choix-equipementiers strong'
    $('div.results-container:not(.hide)').not('.noequip').each(function(){
        var refCons = $(this);
        if (refCons.find('.equipments_count').length > 0 ){
            var refeqs = refCons.find('div.sub_result')
                .find('tr.origin-equivalence:not(.hide)[data-pr !="ZZ"][data-pr !="4"]')
                .length;
        } else {
            var refeqs = refCons.find('div.sub_result')
                .find('tr.origin-equivalence:not(.hide)')
                .length;
        }

        let strong = refCons.find(`${numberEquip}.loader-filter-result-piece , ${numberEquip}.equipments_count`)
        strong.text(refeqs);
        if(refeqs != 0) {
            refCons.toggle(true);
        }
        total += refeqs;
    });
    $("strong.blue_text.nb-ref-equi-total.loader-filter-result-piece").text(total);
    $("strong.nb-ref-origine").text($('div.results-container:not(.hide):visible').not('.noequip').length);
}
