import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import ViewAvailabilitiesEvent from "./ViewAvailabilitiesEvent";
export default class ViewAvailabilitiesNormalizer {
    normalize(element, origin) {
        const viewAvailabilitiesEvent = new ViewAvailabilitiesEvent(origin);
        const parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return viewAvailabilitiesEvent;
        }
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return viewAvailabilitiesEvent;
        }
        const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
        viewAvailabilitiesEvent.productCollection.addProduct(product);
        return viewAvailabilitiesEvent;
    }
    support(element, origin) {
        return true;
    }
}
