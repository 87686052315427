import { initDatePickerAjax } from './pubtech-main-v2'
import { ajouterPanier } from './pubtech-references-action'
import { FermerPopinFicheLiaisonMsg } from "./pubtech-fiche-liaison";
import { ajouterPanierPeintures } from './pubtech-peintures'
import { initReperes, switchHideOnClickOutside } from './popinFamilles';
import { formatAvailability } from "./manage-ga";
import { handleFicheProduitImageError } from "./imageErrors";
import {
    animatePanierButton,
    handleAjaxResult,
    handleProductExistsInCart,
    preAddToCartCheck,
    preAddToCartSingleProductDataCollect
} from "./pre-add-to-cart";
import { initPromoBanner } from "./autossimo-pneus-promos-banners";
import EventHandler from './analytics/amplitude/EventHandler';
import GaEventHandler from './analytics/ga/GaEventHandler';

const $ = require('jquery');
const V3_PRICING = '.v3_pricing';
const BLOC_CONTACT_DISTRIBUTOR = '.availability-pricing:not(.mode) .bloc-contact-your-distributor.with-contact-us';
const POP_IN_ON_DEMAND = 'autossimo_pop_in_available_on_demand';
const TARGET_ON_DEMAND = 'popInAvailableOnDemand';
const DATA_AVAILABILITY = 'data-availability';
const DATA_MANUFACTURER_CODE = 'data-manufacturer-code';
const DATA_REFEQ = 'data-refeq-cart';
const POP_IN_CORA_ALERT_INFOS = 'autossimo_pop_in_cora_alert_infos';
const TARGET_CORA_UNAVAILABLE = 'popInCoraAlertInfos';
const POPIN_BTN_EINA_CONFIRM = $('.popin-btn-eina-confirm');
const POPIN_REF_KTYPNR = 'popin-ref-ktypnr';
const MODAL_TARGET = 'modal-target';
const POPIN_PACKAGE_IDENTIFIER = '#popinPackage';
const POPIN_FORBIDDEN_IDENTIFIER = '#popinForbidden';
const TIRE_RESULTAT = '.tire-resultat';
const LOADING_FP = 'loading-fp';
const POPIN_FP_PNEUS = '#popinFpPneus';
const POPIN_TECDOC_SUCCESS = '#popinTecdocSuccess';
const LOADING_BLOCK = '.loading-block';

/* Init Popin ajax */
$(document).ready(function () {
    //Ouvre une popin en chargeant en ajax son contenu préalablement
    $(document).on('click', '.action-open-popin-ajax', function () {
        const textButton = $(this).text().trim();
        const transmissionType = textButton.includes('Transmettre') ? 'transmission' : 'download';
        if ('transmission' === transmissionType) {
            GaEventHandler.beginCheckout($('.section_cart--body-item'), 'cart-layer');
        } else if ('download' === transmissionType) {
            GaEventHandler.beginCheckout($('.section_cart--body-item'), 'cart-layer-punchout');
        }
        const url = $(this).attr('data-ajax-url');
        const dataTarget = $(this).attr('data-modal-target');

        EventHandler.startOrder($(this),'cart_layout');
        $('#' + dataTarget).load(url, function () {
            $('#' + dataTarget).parent().show();
            $('#' + dataTarget).modal({
                backdrop: false
            });
        });
        $('#popinTransmissionCommande').css({ top: 100 });
        return false;
    });

    // Ajout panier fiche produit piece
    $(document).on('click', '.btn-panier-fp', function () {
        $(this).prop('disabled', true);
        GaEventHandler.addToCart($(this), 'fp');
        const origin = 'item_view';
        if (!canHandleUnavailableCoraProducts($(this))) {
            EventHandler.addToCart($(this), origin, false);
            return;
        }
        if ('block' === $(this).parents(V3_PRICING).find(BLOC_CONTACT_DISTRIBUTOR).css('display')) {
            EventHandler.addToCart($(this), origin, false);
            getPopin($(this), Routing.generate(POP_IN_ON_DEMAND), TARGET_ON_DEMAND);
        } else {
            const productId = $(this).data("product-id");
            const path = $(this).data("path");
            const codrefco = $(this).data("codrefco");
            const codrefeq = $(this).data("codrefeq");
            const qte = $("#QFP-" + codrefeq).val();
            const typeLigne = $(this).data("type-ligne");
            const date = $(this).attr("data-date");
            const availability = $(this).attr(DATA_AVAILABILITY);
            const refeq = $(this).attr(DATA_REFEQ);
            const manufacturerCode = $(this).attr(DATA_MANUFACTURER_CODE);
            const from = $(this).data("isidg");
            const immat = $(this).data('vehicule-immat');
            const advertisingBannerId = $(this).data('advertising-banner-id');
            const data = {
                obj: $(this),
                dataProductId: productId,
                ajaxurl: path,
                codrefco: codrefco,
                codrefeq: codrefeq,
                qte: qte,
                typeligne: typeLigne,
                date: date,
                paramDropshipping: undefined,
                manufacturerCode: manufacturerCode,
                availability: availability,
                description: undefined,
                from: from,
                refeq: refeq,
                vehiculeImmat: immat,
                advertisingBannerId: advertisingBannerId,
            }
            ajouterPanier(data, origin);
        }
    });

    // add to cart paint product sheet
    $(document).on('click', '.btn-panier-fp-peinture', function (e) {
        e.preventDefault();
        $(this).prop('disabled', true);
        GaEventHandler.addToCart($(this), 'fp-peinture');
        if ('block' === $(this).parents(V3_PRICING).find(BLOC_CONTACT_DISTRIBUTOR).css('display')) {
            EventHandler.addToCart($(this), 'item_view', false);
            getPopin($(this), Routing.generate(POP_IN_ON_DEMAND), TARGET_ON_DEMAND);
        } else {
            const path = $(this).data("path");
            const codrefco = $(this).data("ref");
            const codrefeq = $(this).data("codref");
            const qte = $("#Qfp-" + codrefeq).val();
            const typeLigne = $(this).data("type-ligne");
            const date = $(this).attr("data-date");
            const codarbo = $(this).attr("data-codarbo");
            const availability = $(this).attr(DATA_AVAILABILITY);
            const refeq = $(this).attr(DATA_REFEQ);
            const manufacturerCode = $(this).attr(DATA_MANUFACTURER_CODE);
            const advertisingBannerId = $(this).data('advertising-banner-id');
            const data = {
                path: path,
                ref: codrefco,
                codref: codrefeq,
                qte: qte,
                typeligne: typeLigne,
                date: date,
                codarbo: codarbo,
                availability: availability,
                refeq: refeq,
                manufacturerCode: manufacturerCode,
                advertisingBannerId: advertisingBannerId,
            }
            const preAddCheck = preAddToCartCheck(preAddToCartSingleProductDataCollect(data), false);
            if (handleAjaxResult(preAddCheck)) {
                handleProductExistsInCart(preAddCheck, function () {
                    ajouterPanierPeintures(data).done(() => EventHandler.addToCart($(e.target), 'item_view', true));
                }, $(this),
                () => EventHandler.addToCart($(e.target), 'item_view', false))
            } else {
                ajouterPanierPeintures(data).done(() => EventHandler.addToCart($(e.target), 'item_view', true));
                animatePanierButton($(this))
            }
        }
    });

    // ajout panier pneus depuis fiche produit
    $(document).on('click', '.btn-panier-fp-pneus', function () {
        $(this).prop('disabled', true);

        GaEventHandler.addToCart($(this), 'fp-pneus');

        const origin = 'item_view';
        const productId = $(this).data("product-id");
        const path = $(this).data("path");
        const codrefco = $(this).data("codrefco");
        const codrefeq = $(this).data("codrefeq");
        const isSheetDrop = $('#offer-deadline').length > 0 && $('#offer-deadline').css('display') === 'block';
        const qte = isSheetDrop ? $("#offer-deadline #QFP-" + codrefeq).val() : $("#QFP-" + codrefeq).val();
        const typeLigne = "C";
        let date = $(this).attr("data-date");
        const codarbo = $(this).attr("data-codarbo");

        // Get value flag dropShipping and siret from data
        let flagDropShipping = $(this).attr('data-flag-dropshipping');
        let siretDropShipping = $(this).attr('data-siret-dropshipping');
        const platform = $(this).attr('data-flag-platform');
        const availability = $(this).attr(DATA_AVAILABILITY);
        const refeq = $(this).attr(DATA_REFEQ);
        const manufacturerCode = $(this).attr(DATA_MANUFACTURER_CODE);
        const advertisingBannerId = $(this).attr('data-advertising-banner-id');

        if (typeof flagDropShipping == 'undefined') {
            flagDropShipping = 'N';
        }
        if (typeof siretDropShipping == 'undefined') {
            siretDropShipping = null;
        }
        if (typeof date == 'undefined') {
            date = null;
        }

        // Set DropShipping parameters
        const paramsDropShipping = { 'flag_dropshipping': flagDropShipping, 'siret_dropshipping': siretDropShipping };
        if ('block' === $(this).parents(V3_PRICING).find(BLOC_CONTACT_DISTRIBUTOR).css('display')) {
            EventHandler.addToCart($(this), origin, false);
            getPopin($(this), Routing.generate(POP_IN_ON_DEMAND), TARGET_ON_DEMAND);
        } else {
            const data = {
                obj: $(this),
                dataProductId: productId,
                ajaxurl: path,
                codrefco: codrefco,
                codrefeq: codrefeq,
                qte: qte,
                typeligne: typeLigne,
                date: date,
                paramDropshipping: paramsDropShipping,
                platform: platform,
                availability: availability,
                refeq: refeq,
                codarbo: codarbo,
                manufacturerCode: manufacturerCode,
                advertisingBannerId: advertisingBannerId
            }
            ajouterPanier(data, origin);
        }
    });

    jQuery(document).on('click', '#favButtonFp .deuxieme', function () {
        const strPathname = window.location.pathname;
        if (strPathname.indexOf('/devis/suivi') >= 0) {
            alert('Fonction indisponible pour le moment');
        }
    });

    // Generic quotation
    $(document).on('click', '.generic_quotation', function (e) {
        const quotationPersonalization = $('.quotation_personalization').val();
        const timeScales = $('.time_scales').val();
        let isGranted = true;

        if ($(this).hasClass('quote-parameters')) {
            isGranted = _.isEqual(quotationPersonalization || timeScales, '1');
        } else {
            isGranted = _.isEqual(quotationPersonalization, '1');
        }

        if (!isGranted) {
            alertPackage();
            e.preventDefault()
        }
    });

    // Show all engine oils on a modal
    $(document).on('click', '#allEngineOils',function () {
        $("#filterEngineOil").val('');
        const mid = $(this).data('mid');
        const errorAutodata = $(this).data('error');
        $.ajax({
            url: Routing.generate('autossimo_popin_engine_oils'),
            type: "GET",
            data: {
                mid: mid
            },
            success: function (data) {
                if (!errorAutodata) {
                    $('#popinEngineOils .modal-body table tbody').html(data);
                } else {
                    $('#popinEngineOils .modal-body').html(data);
                }
                $('#popinEngineOils').show();
                $('#popinEngineOils').modal({
                    backdrop: true
                });
            },
            onFailure: function (error) {
                console.log('error', error);
            }
        });
    });

    //Filter Engine Oils by Viscosity in the modal
    $("#filterEngineOil").on("keyup", function () {
        const search = $(this).val().toLowerCase();
        $("#listOilEngine tr td:nth-child(2)").filter(function () {
            if ($(this).text().replace(/[\r\n\s]+/g, ' ').trim().toLowerCase().indexOf(search) > -1) {
                $(this).parent().show();
            } else {
                $(this).parent().hide();
            }
        });
    });

    // Show Instructions of Engine Oils
    $(document).on('click', '.showInstructions', showInstructions);
    $(document).on('click', '#listOilEngine', showInstructions);
});

function showInstructions() {
    const mid = $(this).attr('data-mid');
    const notesId = $(this).attr('data-notesid');
    $.ajax({
        url: Routing.generate('autossimo_popin_instructions_engine_oils'),
        type: "GET",
        data: {
            mid: mid,
            notesId: notesId
        },
        success: function (data) {
            $('#popinInstructions .modal-body').html(data);
            $('#popinInstructions').show();
            $('#popinInstructions').modal({
                backdrop: true
            });
        },
        onFailure: function (error) {
            console.log('error', error);
        }
    });
}

global.getFpPneusAjax = (url, isDropshipping) => {
    if (!isDropshipping) {
        isDropshipping = false;
    }
    if (!$(TIRE_RESULTAT).hasClass(LOADING_FP)) {
        $(TIRE_RESULTAT).addClass(LOADING_FP);
        $(POPIN_FP_PNEUS).load(url, { dropshipping: isDropshipping }, function () {
            $(POPIN_FP_PNEUS).parent().show();
            $(POPIN_FP_PNEUS).modal({
                backdrop: true
            });
            initDatePickerAjax();
            $(TIRE_RESULTAT).removeClass(LOADING_FP);
            $(POPIN_FP_PNEUS).find("div.priceBloc")
                .find(".keep-price")
                .removeClass("showme");

            GaEventHandler.viewItem($(this),'getFpPneusAjax');
            const typePromo = $('.promo_flash_24');
            if (typePromo.length > 1) {
                $('.productDetail .promo_flash_24').removeClass('hidden');
            }
            const availability = formatAvailability($(POPIN_FP_PNEUS).find('#blocDispo .dispo-details').children(':not(.tooltips)').text());
            $(POPIN_FP_PNEUS).find('#blocDispo').attr('data-available-value', availability);
            handleFicheProduitImageError();
            initPromoBanner();
        });
    }

    return false;
}

global.getPopin = (obj, url, dataTarget, index, dataCoraNotAvailable = []) => {
    if (index != '' || index === 0) {
        $('#loaderrore' + index).removeClass('hide'); // On masque le gif au chargement de la page
    } else {
        $(LOADING_BLOCK).removeClass('hide'); // On masque le gif au chargement de la page
    }

    $('#' + dataTarget).load(url, { 'dataCoraNotAvailable': dataCoraNotAvailable }, function () {
        $('#' + dataTarget).parent().show();
        $('#' + dataTarget).modal({
            backdrop: true
        });

        $('body').addClass('modal-open');

        $('.modal-dialog').attr('style', 'top:120px');
        if ($('#popinFicheProduit').is(":visible")) {
            const topPosition = 370 - $('#popinFicheProduit').scrollTop();
            $('.popInCoraAlertInfos .modal-dialog').attr('style', `top:${topPosition}px`);
        }
        $(LOADING_BLOCK).hide(); // On masque le gif au chargement de la page
        if (index != '' || index === 0) {
            $('#loaderrore' + index).addClass('hide');
        } else {
            $('.loaderrore').hide(); // On masque le gif au chargement de la page
        }
    });

    obj.prop('disabled', false);

    return false;
}
$(document).on('click', '.action-open-popin:not(.action-open-popin-message-produit)', function () {
    getPopin($(this), $(this).data('url'), $(this).data(MODAL_TARGET));
});

$(document).on('click', '.bared_time', function (event) {
    if ($(this).attr('data-have-time-scales-role') === '1') {
        const urlParams = {
            codfam: $(this).attr('data-codfam'),
            landmark: $(this).attr('data-landmark'),
            codmar: $(this).attr('data-codmar'),
            numOrd: $(this).attr('data-numOrd'),
            sVin: $(this).attr('data-sVin'),
            immat: $(this).attr('data-immat'),
            typeMine: $(this).attr('data-typeMine'),
            iDmec: $(this).attr('data-iDmec'),
            type: $(this).attr('data-type'),
            libFa: $(this).attr('data-libFa'),
            libSG: $(this).attr('data-libSG'),
            libRep: $(this).attr('data-libRep'),
        };
        getPopin($(this), Routing.generate('autossimo_popin_temps_baremes', urlParams), $(this).data(MODAL_TARGET));
        EventHandler.viewLaborTime();
    } else {
        alertPackage();
        event.preventDefault();
    }
});

export function getPopinPlanche(obj, url, dataTarget, libSousFamille) {
    const data = {
        'libSousFamille': libSousFamille,
        'codmar': obj.attr('data-codmar'),
        'numOrd': obj.attr('data-numOrd'),
        'sVin': obj.attr('data-sVin'),
        'immat': obj.attr('data-immat'),
        'typeMine': obj.attr('data-typeMine'),
        'iDmec': obj.attr('data-iDmec'),
        'type': obj.attr('data-type'),
    }
    $('#' + dataTarget).load(url, data, function () {
        $('#' + dataTarget).parent().show();
        $('#' + dataTarget).modal({
            backdrop: false
        });

        let newSousFamHTML = '';
        const regLibSousFamille = /(.*)\|(.*)/;
        let sousFamilleNom = libSousFamille.replace(regLibSousFamille, '$2');

        if (sousFamilleNom.length > 19) {
            sousFamilleNom = sousFamilleNom.substring(0, 14);
            sousFamilleNom = sousFamilleNom + '...';
        }
        newSousFamHTML = sousFamilleNom;
        $('#sousfam').html(newSousFamHTML);
        $('#sousfam').attr('title', sousFamilleNom);
        initReperes();
        switchHideOnClickOutside(false);

        $('div.modal-header div.close').on('click', function (event) {
            event.stopImmediatePropagation();
            switchHideOnClickOutside(true);
        });

    });

    return false;
}

function getPopinMessageProduit(obj, url, dataTarget, numligne, panier) {
    $('#headerDetail').find('.bandeau-alerte').remove();
    const data = {
        'numligne': numligne,
        'panier': panier
    };
    $('#' + dataTarget).load(url, data, function (responseText, textStatus, req) {
        switch (req.status) {
            case 403:
                alertForbidden();
                break;
            case 200:
                $('#' + dataTarget).parent().show();
                $('#' + dataTarget).modal({
                    backdrop: false
                });
                break;
            default:
                break;
        }
    });

    return false;
}
$(document).on('click', '.action-open-popin-message-produit', function () {
    getPopinMessageProduit(
        $(this),
        $(this).data('url'),
        $(this).data(MODAL_TARGET),
        $(this).data('line'),
        $(this).data('basket')
    )
})

$(document).on('click', '.action-get-popin-message-commande', function () {
    getPopinMessageCommande(
        $(this),
        $(this).data('url'),
        $(this).data('target'),
        $(this).data('numcde')
    )
})

function getPopinMessageCommande(obj, url, dataTarget, numcde) {
    const data = {
        'numcde': numcde
    }
    $('#' + dataTarget).load(url, data, function (responseText, textStatus, req) {
        switch (req.status) {
            case 403:
                alertForbidden();
                break;
            case 200:
                $('#' + dataTarget).parent().show();
                $('#' + dataTarget).modal({
                    backdrop: false
                });
                break;
            default:
                break;
        }
    });

    return false;
}

function getPopinFicheLiaison(obj, url, dataTarget) {
    let data = {
        'Immat': ''
    }
    if ($('.type-immatriculation').eq(0).html() == 'immat') {
        data = {
            'Immat': 'immat'
        }
    }

    data.linkSheetCategory = obj.data('linkSheetCategory');

    $('#' + dataTarget).load(url, data, function () {
        $(document).ready(function () {
            function checkLibEquip() {
                if (typeof codeEquip !== 'undefined') {
                    if ($("#equip").val() === '') {
                        $("#equip").val(codeEquip[1]);
                    }
                }
            }
            checkLibEquip();
        });

        const $target = $('#' + dataTarget);
        $target.parent().show();
        const sourceSearch = $('#source-search').data('source');
        if (sourceSearch !== undefined) {
            $('#blockImmatFl').removeClass("hidden");
        }
        $target.modal({
            backdrop: true
        });
    });

    return false;
}

$(document).on('click', '.action-open-popin-fiche-liaison', function () {
    if (!$(this).data('forcing')) {
        getPopinFicheLiaison($(this), $(this).data('url'), $(this).data(MODAL_TARGET));
    }
});

$(document).on('click', '#popinPackage .close, #popinPackage .popin-btn', function () {
    $(POPIN_PACKAGE_IDENTIFIER).modal('hide');
});

$(document).on('click', '#popinToutMsgFicheLiaison .close, #pubtech-popin-btn', function () {
    FermerPopinFicheLiaisonMsg();
});

$(document).on('click', '#popinForbidden .close, #popinForbidden .popin-btn', function () {
    $(POPIN_FORBIDDEN_IDENTIFIER).modal('hide');
});

function getPopinPFPT(obj, url, dataTarget) {
    $(LOADING_BLOCK).removeClass('hide'); // On masque le gif au chargement de la page
    const loaderror = $(obj).next('.loaderrore');
    loaderror.removeClass('hide');
    $('#' + dataTarget).load(url, function (responseText, textStatus, XMLHttpRequest) {
        if (textStatus != "error") {
            $('#' + dataTarget).parent().show();
            $('#' + dataTarget).modal({
                backdrop: true
            });

            initDatePickerAjax();

            $('body').addClass('modal-open');
            $('.modal-dialog').css('top', '120px');
        } else {
            alert('Une erreur est survenue, contactez votre administrateur');
        }
        $(LOADING_BLOCK).hide(); // On masque le gif au chargement de la page
        loaderror.addClass('hide'); // On masque le gif au chargement de la page
    });

    return false;
}

export function alertPackage() {
    $(POPIN_PACKAGE_IDENTIFIER).parent().show();
    $(POPIN_PACKAGE_IDENTIFIER).modal({
        backdrop: false
    });
}
export function alertForbidden() {
    $(POPIN_FORBIDDEN_IDENTIFIER).parent().show();
    $(POPIN_FORBIDDEN_IDENTIFIER).modal({
        backdrop: false
    });
}

$(document).on("click", '.pfpt', function (e) {
    e.preventDefault();
    const path = this.getAttribute('data-path');
    getPopinPFPT(this, path, 'popinPfpt');
});

$(document).on("click", ".alert-package", function (event) {
    alertPackage();
    event.preventDefault()
});

$(document).on('click', '#tecdocweb', function (e) {
    e.preventDefault();
    $.ajax({
        url: Routing.generate('autossimo_vehicules_tecdoc'),
        dataType: 'json',
        success: function (data) {
            const status = data.status;
            if (status == 'OK') {
                $(POPIN_TECDOC_SUCCESS).attr('data-numsession', data.numeroSession);
                $('#popinTecdocSuccess iframe').attr('src', data.retour);
                $(POPIN_TECDOC_SUCCESS).show();
                $(POPIN_TECDOC_SUCCESS).modal({
                    backdrop: true
                });
            } else {
                $('#popinTecdoc .modal-body p').html(data.retour);
                $('#popinTecdoc').show();
                $('#popinTecdoc').modal({
                    backdrop: true
                });
            }
        },
        error: function () {
            alert('Une erreur est survenue avec la session. Veuillez réessayer.');
        }
    });
    return false;
});

$(document).on('click', '#popinTecdocSuccess .close', function (e) {
    e.preventDefault();
    const numeroSess = $(POPIN_TECDOC_SUCCESS).attr('data-numsession');

    $.ajax({
        type: "POST",
        data: {
            numeroSession: numeroSess
        },
        url: Routing.generate('autossimo_remove_session_tecdoc'),
        async: true,
        success: function (data) {
            if (data.status) {
                $(POPIN_TECDOC_SUCCESS).modal('hide');
            }
        },
        onFailure: function (error) {
            console.log('error', error);
        }
    });
});

$(document).on('click', '.nav-tabs li', function () {
    if ($(this).parent().is('.vehicle-sheet')) {
        $(this).removeClass('active');
    }
    if ($(this).hasClass('active')) {
        return;
    } else {
        $(this).parent().find('li').each(function (k, v) {
            if ($(v).hasClass('active')) {
                $(v).removeClass('active');
                $(this).find('a').removeClass('active border_bottom_red');
            }
        });
        $(this).addClass('active');
        if (!$(this).parent().is('.vehicle-sheet')) {
            $(this).find('a').addClass('active border_bottom_red');
        } else {
            $(this).removeClass('active');
        }
    }
});

$(document).on('click', '#ficheproduit-onglet1', function (e) {
    e.preventDefault();
    $('#main-reference').addClass('active');
    $('#tab_compatibilities').removeClass('active');
    $("#ficheproduit-1").show();
    $("#ficheproduit-2").hide();
});
$(document).on('click', '#ficheproduit-onglet2', function (e) {
    e.preventDefault();
    $('#main-reference').removeClass('active');
    $('#tab_compatibilities').addClass('active');
    $("#ficheproduit-1").hide();
    $("#ficheproduit-2").show();
    $(".div_to_toggle").hide();
    $('.list_vehicules').show();
    $('div.list-item:first-child').show();
    $('.items22 div.div_to_toggle:first-child').show();
    $('.name_modele22:first-child').addClass('active');
});
$(document).on('click', '.row.bloc-vehicules-compatibles.logo', function () {
    $(this).addClass("active");
    $('.items22 div.div_to_toggle').hide();
    $(".div_to_toggle").toggle();
});

$(document).on("click", 'ul.modele li', function () {
    const mamodele = $(this).attr('class');
    const mymodele = mamodele.slice(0, mamodele.indexOf(" "));

    $('div.list-item').hide();
    $('div.list_vehicules').show();
    $('div.' + mymodele + '.list-item').show();
    $('ul.modele li').removeClass('active');
    $(this).toggleClass('active');

});

function canActivateEinaAccount() {
    if (!$('input[name="eina-rgpd"]').is(':checked')) {
        POPIN_BTN_EINA_CONFIRM.addClass('disabled');
    } else {
        POPIN_BTN_EINA_CONFIRM.removeClass('disabled');
    }
}

function canHandleUnavailableCoraProducts(glueElement) {
    const isFiducial = $('.user-infos').data('partner-name') === 'FIDUCIAL';
    if (glueElement.data('manufacturer-code') === 'CORA' && glueElement.data('availability') === 'A' && !isFiducial) {
        getPopin(glueElement, Routing.generate(POP_IN_CORA_ALERT_INFOS), TARGET_CORA_UNAVAILABLE);

        return false;
    }

    return true;
}

$(document).on('change', 'input[name="eina-rgpd"]', function () {
    canActivateEinaAccount();
});

$(document).on('click', '.eina-rgpd', function (e) {
    e.preventDefault();
    const popinElem = $('#popinEina');
    $(popinElem).parent().show();
    $(popinElem).modal();
    const refKtypnr = $(this).data("refKtypnr");

    popinElem.attr(POPIN_REF_KTYPNR, '');
    if (refKtypnr) {
        popinElem.attr(POPIN_REF_KTYPNR, refKtypnr);
    }

    canActivateEinaAccount();

    return false;
});

$(document).on('click', '.popin-btn-eina-confirm', function (e) {
    const popinRefKtypnr = $('#popinEina').attr(POPIN_REF_KTYPNR);

    $.ajax({
        url: Routing.generate('rgpd_eina', { 'checked': 'true', 'ktypnr': popinRefKtypnr }),
        success: function (data) {
            if ('' !== data.uri) {
                window.open(data.uri, '_blank');
            }

            location.reload();
        },
    });
});

// Event on click to "peinturesnav" bloc
$(document).on('click', '.peinturesnav', function (event) {
    if ($(this).find('a').length === 0) {
        return false;
    }
    // Build parameters
    const popin = $('#popinInfoPaintCollection');

    // Disable default event
    event.preventDefault();

    if ($(this).hasClass('disabled-paint-collection')) {
        // Show modal informative when connected user hasn't the paint collection
        popin.show();
        popin.modal();
    } else {
        // Handle redirection to paint page
        window.location.href = Routing.generate('autossimo_peintures');
    }
});

// Event on click to " chains-socks-nav " bloc
$(document).on('click', '.chains-socks-nav', function (event) {
    if ($(this).find('a').length === 0) {
        return false;
    }
    const popping = $('#popinInfoTreeCodeNotAllowed');

    // Disable default event
    event.preventDefault();

    if ($(this).hasClass('disabled-consumable-tree-code')) {
        // Show popup if arbores Code '020101' is not in active codeArbo
        popping.show();
        popping.modal();
    } else {
        // Handle redirection to chains socks page
        window.location.href = Routing.generate('autossimo_chains_socks');
    }
});
